import React, {createRef, useEffect, useMemo, useRef, useState} from 'react';
import {
  Button, Divider,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Item,
  Label,
  List,
  Message,
  Modal, Popup,
  Ref,
  Segment,
  Sticky,
  Tab,
} from 'semantic-ui-react';
import {Link, useParams} from 'react-router-dom';
import _ from 'lodash';
import {NetworkUtil} from '../../utils/NetworkUtil';
import {useRecoilValue} from 'recoil';
import {jwtAtom, tokenRefreshingAtom} from '../../recoil/atoms';
import {CountdownCircleTimer} from 'react-countdown-circle-timer';
import {BASE_STYLES, CARMON_INFO, THEME} from '../../consts/consts';
import PerformanceCheckReport from '../../components/Bid/PerformanceCheckReport';
import AccidentHistory from '../../components/Bid/AccidentHistory';
import CarInformation from '../../components/Bid/CarInformation';
import {CommonUtil} from '../../utils/CommonUtil';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slick from 'react-slick';

import NoImage from '../../images/no-image.png';
import {BackgroundImage} from 'react-image-and-background-image-fade';
import {FormatOptions, numToKorean} from 'num-to-korean';
import moment from 'moment';
import {BidUtil} from '../../utils/BidUtil';

function onImageError(e) {
  e.target.src = NoImage;
}

export default function MyBidItem({match, location, history}) {
  const isTokenRefreshing = useRecoilValue(tokenRefreshingAtom);

  const {auctionId} = useParams();
  const [bid, setBid] = useState({
    images: [],
  });
  const [errorData, setErrorData] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [isImageViewerOpened, setIsImageViewerOpened] = useState(false);

  const doSearch = useMemo(() => () => {
    NetworkUtil.get(`/apis/my-bids/${auctionId}`, {},
      data => {
        const bid = data;
        _.set(bid, 'carInformation', JSON.parse(data.rawCi));
        _.set(bid, 'accidentHistory', JSON.parse(data.rawAh));
        _.set(bid, 'carRegister', JSON.parse(data.rawAr));
        _.set(bid, 'performanceCheckRecord', JSON.parse(data.rawPcr));

        setBid(bid);
      },
      errorData => setErrorData(errorData));
  }, [auctionId]);

  useEffect(() => {
    if (!isTokenRefreshing) doSearch();
  }, [isTokenRefreshing, doSearch]);

  useEffect(() => {
    setCurrentImage(bid.images[0]);
  }, [bid.images]);

  return (
    <div className={'carmon-view-container'}>
      <div className={'bid-item-main-info'}>
        <Segment>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            alignSelf: 'stretch',
          }}>
            <div>
              <BackgroundImage
                lazyLoad
                src={currentImage?.imageUrl}
                style={{
                  display: 'flex',
                  padding: 0,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                width={'360px'}
                height={'240px'}>
                <Button
                  icon
                  style={{
                    position: 'absolute',
                    bottom: 4,
                    right: 0,
                    zIndex: 20,
                    backgroundColor: 'rgba(0,0,0,0.8)',
                  }}
                  onClick={() => setIsImageViewerOpened(true)}>
                  <Icon name={'search'} style={{color: 'white'}} />
                </Button>
              </BackgroundImage>
            </div>

            <div style={{flex: 1, marginLeft: 16}}>
              <Header as={'h3'} style={{marginBottom: 8}}>
                <Header.Content>
                  {_.get(bid, 'carInformation.cardata.regname')}
                  <Header as={'h5'} style={{margin: 0}}>{_.get(bid, 'carInformation.cardata.seriesname')}</Header>
                  <span style={{
                    fontWeight: 'normal',
                    fontSize: BASE_STYLES.FONT_SIZE.SUB,
                  }}>{_.get(bid, 'carInformation.cardata.carnum')}</span>
                </Header.Content>
              </Header>
              <Divider style={{margin: '6px 0'}} />
              <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                <span>
                  {_.get(bid, 'locationStateName')} {_.get(bid, 'locationRegionName')}&nbsp;·&nbsp;
                  {_.get(bid, 'carInformation.cardata.makername')}&nbsp;·&nbsp;
                  {_.get(bid, 'carInformation.cardata.year')}년형&nbsp;·&nbsp;
                  {moment(_.get(bid, 'carInformation.cardata.firstdate')).format('YYYY.MM 등록')}
                </span>
                <span>
                  {CommonUtil.Unit.format(_.get(bid, 'performanceCheckRecord.TOT_110202'))}km&nbsp;·&nbsp;
                  {_.get(bid, 'carInformation.cardata.fuel')}&nbsp;·&nbsp;
                  {_.get(bid, 'carInformation.cardata.gearbox')}&nbsp;·&nbsp;
                  {_.get(bid, 'carInformation.cardata.color')}
                </span>
              </div>
              <Divider style={{margin: '6px 0'}} />
              <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                <span style={{lineHeight: 1.3}}>
                  {_.map(_.sortBy(_.get(bid, 'carInformation.cardata.optlist'), ['name']), 'name').join(' · ')}
                </span>
              </div>
            </div>
          </div>
        </Segment>

        <Tab menu={{pointing: true}} panes={[
          {
            menuItem: '① 성능점검기록부',
            render: () => <Tab.Pane attached={false}>
              <PerformanceCheckReport performanceCheckReportData={bid.performanceCheckRecord} />
            </Tab.Pane>,
          },
          {
            menuItem: '② 사고/보험이력',
            render: () => <Tab.Pane attached={false}>
              <AccidentHistory accidentHistoryData={bid.accidentHistory} carRegisterData={bid.carRegister} />
            </Tab.Pane>,
          },
        ]} />
      </div>

      <div className={'bid-item-car-info'}>
        <Label
          size={'large'}
          style={{width: '100%', textAlign: 'center'}}
          color={BidUtil.getStateColor(bid.myBidStateCode)}>
          {bid.myBidStateLabel}
        </Label>

        <Segment>
          <Header as={'h4'}>경매정보</Header>
          <List selection verticalAlign="middle">
            <List.Item>
              <List.Content
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                }}>
                <List.Header>전체조회수</List.Header>
                <List.Description>{bid.totalViewCount}회</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                }}>
                <List.Header>전체입찰수</List.Header>
                <List.Description>{bid.bidCount}건</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                }}>
                <List.Header>내조회수</List.Header>
                <List.Description>{bid.myViewCount}회</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                }}>
                <List.Header>내입찰수</List.Header>
                <List.Description>{bid.myBidCount}건</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                }}>
                <List.Header>내최종입찰가</List.Header>
                <List.Description>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-start',
                  }}>
                    <span>{CommonUtil.Unit.format(bid.myCurrentBidAmount)}원</span>
                    <span style={{fontSize: BASE_STYLES.FONT_SIZE.MIN}}>
                                  ({numToKorean(bid.myCurrentBidAmount, FormatOptions.LINGUAL)}원)
                                </span>
                  </div>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Segment>

        {BidUtil.showBidAmountInfo(bid.myBidStateCode) && <Segment>
          <Header as={'h4'}>입금정보</Header>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
            gap: 4,
          }}>
            <span>
              {CommonUtil.Unit.format(bid.totalAmount)}원&nbsp;
              <Popup
                inverted
                size={'tiny'}
                on="hover"
                content={
                  <div style={{minWidth: 200}}>
                    <div style={{
                      alignSelf: 'stretch',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                      <span>차량</span>
                      <span>{CommonUtil.Unit.format(bid.bidAmount)}원</span>
                    </div>
                    <div style={{
                      alignSelf: 'stretch',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                      <span>탁송비</span>
                      <span>{CommonUtil.Unit.format(bid.transportAmount)}원</span>
                    </div>
                    <div style={{
                      alignSelf: 'stretch',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                      <span>수수료</span>
                      <span>{CommonUtil.Unit.format(bid.feeAmount)}원</span>
                    </div>
                  </div>
                }
                trigger={<Icon name={'question circle'} color={'yellow'} fitted link />} />
            </span>
          </div>
          <Divider style={{margin: '6px 0'}} />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
            gap: 4,
          }}>
            <span>{bid.cmAccountOwner}</span>
            <span>{bid.cmBank}</span>
            <span>{bid.cmAccountNumber}</span>
          </div>
          {bid.dealerPaidYn === 'Y' && (<>
            <Divider style={{margin: '6px 0'}} />
            <Label
              size={'large'}
              color={'green'}
              style={{
                width: '100%',
                textAlign: 'center',
              }}>
              입금확인완료&nbsp;
              <Popup
                inverted
                size={'tiny'}
                on="hover"
                content={moment(bid.dealerPaidTime).format('YYYY-MM-DD HH:mm')}
                trigger={<Icon name={'clock'} fitted link />} />
            </Label>
          </>)}
        </Segment>}

        {BidUtil.showBidAmountInfo(bid.myBidStateCode) && <Segment>
          <Header as={'h4'}>탁송정보</Header>
          <span>{bid.buyerAddress}</span>
          <Divider style={{margin: '6px 0'}} />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
            gap: 4,
          }}>
            <span>{bid.transportManagerName ? `${bid.transportManagerName}` : '탁송기사 배정대기'}</span>
            <span>{bid.transportManagerName && `${bid.transportManagerContactNumber}`}</span>
          </div>
        </Segment>}

        {BidUtil.showBidAmountInfo(bid.myBidStateCode) && <Button
          icon={'file alternate outline'}
          content={'계약서 보기'}
          disabled={!BidUtil.showBidContract(bid.myBidStateCode)}
          style={{width: '100%'}}
          as={Link}
          to={`/my-bid/item/${bid.auctionId}/contract`} target={'_blank'} />}

        {isImageViewerOpened && (<Modal
          basic
          centered={false}
          onClose={() => setIsImageViewerOpened(false)}
          onOpen={() => setIsImageViewerOpened(true)}
          open={isImageViewerOpened}
          style={{
            flex: 1,
            display: 'flex!important',
            flexDirection: 'column',
            width: '100%',
            margin: 0,
          }}>
          <Modal.Header style={{padding: '6px 0', textAlign: 'right'}}>
            <Icon name={'close'} size={'large'} link onClick={() => setIsImageViewerOpened(false)} />
          </Modal.Header>
          <Modal.Content>
            {_.map(bid.images, (img, idx) => (
              <Segment basic style={{padding: 0}}>
                <Image src={img.imageUrl} style={{width: '100%'}} />
                <Label attached="top">{idx + 1}. {BidUtil.getImageTitle(img.point)}</Label>
              </Segment>
            ))}
          </Modal.Content>
        </Modal>)}

        <Modal
          basic
          open={errorData !== null}
          size="small">
          <Header icon>
            <Icon name="remove" />
            {errorData?.message}
          </Header>
          <Modal.Actions>
            <Button basic inverted onClick={() => window.close()}>
              <Icon name="remove" /> 닫기
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
}
