import {CommonUtil} from './CommonUtil';
import log from 'loglevel';
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const logger = log.noConflict();
logger.setDefaultLevel('trace');

export const Logger = {
  debug(...messages) {
    if (!CommonUtil.RunMode.isProd()) {
      logger.debug(`[${moment().format(DATE_FORMAT)}]`, ...messages);
    }
  },
  info(...messages) {
    if (!CommonUtil.RunMode.isProd()) {
      logger.info(`[${moment().format(DATE_FORMAT)}]`, ...messages);
    }
  },
  error(...errorMessages) {
    if (!CommonUtil.RunMode.isProd()) {
      logger.error(`[${moment().format(DATE_FORMAT)}]`, ...errorMessages);
    }
  },
};
