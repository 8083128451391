import {Route, Switch} from 'react-router-dom';
import AgreementView from './Agreement';
import InformationView from './Information';
import CompletionView from './Completion';
import NotFound from '../Error/NotFound';

export default function Register({match, location, history}) {
  return (
    <Switch>
      <Route path={`${match.path}/step1`} component={AgreementView} />
      <Route path={`${match.path}/step2`} component={InformationView} />
      <Route path={`${match.path}/completion`} component={CompletionView} />
      <Route path={`${match.path}/**`} component={NotFound} />
    </Switch>
  );
}
