import {Message} from 'semantic-ui-react';
import {useEffect, useState} from 'react';
import {LS_KEYS} from '../../consts/consts';

export default function NotFound({match, location, history}) {
  const [timeLeft, setTimeLeft] = useState(3);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const newTimeLeft = timeLeft - 1;

      if (newTimeLeft === 0) {
        const isLoggedIn = JSON.parse(localStorage.getItem(LS_KEYS.LOGIN_CHECK_KEY));
        // isLoggedIn ? history.replace('/dashboard') : history.replace('/');
        isLoggedIn ? history.replace('/bid/list') : history.replace('/');
      } else setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [history, timeLeft]);

  return (
    <div className={'carmon-view-container'}
         style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <Message error size={'massive'} icon={'exclamation triangle'}
               header={'페이지를 찾을 수 없습니다.'}
               content={() => <span>{timeLeft}초 뒤에 이동합니다.</span>}
               style={{maxWidth: 800}} />
    </div>
  );
}