import React, {createRef, useEffect, useMemo, useRef, useState} from 'react';
import {
  Button,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Item,
  Label,
  List,
  Message,
  Modal,
  Ref,
  Segment,
  Sticky,
  Tab,
} from 'semantic-ui-react';
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import {NetworkUtil} from '../../utils/NetworkUtil';
import {useRecoilValue} from 'recoil';
import {jwtAtom, tokenRefreshingAtom} from '../../recoil/atoms';
import {CountdownCircleTimer} from 'react-countdown-circle-timer';
import {BASE_STYLES, CARMON_INFO, THEME} from '../../consts/consts';
import PerformanceCheckReport from '../../components/Bid/PerformanceCheckReport';
import AccidentHistory from '../../components/Bid/AccidentHistory';
import CarInformation from '../../components/Bid/CarInformation';
import {CommonUtil} from '../../utils/CommonUtil';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slick from 'react-slick';

import NoImage from '../../images/no-image.png';
import {BackgroundImage} from 'react-image-and-background-image-fade';
import {FormatOptions, numToKorean} from 'num-to-korean';
import {BidUtil} from '../../utils/BidUtil';

function onImageError(e) {
  e.target.src = NoImage;
}

export default function BidItem({match, location, history}) {
  const isTokenRefreshing = useRecoilValue(tokenRefreshingAtom);

  const {auctionId} = useParams();
  const contextRef = createRef();
  const slickRef = useRef();

  const [bid, setBid] = useState({
    images: [],
  });
  const [remainingTime, setRemainingTime] = useState(0);
  const [currentImage, setCurrentImage] = useState(null);

  const [biddingAmount, setBiddingAmount] = useState(0);
  const [latestBidding, setLatestBidding] = useState(null);
  const [biddingError, setBiddingError] = useState(null);
  const [biddingAmountIsZero, setBiddingAmountIsZero] = useState(false);
  const [newBiddingAmountIsLower, setNewBiddingAmountIsLower] = useState(false);
  const [isConfirmOpened, setIsConfirmedOpened] = useState(false);
  const [isImageViewerOpened, setIsImageViewerOpened] = useState(false);

  const [isBiddingFinished, setIsBiddingFinished] = useState(false);

  const doSearch = useMemo(() => () => {
    NetworkUtil.get(`/apis/bids/${auctionId}`, {},
      data => {
        const bid = data;
        _.set(bid, 'carInformation', JSON.parse(data.rawCi));
        _.set(bid, 'accidentHistory', JSON.parse(data.rawAh));
        _.set(bid, 'carRegister', JSON.parse(data.rawAr));
        _.set(bid, 'performanceCheckRecord', JSON.parse(data.rawPcr));

        setBid(bid);
      },
      errorData => {

      });
  }, [auctionId]);

  const doBid = () => {
    if (biddingAmount <= 0) {
      setBiddingAmountIsZero(true);
      setTimeout(() => setBiddingAmountIsZero(false), 3000);
      return false;
    }

    // if ((biddingAmount * 10000) <= bid.myMaxBidAmount) {
    //   setNewBiddingAmountIsLower(true);
    //   setTimeout(() => setNewBiddingAmountIsLower(false), 3000);
    //   return false;
    // }

    setIsConfirmedOpened(true);
  };

  useEffect(() => {
    if (!isTokenRefreshing) doSearch();
  }, [isTokenRefreshing, doSearch]);

  useEffect(() => {
    setCurrentImage(bid.images[0]);
  }, [bid.images]);

  useEffect(() => {
    setRemainingTime(bid.remainingTime);
  }, [bid.remainingTime]);

  return (
    <div className={'carmon-view-container'}>
      <Ref innerRef={contextRef}>
        <>
          <div className={'bid-item-main-info'}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'stretch',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 16,
            }}>
              <Segment style={{
                display: 'flex',
                flexDirection: 'column',
                alignSelf: 'stretch',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Segment.Group style={{width: '100%', maxWidth: 800}}>
                  <Segment
                    basic
                    attached={'top'}
                    className={'bid-item-slider'}>
                    <Slick
                      ref={slickRef}
                      speed={0}
                      infinite={true}
                      slidesToShow={1}
                      slidesToScroll={1}
                      afterChange={index => setCurrentImage(bid.images[index])}>
                      {_.map(bid.images, (img, idx) => (
                        <Segment key={`img-${idx}`} className="slide-image">
                          <BackgroundImage
                            lazyLoad
                            src={img.imageUrl}
                            style={{
                              display: 'flex',
                              padding: 0,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                            }}
                            height={'100%'} />
                          <Label className={'point-label'} attached="bottom">{BidUtil.getImageTitle(img.point)}</Label>
                          <Label className={'mag-label'} attached="top right">
                            <Button size={'large'} onClick={() => setIsImageViewerOpened(true)}>
                              <Icon name={'magnify'} size={'large'} />
                            </Button>
                          </Label>
                        </Segment>
                      ))}
                    </Slick>
                  </Segment>
                  <Segment
                    basic
                    attached={'bottom'}
                    style={{
                      padding: `2px 4px`,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <div style={{flex: 1, display: 'flex', flexFlow: 'row wrap', gap: 1}}>
                      {_.map(bid.images, (img, idx) => (
                        <Segment key={`thumbnail-${idx}`} className={'slide-thumbnail'}>
                          <BackgroundImage
                            lazyLoad
                            src={img.imageUrl}
                            style={{
                              display: 'flex',
                              padding: 0,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                            }}
                            height={'80px'}
                            data-item-index={idx}
                            onMouseOver={e => {
                              e.target.style.border = `4px solid ${BASE_STYLES.THEME_SUB_COLOR}`;
                              let index = e.target.dataset['itemIndex'];
                              slickRef.current.slickGoTo(index);
                            }}
                            onMouseOut={e => {
                              e.target.style.border = `0`;
                            }} />
                        </Segment>
                      ))}
                    </div>
                  </Segment>
                </Segment.Group>
              </Segment>
            </div>

            <Tab menu={{pointing: true}} panes={[
              {
                menuItem: '① 성능점검기록부',
                render: () => <Tab.Pane attached={false}>
                  <PerformanceCheckReport performanceCheckReportData={bid.performanceCheckRecord} />
                </Tab.Pane>,
              },
              {
                menuItem: '② 사고/보험이력',
                render: () => <Tab.Pane attached={false}>
                  <AccidentHistory accidentHistoryData={bid.accidentHistory} carRegisterData={bid.carRegister} />
                </Tab.Pane>,
              },
            ]} />
          </div>

          <div className={'bid-item-car-info'}>
            <CarInformation
              auctionId={bid.auctionId}
              carInformationData={bid.carInformation}
              mileageData={bid.performanceCheckRecord?.TOT_110202} />

            <Sticky context={contextRef} offset={102}>
              <Segment>
                <Grid>
                  <Grid.Row>
                    <Grid.Column style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <CountdownCircleTimer
                        isPlaying={true}
                        size={200}
                        strokeWidth={12}
                        key={remainingTime}
                        duration={86400}
                        colors={[[BASE_STYLES.THEME_SUB_COLOR]]}
                        initialRemainingTime={remainingTime}
                        onComplete={() => setIsBiddingFinished(true)}
                        children={({remainingTime}) => {
                          const hours = Math.floor(remainingTime / 3600);
                          const minutes = Math.floor((remainingTime % 3600) / 60);
                          const seconds = remainingTime % 60;

                          return (
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                              <Header as={'h1'} color={THEME} style={{margin: 0}}>
                                {_.padStart(_.toString(hours), 2, '0')}:{_.padStart(_.toString(minutes), 2, '0')}:{_.padStart(_.toString(seconds), 2, '0')}
                              </Header>
                              <Header as={'h3'} color={THEME} style={{margin: 0}}>
                                {remainingTime > 0 ? '경매진행중' : '경매종료'}
                              </Header>
                            </div>
                          );
                        }}>
                      </CountdownCircleTimer>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row className={'bid-item-view-count'}>
                    <Grid.Column>
                      <List selection verticalAlign="middle">
                        <List.Item>
                          <List.Content
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              justifyContent: 'space-between',
                            }}>
                            <List.Header>전체조회수</List.Header>
                            <List.Description>{bid.totalViewCount}회</List.Description>
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              justifyContent: 'space-between',
                            }}>
                            <List.Header>전체입찰수</List.Header>
                            <List.Description>{bid.bidCount}건</List.Description>
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              justifyContent: 'space-between',
                            }}>
                            <List.Header>내조회수</List.Header>
                            <List.Description>{bid.myViewCount}회</List.Description>
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              justifyContent: 'space-between',
                            }}>
                            <List.Header>내입찰수</List.Header>
                            <List.Description>{bid.myBidCount}건</List.Description>
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              justifyContent: 'space-between',
                            }}>
                            <List.Header>내최종입찰가</List.Header>
                            <List.Description>
                              <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                justifyContent: 'flex-start',
                              }}>
                                <span>{CommonUtil.Unit.format(bid.myCurrentBidAmount)}원</span>
                                <span style={{fontSize: BASE_STYLES.FONT_SIZE.MIN}}>
                                  ({numToKorean(bid.myCurrentBidAmount, FormatOptions.LINGUAL)}원)
                                </span>
                              </div>
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      </List>
                    </Grid.Column>
                  </Grid.Row>

                  {remainingTime > 0 && (
                    <Grid.Row>
                      <Grid.Column>
                        <Item>
                          <Item.Content>
                            <Item.Description>
                              <Input
                                fluid
                                label={{
                                  basic: true,
                                  content: '만원',
                                  style: {
                                    display: 'flex',
                                    alignItems: 'center',
                                  },
                                }}
                                labelPosition={'right'}
                                input={{
                                  style: {
                                    textAlign: 'right',
                                    fontWeight: 'bold',
                                    fontSize: 26,
                                  },
                                }}
                                value={biddingAmount}
                                disabled={bid.myBidCount >= 3}
                                maxLength={6}
                                onFocus={e => e.target.select()}
                                onChange={(e, {value}) => {
                                  const refinedValue = _.replace(value, /[^0-9]/g, '');
                                  setBiddingAmount(_.toNumber(_.defaultTo(refinedValue, '0')));
                                }}
                              />
                              {biddingAmount !== 0 && (
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'flex-end',
                                }}>
                                  <Label color={'black'} pointing style={{marginBottom: 6}}>
                                    {numToKorean(biddingAmount * 10000, FormatOptions.LINGUAL)}원
                                  </Label>
                                </div>
                              )}
                            </Item.Description>
                            <Item.Extra style={{padding: '6px 0'}}>
                              <Header as={'h5'} color={'orange'}>
                                <Icon name={'exclamation circle'} />
                                <Header.Content>입찰은 최대 3번만 가능합니다.</Header.Content>
                              </Header>
                            </Item.Extra>
                          </Item.Content>
                        </Item>
                        <Button disabled={bid.myBidCount >= 3} fluid primary content={'입찰하기'} onClick={() => doBid()} />
                        {biddingAmountIsZero && (
                          <Message
                            icon="exclamation"
                            warning
                            header="입찰가 확인!"
                            content="입찰가는 1만원 이상으로 입력해 주세요."
                          />)}
                        {newBiddingAmountIsLower && (
                          <Message
                            icon="exclamation"
                            warning
                            header="입찰가 확인!"
                            content="딜러님이 이전에 입찰하신 최고가 보다 적은 금액입니다. 더 큰 금액으로 입찰해 주세요."
                          />)}
                        {latestBidding !== null && (
                          <Message
                            icon="check"
                            success
                            header="입찰 성공!"
                            content={<><b>{CommonUtil.Unit.format(latestBidding.bidAmount / 10000)}만원</b>에 입찰하셨습니다!</>}
                          />)}
                        {biddingError !== null && (
                          <Message
                            icon="exclamation"
                            error
                            header="입찰 실패!"
                            content={`입찰에 문제가 발생했습니다. 다시 입찰을 진행해보시고, 문제가 발생할 경우 카몬고객센터(${CARMON_INFO.REPRESENTATIVE_TEL})로 연락주세요. 이용에 불편을 드려 죄송합니다.`}
                          />
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Grid>
              </Segment>
            </Sticky>
          </div>
        </>
      </Ref>

      <Modal
        size={'tiny'}
        centered={false}
        open={isConfirmOpened}
        onClose={() => setIsConfirmedOpened(false)}
        onOpen={() => setIsConfirmedOpened(true)}>
        <Modal.Header>입찰을 진행합니다!</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            입력하신 입찰가는 <br />
            <b style={{fontSize: BASE_STYLES.FONT_SIZE.MAX}}>{CommonUtil.Unit.format(biddingAmount)}만원
              ({numToKorean(_.toNumber(_.defaultTo(biddingAmount, '0')) * 10000, FormatOptions.LINGUAL)}원)</b> 입니다.<br />
            경매당 <b>3번</b>의 입찰만 가능합니다.<br />입력하신 금액으로 입찰을 진행하시겠습니까?
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => {
              setLatestBidding(null);
              setBiddingError(null);

              NetworkUtil.put(`/apis/bids/${auctionId}`, {biddingAmount}, data => {
                setLatestBidding(data);
                setBiddingAmount(0);
                doSearch();
              }, errorData => {
                setBiddingError(errorData);
              }, () => {
                setIsConfirmedOpened(false);
                setTimeout(() => {
                  setLatestBidding(null);
                  setBiddingError(null);
                }, 5000);
              });
            }}>
            <Icon name={'grab'} />
            입찰하기
          </Button>
          <Button onClick={() => setIsConfirmedOpened(false)}>취소</Button>
        </Modal.Actions>
      </Modal>

      {isImageViewerOpened && (<Modal
        basic
        centered={false}
        onClose={() => setIsImageViewerOpened(false)}
        onOpen={() => setIsImageViewerOpened(true)}
        open={isImageViewerOpened}
        style={{
          flex: 1,
          display: 'flex!important',
          flexDirection: 'column',
          width: '100%',
          margin: 0,
        }}>
        <Modal.Header style={{padding: '6px 0', textAlign: 'right'}}>
          <Icon name={'close'} size={'large'} link onClick={() => setIsImageViewerOpened(false)} />
        </Modal.Header>
        <Modal.Content>
          {_.map(bid.images, (img, idx) => (
            <Segment basic style={{padding: 0}}>
              <Image src={img.imageUrl} style={{width: '100%'}} />
              <Label attached="top">{idx + 1}. {BidUtil.getImageTitle(img.point)}</Label>
            </Segment>
          ))}
        </Modal.Content>
      </Modal>)}

      <Modal
        basic
        onClose={() => setIsBiddingFinished(false)}
        onOpen={() => setIsBiddingFinished(true)}
        open={isBiddingFinished}
        size="small">
        <Header icon>
          <Icon name="thumbs up outline" />
          경매가 종료되었습니다!<br />
          딜러님께 좋은 결과가 있으시길 바랍니다!
        </Header>
        <Modal.Actions>
          <Button basic inverted onClick={() => setIsBiddingFinished(false)}>
            <Icon name="remove" /> 닫기
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
