import {Button, Container, Dropdown, Form, Header, Icon, Input, Message, Segment} from 'semantic-ui-react';
import {useRecoilValue} from 'recoil';
import {jwtAtom, tokenRefreshingAtom} from '../../../recoil/atoms';
import jwtDecode from 'jwt-decode';
import {Logger} from '../../../utils/Logger';
import React, {useEffect, useState} from 'react';
import {NetworkUtil} from '../../../utils/NetworkUtil';
import {CARMON_INFO, THEME} from '../../../consts/consts';
import {Link} from 'react-router-dom';
import _ from 'lodash';

export default function Employment({match, location, history}) {
  const isTokenRefreshing = useRecoilValue(tokenRefreshingAtom);

  const [dealer, setDealer] = useState({});

  const [isLocationStateLoading, setIsLocationStateLoading] = useState(false);
  const [isLocationRegionLoading, setIsLocationRegionLoading] = useState(false);
  const [locationStates, setLocationStates] = useState([]);
  const [locationRegions, setLocationRegions] = useState([]);

  const [validationResult, setValidationResult] = useState(null);

  const getLocationStates = async () => {
    const locationStates = await NetworkUtil.getAsync('/apis/data/base/location/states', {}, setIsLocationStateLoading);
    const locationStatesForDropdown = _.map(locationStates, locationState => ({
      key: `${locationState['code']}_${locationState['name']}`,
      text: locationState['name'],
      value: locationState['code'],
      disabled: true,
    }));
    setLocationStates(locationStatesForDropdown);
  };

  const getLocationRegions = async (value) => {
    if (value === undefined) return;

    const locationRegions = await NetworkUtil.getAsync(`/apis/data/base/location/states/${value}`, {}, setIsLocationRegionLoading);
    const locationRegionsForDropdown = _.map(locationRegions, locationRegion => ({
      key: `${locationRegion['code']}_${locationRegion['name']}`,
      text: locationRegion['name'],
      value: locationRegion['code'],
      disabled: true,
    }));
    setLocationRegions(locationRegionsForDropdown);
  };

  const validateForm = () => {
    const result = _.reduce(dealer, (res, value, key) => {
      res[key] = !_.isEmpty(value);
      return res;
    }, {});

    setValidationResult(result);
    return result;
  };

  useEffect(() => {
    if (!isTokenRefreshing) {
      (async () => {
        await getLocationStates();
        await getLocationRegions(dealer.locationState);
      })();
    }
  }, [dealer.locationState, isTokenRefreshing]);

  useEffect(() => {
    if (!isTokenRefreshing) {
      NetworkUtil.get(`/apis/dealer`, {}, data => setDealer(data));
    }
  }, [isTokenRefreshing, setDealer]);

  return (
    <Container className={'carmon-view-container'}
               style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
      <Segment basic style={{width: 660}}>
        <Segment attached="top" color={THEME}>
          <Button content="뒤로가기" icon="arrow circle left" labelPosition="left" as={Link} to={'/my-info'} replace />
        </Segment>
        <Segment attached>
          <Header as={'h4'}>
            <Header.Content>
              <Icon name={'id card outline'} />
              사원증 및 소속상사 관리
            </Header.Content>
            <Header.Subheader>
              종사원증 정보와 소속매매상사 정보를 관리합니다.
            </Header.Subheader>
          </Header>
        </Segment>
        <Segment attached loading={false}>
          <Form readOnly>
            <Form.Group>
              <Form.Field
                width={5}
                control={Input}
                label="이름"
                placeholder="이름"
                value={dealer.name}
                readOnly
              />

              <Form.Field
                width={4}
                control={Input}
                label="직함"
                placeholder="직함"
                value={dealer.positionTitle}
              />

              <Form.Field
                width={7}
                control={Input}
                label="종사원번호"
                placeholder="종사원번호"
                value={dealer.employeeNumber}
                readOnly
              />
            </Form.Group>

            <Form.Group widths={'equal'}>
              <Form.Field>
                <label>주요활동지역</label>
                <Dropdown
                  placeholder="시/도"
                  fluid
                  search={false}
                  selection
                  loading={isLocationStateLoading}
                  value={dealer.locationState}
                  options={locationStates}
                  onChange={async (e, {value}) => {
                    setDealer(prevState => ({...prevState, locationState: value}));
                    await getLocationRegions(value);
                  }}
                  error={validationResult?.locationState === false}
                />
              </Form.Field>

              <Form.Field>
                <label>주요활동지역</label>
                <Dropdown
                  placeholder="시/군/구"
                  fluid
                  search={false}
                  selection
                  loading={isLocationRegionLoading}
                  value={dealer.locationRegion}
                  options={locationRegions}
                  onChange={async (e, {value}) => {
                    setDealer(prevState => ({...prevState, locationRegion: value}));
                  }}
                  error={validationResult?.locationRegion === false}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths={'equal'}>
              <Form.Field
                control={Input}
                label="매매상사명"
                placeholder="매매상사명"
                value={dealer.companyName}
                readOnly
              />

              <Form.Field
                control={Input}
                label="매매상사전화번호"
                placeholder="매매상사전화번호"
                value={dealer.companyContactNumber}
              />
            </Form.Group>
          </Form>
        </Segment>
        <Message info attached={'bottom'}>
          <Header as={'h5'}>
            <Header.Subheader>
              <div
                style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                <Icon name={'exclamation circle'} />
                <div style={{flex: 1}}>
                  매매상사 소속이 변경된 경우, 카몬고객센터({CARMON_INFO.REPRESENTATIVE_TEL})로 연락주시면,<br />별도의 절차를 통해 처리해 드립니다.
                </div>
              </div>
            </Header.Subheader>
          </Header>
        </Message>
        {/*<Segment attached={'bottom'} textAlign={'right'}>*/}
        {/*  <Button type={'button'} content={'변경신청'} />*/}
        {/*</Segment>*/}
      </Segment>
    </Container>
  );
}
