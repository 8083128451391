import React from 'react';
import ReactDOM from 'react-dom';

import {RecoilRoot} from 'recoil';
import {BrowserRouter} from 'react-router-dom';

import App from './App';
import AppMobile from './AppMobile';
import reportWebVitals from './reportWebVitals';

import {BrowserView, MobileView} from 'react-device-detect';

import 'semantic-ui-less/semantic.less';
import './index.css';
import './App.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserView>
      <RecoilRoot>
        {/*<Suspense fallback={<div>Loading...</div>}>*/}
        <BrowserRouter>
          <App />
        </BrowserRouter>
        {/*</Suspense>*/}
      </RecoilRoot>
    </BrowserView>
    <MobileView>
      <AppMobile />
    </MobileView>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
