import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Container,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Message,
  Modal,
  Ref,
  Segment,
} from 'semantic-ui-react';
import {Logger} from '../../utils/Logger';
import _ from 'lodash';
import {NetworkUtil} from '../../utils/NetworkUtil';
import {BASE_STYLES, THEME} from '../../consts/consts';
import {Link} from 'react-router-dom';

export default function InformationView({
                                          match,
                                          location,
                                          history,
                                          location: {
                                            state: data,
                                          },
                                        }) {
  const combined = _.merge({
    name: '',
    positionTitle: '',
    employeeNumber: '',
    locationState: '',
    locationRegion: '',
    companyName: '',
    companyContactNumber: '',
  }, data);

  const nameRef = useRef(null);
  const positionTitleRef = useRef(null);
  const employeeNumberRef = useRef(null);
  const companyNameRef = useRef(null);
  const companyContactNumberRef = useRef(null);

  const [registrationInfo, setRegistrationInfo] = useState(combined);
  const [registrationError, setRegistrationError] = useState();
  const [inProceeding, setInProceeding] = useState(false);

  const [isLocationStateLoading, setIsLocationStateLoading] = useState(false);
  const [isLocationRegionLoading, setIsLocationRegionLoading] = useState(false);
  const [locationStates, setLocationStates] = useState([]);
  const [locationRegions, setLocationRegions] = useState([]);

  const [validationResult, setValidationResult] = useState(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isWelcomeDialogOpen, setIsWelcomeDialogOpen] = useState(false);

  const getLocationStates = async () => {
    const locationStates = await NetworkUtil.getAsync('/apis/data/base/location/states', {}, setIsLocationStateLoading);
    const locationStatesForDropdown = _.map(locationStates, locationState => ({
      key: `${locationState['code']}_${locationState['name']}`,
      text: locationState['name'],
      value: locationState['code'],
    }));
    setLocationStates(locationStatesForDropdown);
  };

  const getLocationRegions = async (value) => {
    const locationRegions = await NetworkUtil.getAsync(`/apis/data/base/location/states/${value}`, {}, setIsLocationRegionLoading);
    const locationRegionsForDropdown = _.map(locationRegions, locationRegion => ({
      key: `${locationRegion['code']}_${locationRegion['name']}`,
      text: locationRegion['name'],
      value: locationRegion['code'],
    }));
    setLocationRegions(locationRegionsForDropdown);
  };

  const validateForm = () => {
    const result = _.reduce(registrationInfo, (res, value, key) => {
      res[key] = !_.isEmpty(value);
      return res;
    }, {});

    setValidationResult(result);
    return result;
  };

  useEffect(() => {
    (async () => await getLocationStates())();
  }, []);

  return (
    <Container fluid className={'carmon-view-container'}
               style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Grid>
        <Grid.Row>
          <Grid.Column style={{maxWidth: 660}}>
            <Segment attached="top" color={THEME}>
              <Button content="뒤로가기" icon="arrow circle left" labelPosition="left" as={Link} to={'/'} replace />
            </Segment>
            <Segment attached>
              <Header textAlign={'center'}>
                카몬 딜러 가입
                <Header.Subheader>중고차? 다 카몬이랑 해!</Header.Subheader>
              </Header>
              <Form loading={inProceeding}>
                <Grid textAlign="left">
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Ref innerRef={nameRef}>
                        <Form.Field
                          control={Input}
                          label="이름"
                          placeholder="이름"
                          value={registrationInfo.name}
                          onChange={(e, {value}) => setRegistrationInfo({...registrationInfo, name: value})}
                          onKeyUp={e => (e.key === 'Enter' && positionTitleRef.current.querySelector('input').focus())}
                          error={validationResult?.name === false && {
                            content: '이름을 입력해주세요.',
                            pointing: 'above',
                          }}
                        />
                      </Ref>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Ref innerRef={positionTitleRef}>
                        <Form.Field
                          control={Input}
                          label="직함"
                          placeholder="직함(과장, 차장 등)"
                          value={registrationInfo.positionTitle}
                          onChange={(e, {value}) => setRegistrationInfo({...registrationInfo, positionTitle: value})}
                          onKeyUp={e => (e.key === 'Enter' && employeeNumberRef.current.querySelector('input').focus())}
                          error={validationResult?.positionTitle === false && {
                            content: '직함을 입력해주세요.',
                            pointing: 'above',
                          }}
                        />
                      </Ref>
                    </Grid.Column>
                    <Grid.Column width={7}>
                      <Ref innerRef={employeeNumberRef}>
                        <Form.Field
                          control={Input}
                          label="종사원번호"
                          placeholder="유효한 종사원번호를 입력해주세요."
                          value={registrationInfo.employeeNumber}
                          onChange={(e, {value}) => setRegistrationInfo({...registrationInfo, employeeNumber: value})}
                          onKeyUp={async e => (e.key === 'Enter' && companyNameRef.current.querySelector('input').focus())}
                          error={validationResult?.employeeNumber === false && {
                            content: '종사원번호를 입력해주세요.',
                            pointing: 'above',
                          }}
                        />
                      </Ref>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Form.Field>
                        <label>주요활동지역</label>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={8}>
                              <Dropdown
                                placeholder="시/도"
                                fluid
                                search
                                selection
                                loading={isLocationStateLoading}
                                value={registrationInfo.locationState}
                                options={locationStates}
                                onChange={async (e, {value}) => {
                                  setRegistrationInfo({...registrationInfo, locationState: value});
                                  await getLocationRegions(value);
                                }}
                                error={validationResult?.locationState === false}
                              />
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Dropdown
                                placeholder="시/군/구"
                                fluid
                                search
                                selection
                                loading={isLocationRegionLoading}
                                value={registrationInfo.locationRegion}
                                options={locationRegions}
                                onChange={async (e, {value}) => {
                                  setRegistrationInfo({...registrationInfo, locationRegion: value});
                                }}
                                error={validationResult?.locationRegion === false}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Form.Field>
                      <Form.Field>
                        <label>소속매매상사</label>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={8}>
                              <Ref innerRef={companyNameRef}>
                                <Form.Field
                                  control={Input}
                                  placeholder="매매상사명"
                                  value={registrationInfo.companyName}
                                  onChange={(e, {value}) => setRegistrationInfo({
                                    ...registrationInfo,
                                    companyName: value,
                                  })}
                                  onKeyUp={async e => (e.key === 'Enter' && companyContactNumberRef.current.querySelector('input').focus())}
                                  error={validationResult?.companyName === false && {
                                    content: '매매상사명을 입력해주세요.',
                                    pointing: 'above',
                                  }}
                                />
                              </Ref>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Ref innerRef={companyContactNumberRef}>
                                <Form.Field
                                  control={Input}
                                  placeholder="매매상사전화번호"
                                  value={registrationInfo.companyContactNumber}
                                  maxLength={12}
                                  onChange={(e, {value}) => {
                                    const refinedValue = _.replace(value, /[^0-9]/g, '');
                                    setRegistrationInfo({...registrationInfo, companyContactNumber: refinedValue});
                                  }}
                                  error={validationResult?.companyContactNumber === false && {
                                    content: '매매상사전화번호를 입력해주세요.',
                                    pointing: 'above',
                                  }}
                                />
                              </Ref>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Header as={'h5'}>
                        <Header.Subheader>
                          <div
                            style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginBottom: 8}}>
                            <Icon name={'exclamation circle'} />
                            <div style={{flex: 1}}>
                              종사원증의 매매상사 정보와 입력한 매매상사 정보가 다를 경우, 추가 확인 절차가 필요합니다.<br />이에 따라 가입 승인이 다소 지연될 수 있습니다.
                            </div>
                          </div>
                          <div
                            style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginBottom: 8}}>
                            <Icon name={'exclamation circle'} />
                            <div style={{flex: 1}}>
                              매매상사 정보가 갱신되지 않았을 경우 종사원증 정보를 갱신한 후 가입 신청을 진행하시면, 보다 빠르게 카몬 서비스를 이용하실 수 있습니다.
                            </div>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                            <Icon name={'exclamation circle'} />
                            <div style={{flex: 1}}>
                              입력하신 사항과 종사원증만으로 확인이 어려울 경우에만 매매상사전화번호를 이용합니다.
                            </div>
                          </div>
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        type={'button'}
                        fluid
                        primary
                        content={'가입신청하기'}
                        disabled={inProceeding}
                        onClick={async e => {
                          const result = validateForm();
                          if (!_.every(result, i => (i === true))) return false;

                          setRegistrationError(null);
                          setIsConfirmDialogOpen(true);
                        }} />
                    </Grid.Column>
                    <Modal
                      basic
                      open={isConfirmDialogOpen}
                      size={'tiny'}>
                      <Header>
                        <Icon name={'exclamation circle'} /> 가입 전 마지막 확인!
                      </Header>
                      <Modal.Content>
                        {registrationInfo.name} {registrationInfo.positionTitle}님! 카몬 회원가입을 진행합니다!<br /><br />
                        휴대전화번호 : {registrationInfo.contactNumber}<br />
                        종사원번호 : {registrationInfo.employeeNumber}<br />
                        소속상사 : {registrationInfo.companyName} | {registrationInfo.companyContactNumber}<br />
                      </Modal.Content>
                      <Modal.Actions>
                        <Button basic color="red" inverted onClick={() => setIsConfirmDialogOpen(false)}>
                          <Icon name="remove" /> 다시입력
                        </Button>
                        <Button primary inverted onClick={async () => {
                          try {
                            const newDealer = await NetworkUtil.postAsync('/apis/dealer/registration', registrationInfo, setInProceeding);
                            setIsConfirmDialogOpen(false);
                            setIsWelcomeDialogOpen(newDealer.loginId === registrationInfo.loginId);
                          } catch (errorData) {
                            Logger.error(errorData);
                            setRegistrationError(errorData);
                          }
                        }}>
                          <Icon name="checkmark" /> 카몬가입하기
                        </Button>
                      </Modal.Actions>
                      <Message error hidden={registrationError === null}>
                        <Message.Header>
                          <Icon name={'exclamation triangle'} /> 딜러 가입에 문제가 있습니다.
                        </Message.Header>
                        <Message.Content>
                          {registrationError?.message}
                        </Message.Content>
                      </Message>
                    </Modal>
                  </Grid.Row>
                </Grid>
              </Form>
              <Modal
                basic
                open={isWelcomeDialogOpen}
                size={'small'}>
                <Modal.Content style={{textAlign: 'center', paddingBottom: 0}}>
                  <Header inverted>
                    <Icon name={'handshake outline'} />
                    <Header.Content>"자동차? 다 <span
                      style={{color: BASE_STYLES.THEME_COLOR, fontSize: '1.4em'}}>카몬</span>이랑 해!" <span
                      style={{color: BASE_STYLES.THEME_COLOR, fontSize: '1.4em'}}>카몬</span>에 오신
                      것을 환영합니다!
                    </Header.Content>
                  </Header>
                  {/*https://www.youtube.com/watch?v=hKbYX74Va-U*/}
                  {/*https://i.ytimg.com/vi/hKbYX74Va-U/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD1ZCHPiLbPQ9Q9vPDfxBB64bUYLQ*/}
                  <iframe width="616" height="346" src="https://www.youtube.com/embed/hKbYX74Va-U?autoplay=1&mute=1"
                          title="Welcome to Carmon!" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </Modal.Content>
                <Modal.Actions>
                  <Button inverted onClick={e => {
                    setIsWelcomeDialogOpen(false);
                    history.replace('/');
                  }}>
                    <Icon name="checkmark" /> 닫기
                  </Button>
                </Modal.Actions>
              </Modal>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
