import React from 'react';
import {Header, List, Segment} from 'semantic-ui-react';
import _ from 'lodash';
import {BASE_STYLES, THEME} from '../../consts/consts';

import {CAR_CRITERION_STEPS} from '../../views/Bid/BidList';
import {CommonUtil} from '../../utils/CommonUtil';

function SFCheckBox({
                      base,
                      conditions,
                      condKey: key,
                      renderIf = true,
                      isInner,
                      isLoading = false,
                      onItemClicked,
                      children,
                    }) {
  if (!renderIf) return null;

  const {title, data} = base[key];
  const {[key]: condition} = conditions;
  let items = data;

  // 다음 항목의 데이터가 있는지 확인
  // TODO - State가 어디든 바뀔 때마다 계속 Re-render 되는데 이거 정상인가?

  function _buildItemKey(idx) {
    return `check_${key}_${idx}`;
  }

  let conditionKeyIndex = _.findIndex(CAR_CRITERION_STEPS, step => (step === key));
  const nextKey = CAR_CRITERION_STEPS[conditionKeyIndex + 1];
  const parentKey = CAR_CRITERION_STEPS[conditionKeyIndex - 1];

  let labelKey = 'label', valueKey = 'value';
  if (key !== 'country' && _.includes(CAR_CRITERION_STEPS, key)) {
    labelKey = `${key}Name`;
    valueKey = `${key}No`;
  }

  let parentLabelKey = 'label', parentValueKey = 'value';
  if (parentKey) {
    parentLabelKey = `${parentKey}Name`;
    parentValueKey = `${parentKey}No`;
  }

  function _renderListItem(item, idx) {
    return (
      <List.Item
        key={_buildItemKey(idx)}
        className={'sf-check-box-list-item'}
        name={key}
        label={item[labelKey]}
        value={item[valueKey]}
        nextstep={_.has(item, 'nextStepCount') ? (item['nextStepCount'] !== 0 ? 'Y' : 'N') : 'X'}
        onClick={(e, props) => {
          e.stopPropagation();
          onItemClicked(e, props);
        }}>
        <List.Icon
          name={_.includes(condition, item[valueKey]) ? 'check circle' : 'circle outline'}
          color={THEME}
          size={'large'}
        />
        <List.Content>
          {
            (key !== 'country' && _.includes(CAR_CRITERION_STEPS, key))
              ?
              <List.Description>{item[labelKey]}
                <span style={{
                  fontSize: BASE_STYLES.FONT_SIZE.SUB,
                  fontWeight: 'bold',
                  color: 'orange',
                  marginLeft: 4,
                }}>{item.carCount}</span> {/*(CommonUtil.RunMode.isDev() && _.has(item, 'nextStepCount')) && `[${item['nextStepCount']}]`*/}
              </List.Description>
              :
              <List.Description>{item[labelKey]}</List.Description>
          }
          {(children && _.includes(condition, item[valueKey])) && children}
        </List.Content>
      </List.Item>
    );
  }

  function _renderInner() {
    if (!items || items.length === 0) return null;

    return (
      <List selection style={{padding: `4px 0`}}>
        {_.map(items, (item, idx) => {
            if (key !== 'country' && _.includes(CAR_CRITERION_STEPS, key)) {
              if (_.isEmpty(condition)) return _renderListItem(item, idx);
              if (item['nextStepCount'] !== 0) {
                if (_.includes(condition, item[valueKey])) {
                  return _renderListItem(item, idx);
                } else {
                  if (nextKey === undefined) return _renderListItem(item, idx);
                  return null;
                }
              } else {
                if (nextKey !== undefined) {
                  if (base[nextKey].data.length === 0) {
                    if (_.includes(condition, item[valueKey])) {
                      return _renderListItem(item, idx);
                    } else {
                      if (_.sumBy(base[key], 'nextStepCount') === 0) return _renderListItem(item, idx);
                      else return null;
                      // TODO - 같은 레벨에 nextStepCount가 0인 경우 선택하지 않은 다른 항목도 표시되는 문제 (그랜저 XG - L35 & 아제라)
                    }
                  } else return null;
                } else return _renderListItem(item, idx);
              }
            } else {
              return _renderListItem(item, idx);
            }
          },
        )}
      </List>
    );
  }

  return (
    <>
      {title && <Header as={'h5'} attached={'top'}>{title}</Header>}
      {
        isInner
          ? _renderInner()
          : (
            <Segment
              attached
              loading={isLoading}
              style={{
                minHeight: (_.includes(CAR_CRITERION_STEPS, key) && key !== 'country') ? 300 : 0,
                maxHeight: 300,
                overflowY: isLoading ? 'hidden' : 'auto',
                padding: `2px 10px`,
                borderRadius: '0 0 .28571429rem .28571429rem',
              }}>
              {_renderInner()}
            </Segment>)
      }
    </>
  );
}

export default SFCheckBox;
