import {atom} from 'recoil';
import {THEME} from '../../consts/consts';

const envAtom = atom({
  key: 'ATOM_ENV',
  default: {
    theme: THEME,
  },
});

export {envAtom};
