import {Step, Icon, Sticky} from 'semantic-ui-react';
import {withRouter} from 'react-router-dom';

function Sidebar({location, match, history}) {
  console.log(location, match, history);

  return (
    <Sticky offset={100}>
      <Step.Group vertical>
        <Step
          active={(match.path === '/register/step1')}
          completed={!(match.path === '/register/step1')}
          disabled={!(match.path === '/register/step1')}>
          <Icon name="check" />
          <Step.Content>
            <Step.Title>이용약관 동의</Step.Title>
          </Step.Content>
        </Step>

        <Step
          active={(match.path === '/register/step2')}
          completed={!(match.path === '/register/step2')}
          disabled={!(match.path === '/register/step2')}>
          <Icon name="signup" />
          <Step.Content>
            <Step.Title>가입신청</Step.Title>
          </Step.Content>
        </Step>

        <Step
          active={(match.path === '/register/completion')}
          completed={!(match.path === '/register/completion')}
          disabled={!(match.path === '/register/completion')}>
          <Icon name="user secret" />
          <Step.Content>
            <Step.Title>딜러활동 시작</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    </Sticky>
  );
}

export default withRouter(Sidebar);
