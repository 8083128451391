import {Header, Image} from 'semantic-ui-react';
import {BASE_STYLES, CARMON_INFO} from '../../consts/consts';

import Carzen from '../../images/logos/partners/carzen.png';
import Kaat from '../../images/logos/partners/kaat.png';
import UCar from '../../images/logos/partners/ucar.png';

function Footer() {
  return (
    <footer id={'carmon-footer'} className={'carmon-footer'}>
      <Header as={'h3'} style={{color: BASE_STYLES.THEME_ITEM_COLOR, marginBottom: 0}}>
        중고차? 다 카몬이랑 해! | 카몬
      </Header>
      <hr />
      <div
        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{flex: 1}}>
          <Header
            as={'h5'}
            style={{
              fontSize: BASE_STYLES.FONT_SIZE.SUB,
              color: BASE_STYLES.THEME_ITEM_COLOR,
              margin: '0 0 6px 0',
            }}>
            대표자 : {CARMON_INFO.REPRESENTATIVE} | 개인정보관리책임자 : {CARMON_INFO.PERSONAL_INFO} | 사업자등록번호
            : {CARMON_INFO.BUSINESS_NUMBER} | 통신판매업신고 : {CARMON_INFO.SELLER_NUMBER}<br />
            사업장소재지 : ({CARMON_INFO.POSTAL_CODE}) {CARMON_INFO.ADDRESS} | 제휴문의 : {CARMON_INFO.CONTACT_EMAIL}
          </Header>
          <Header
            as={'h5'}
            style={{
              fontSize: BASE_STYLES.FONT_SIZE.SUB,
              color: BASE_STYLES.THEME_ITEM_COLOR,
              margin: 0,
            }}>
            Copyright © 카몬 All Rights Reserved.
          </Header>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 16,
        }}>
          <Image src={Carzen} style={{width: 120}} />
          <Image src={Kaat} style={{width: 100}} />
          <Image src={UCar} style={{width: 66}} />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
