import {Button, Container, Form, Header, Icon, Input, Segment} from 'semantic-ui-react';
import {useRecoilValue} from 'recoil';
import {jwtAtom} from '../../../recoil/atoms';
import jwtDecode from 'jwt-decode';
import {Logger} from '../../../utils/Logger';
import React, {useEffect, useState} from 'react';
import {NetworkUtil} from '../../../utils/NetworkUtil';
import {THEME} from '../../../consts/consts';
import {Link} from 'react-router-dom';

export default function Favorite({match, location, history}) {
  const jwt = useRecoilValue(jwtAtom);

  let decodedJwt = {};
  try {
    decodedJwt = jwtDecode(jwt);
  } catch (error) {
    Logger.error(error);
  }

  const loginId = decodedJwt.sub;
  const [dealer, setDealer] = useState({});

  useEffect(() => {
    NetworkUtil.get(`/apis/dealer/${loginId}`, {},
      data => setDealer(data));
  }, [loginId, setDealer]);

  return (
    <Container className={'carmon-view-container'}
               style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
      <Segment basic style={{width: 660}}>
        <Segment attached="top" color={THEME}>
          <Button content="뒤로가기" icon="arrow circle left" labelPosition="left" as={Link} to={'/my-info'} replace />
        </Segment>
        <Segment attached>
          <Header as={'h4'}>
            <Header.Content>
              <Icon name={'user'} />
              프로필 수정
            </Header.Content>
            <Header.Subheader>
              카몬에서 사용되는 프로필과 계정정보를 수정하실 수 있습니다.
            </Header.Subheader>
          </Header>
        </Segment>
        <Segment attached={'bottom'}>
          <Form>
            <Segment>
              <Form.Group widths={'equal'}>
                <Form.Field
                  control={Input}
                  label="아이디"
                  placeholder="아이디"
                  value={dealer.loginId}
                  readOnly
                />

                <Form.Field
                  control={Input}
                  label="이름"
                  placeholder="이름"
                  value={dealer.name}
                  readOnly
                />
              </Form.Group>
            </Segment>

            <Segment basic loading={false} style={{padding: 0}}>
              <Segment.Group>
                <Segment>
                  <Form.Group widths={'equal'}>
                    <Form.Field
                      control={Input}
                      type={'password'}
                      label="비밀번호"
                      placeholder="비밀번호"
                      value={dealer.password}
                    />

                    <Form.Field
                      control={Input}
                      type={'password'}
                      label="비밀번호확인"
                      placeholder="비밀번호확인"
                      value={dealer.password}
                    />
                  </Form.Group>
                </Segment>
                <Segment textAlign={'right'}>
                  <Button type={'button'} content={'변경하기'} />
                </Segment>
              </Segment.Group>
            </Segment>

            <Segment basic loading={false} style={{padding: 0}}>
              <Segment.Group>
                <Segment>
                  <Form.Group widths={'equal'}>
                    <Form.Field
                      control={Input}
                      label="직함"
                      placeholder="직함"
                      value={dealer.positionTitle}
                    />

                    <Form.Field
                      control={Input}
                      label="휴대전화번호"
                      placeholder="휴대전화번호"
                      value={dealer.contactNumber}
                    />
                  </Form.Group>
                </Segment>
                <Segment textAlign={'right'}>
                  <Button type={'button'} content={'변경하기'} />
                </Segment>
              </Segment.Group>
            </Segment>
          </Form>
        </Segment>
      </Segment>
    </Container>
  );
}
