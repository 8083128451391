import _ from 'lodash';

const IMAGE_TITLES = {
  outFront: '정면',
  outLeft: '측면(왼쪽)',
  outRight: '측면(오른쪽)',
  outRear: '후방',
  outTop: '상판',
  inPanel: '계기판',
  inSeat: '시트',
  inDriverDoor: '운전석 도어',
  inNavigation: '네비게이션',
  inCenterFascia: '센터페시아',
  etc1: '기타1',
  etc2: '기타2',
  etc3: '기타3',
  etc4: '기타4',
  etc5: '기타5',
};

export const BidUtil = {
  getImageTitle(position) {
    return IMAGE_TITLES[position] || '';
  },
  showBidAmountInfo(bidStateCode) {
    return _.includes(['MBPC', 'MBTW', 'MBTA', 'MBTS', 'MBTC'], bidStateCode);
  },
  showBidContract(bidStateCode) {
    return _.includes(['MBTS', 'MBTC'], bidStateCode);
  },
  getStateColor(bidStateCode) {
    let stateColor = 'yellow';

    switch (bidStateCode) {
      case 'MBTW':
        stateColor = 'yellow';
        break;
      case 'MBPC':
        stateColor = 'yellow';
        break;
      case 'MBTA':
        stateColor = 'green';
        break;
      case 'MBTS':
        stateColor = 'orange';
        break;
      case 'MBTC':
        stateColor = 'blue';
        break;
      case 'MBW':
        stateColor = 'yellow';
        break;
      case 'SNS':
        stateColor = 'grey';
        break;
      case 'MBL':
        stateColor = 'grey';
        break;
      default:
        stateColor = 'yellow';
    }

    return stateColor;
  },
};