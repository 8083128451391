import {Route, Switch} from 'react-router-dom';
import MyBidListView from './MyBidList';
import MyBidItemView from './MyBidItem';
import MyBidContractView from './MyBidContract';
import NotFound from '../Error/NotFound';

export default function MyBid({match, location, history}) {
  return (
    <Switch>
      <Route path={`${match.path}/list`} component={MyBidListView} />
      <Route exact path={`${match.path}/item/:auctionId`} component={MyBidItemView} />
      <Route exact path={`${match.path}/item/:auctionId/contract`} component={MyBidContractView} />
      <Route path={`${match.path}/**`} component={NotFound} />
    </Switch>
  );
}
