import {useCallback, useEffect} from 'react';
import {useRecoilState} from 'recoil';
import {Route, Switch, useHistory, useLocation, useRouteMatch} from 'react-router-dom';

import NavBar from './components/common/Navbar';
import Footer from './components/common/Footer';

import LoginView from './views/Login/Login';
import Register from './views/Register';
import DashboardView from './views/Dashboard/Dashboard';
import Bid from './views/Bid';
import MyBid from './views/MyBid';
import MyInfo from './views/MyInfo';
import FindInfo from './views/FindInfo';

import {AUTH_UNNECESSARY_PATHS, ERROR_CODE, LS_KEYS} from './consts/consts';

import axios from 'axios';
import './configs/axiosConfig';

import {asyncRefreshToken, refreshToken} from './providers/authProvider';
import {jwtAtom, loginStateAtom, tokenRefreshingAtom} from './recoil/atoms';
import {Logger} from './utils/Logger';

import _ from 'lodash';

import 'semantic-ui-less/semantic.less';
import './index.css';
import './App.css';
import NotFound from './views/Error/NotFound';

let historyListener;

let refreshSubscribers = [];
const onTokenRefreshed = accessToken => {
  _.map(_.uniq(refreshSubscribers), callback => callback(accessToken));
  refreshSubscribers = [];
};
const addRefreshSubscriber = callback => refreshSubscribers.push(callback);

const isAuthPath = pathname => {
  return AUTH_UNNECESSARY_PATHS.findIndex(path => pathname.startsWith(path)) === -1;
};

function App() {
  const [isTokenRefreshing, setIsTokenRefreshing] = useRecoilState(tokenRefreshingAtom);
  const [loginState, setLoginState] = useRecoilState(loginStateAtom);
  const [jwt, setJwt] = useRecoilState(jwtAtom);
  const match = useRouteMatch(), location = useLocation(), history = useHistory();

  if (!historyListener) {
    historyListener = history.listen(async (location, action) => {
      const isLoggedIn = JSON.parse(localStorage.getItem(LS_KEYS.LOGIN_CHECK_KEY));
      const pathname = location.pathname;
      Logger.debug('[HistoryListener]', isLoggedIn, location, action, isAuthPath(pathname));

      switch (action) {
        case 'POP':
          if (pathname !== '/' && !isLoggedIn) {
            localStorage.removeItem(LS_KEYS.LOGIN_CHECK_KEY);
            history.replace('/');
          }
          break;

        // case 'PUSH':
        //   if (pathname !== '/' && isAuthPath(pathname)) await doAuthRefresh(isLoggedIn, history);
        //   break;
      }
    });
  }

  axios.interceptors.response.use(
    response => response,
    async error => {
      const {
        config,
        response: {
          status,
          data: {
            code: errorCode,
          },
        },
      } = error;

      const originalRequest = config;

      if (status === 401 && (errorCode === ERROR_CODE.ACCESS_TOKEN_EXPIRED || errorCode === ERROR_CODE.UNKNOWN_UNAUTHORIZED)) {
        if (!isTokenRefreshing) {
          setIsTokenRefreshing(true);

          try {
            const accessToken = await asyncRefreshToken();

            if (accessToken) {
              setLoginState(true);
              setJwt(accessToken);

              localStorage.setItem(LS_KEYS.LOGIN_CHECK_KEY, 'true');
              axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

              setIsTokenRefreshing(false);
              onTokenRefreshed(accessToken);
            }
          } catch (e) {
            setLoginState(false);
            setJwt('');
            localStorage.removeItem(LS_KEYS.LOGIN_CHECK_KEY);

            setIsTokenRefreshing(false);
            history.replace('/');
          } finally {

          }
        }

        return new Promise((resolve) => {
          addRefreshSubscriber((accessToken) => {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            resolve(axios(originalRequest));
          });
        });
      }

      return Promise.reject(error);
    });

  /** AccessToken 갱신 관련 */

  const doAuthRefresh = useCallback((isLoggedIn, history) => async () => {
    if (isLoggedIn) {
      setIsTokenRefreshing(true);

      await refreshToken(accessToken => {
        setLoginState(true);
        setJwt(accessToken);

        localStorage.setItem(LS_KEYS.LOGIN_CHECK_KEY, 'true');
        setIsTokenRefreshing(false);

        if (history.location.pathname === '/') history.replace('/dashboard');
      }, errorData => {
        console.log('[Refresh]', errorData);

        setLoginState(false);
        setJwt('');

        setIsTokenRefreshing(false);
        localStorage.removeItem(LS_KEYS.LOGIN_CHECK_KEY);

        history.replace('/');
      });
    } else {
      setLoginState(false);
      setJwt('');

      history.replace('/');
    }
  }, [setIsTokenRefreshing, setLoginState, setJwt]);

  useEffect(() => {
    (async () => {
      if (!isAuthPath(location.pathname)) return false;
      if (!isTokenRefreshing) {
        let isLoggedIn = JSON.parse(localStorage.getItem(LS_KEYS.LOGIN_CHECK_KEY));
        await doAuthRefresh(isLoggedIn, history);
      }
    })();
  }, [doAuthRefresh, history, isTokenRefreshing, location.pathname]);

  return (
    <>
      {!_.endsWith(location.pathname, '/contract') ? <NavBar /> : null}
      <section id="carmon-wrapper">
        <Switch>
          {/*<Route exact path={['/', '/Login']} component={LoginView} />*/}
          <Route exact path="/" component={LoginView} />
          <Route path="/find-info" component={FindInfo} />
          <Route path="/register" component={Register} />
          <Route exact path="/dashboard" component={DashboardView} />
          <Route path="/bid" component={Bid} />
          <Route path="/my-bid" component={MyBid} />
          <Route path="/my-info" component={MyInfo} />
          <Route path="*" component={NotFound} />
        </Switch>
        {!_.endsWith(location.pathname, '/contract') ? <Footer /> : null}
      </section>
    </>
  );
}

export default App;
