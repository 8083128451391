const LS_KEYS = {
  LOGIN_CHECK_KEY: 'isLoggedIn',
  KEPT_LOGIN_ID: 'keptLoginId',
};

const CARMON_INFO = {
  REPRESENTATIVE: '성백환',
  PERSONAL_INFO: '성백환',
  REPRESENTATIVE_TEL: '010-3132-0753',
  ADDRESS: '서울특별시 구로구 경인로 579-1, B동 308호 (신도림동, 안성빌딩)',
  POSTAL_CODE: '08212',
  BUSINESS_NUMBER: '369-11-01281',
  SELLER_NUMBER: '제 2021-서울구로-2508호',
  CONTACT_EMAIL: 'carmonster000@gmail.com',
};

const THEME = 'teal';
/*const BASE_STYLES = {
  THEME_COLOR: '#696969',
  THEME_SUB_COLOR: '#feb948',
  THEME_ITEM_COLOR: '#f0f0f0',
  THEME_BG_COLOR: '#00aed9',
  FONT_COLOR: 'rgb(27, 28, 29)',
  BORDER_COLOR: 'rgba(34,36,38,.1)',
};*/

const BASE_STYLES = {
  THEME_COLOR: '#00aed9',
  THEME_SUB_COLOR: '#feb948',
  THEME_ITEM_COLOR: '#f0f0f0',
  THEME_BG_COLOR: '#696969',
  FONT_COLOR: 'rgb(27, 28, 29)',
  BORDER_COLOR: 'rgba(34,36,38,.1)',
  FONT_SIZE: {
    HEADER: {
      MAIN: 36,
      SUB: 26,
    },
    MAX: 22,
    BASE: 18,
    SUB: 16,
    MIN: 14,
  },
};

const ERROR_CODE = {
  UNKNOWN_UNAUTHORIZED: 'UNKNOWN_UNAUTHORIZED',
  BAD_CREDENTIALS: 'BAD_CREDENTIALS',
  DISABLED: 'DISABLED',
  LOCKED: 'LOCKED',
  ACCESS_TOKEN_EXPIRED: 'ACCESS_TOKEN_EXPIRED',
  REFRESH_TOKEN_EXPIRED: 'REFRESH_TOKEN_EXPIRED',
  WAITING_ADMIN_CONFIRMATION: 'WAITING_ADMIN_CONFIRMATION',
  DUPLICATE_RESOURCE: 'DUPLICATE_RESOURCE',
  SERVER_ERROR: 'SERVER_ERROR',
};

const ENVIRONMENTS = {
  RUN_MODE: `${process.env.REACT_APP_RUN_MODE}`,
  API_BASE_URL: `${process.env.REACT_APP_API_BASE_URL}`,
  PRINT_DATA_TO_LOGGER: (`${process.env.REACT_APP_PRINT_DATA_TO_LOGGER}` === 'true'),
};

const AUTH_UNNECESSARY_PATHS = ['/register'];

export {
  LS_KEYS,
  CARMON_INFO,
  THEME,
  BASE_STYLES,
  ERROR_CODE,
  ENVIRONMENTS,
  AUTH_UNNECESSARY_PATHS,
};
