import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Dropdown, Grid, Icon, Label, Ref, Segment, Sticky} from 'semantic-ui-react';
import {NetworkUtil} from '../../utils/NetworkUtil';
import {useRecoilValue} from 'recoil';
import {tokenRefreshingAtom} from '../../recoil/atoms';
import {BackgroundImage} from 'react-image-and-background-image-fade';
import '@inovua/reactdatagrid-community/index.css';
import _ from 'lodash';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import useCountDown from 'react-countdown-hook';
import moment from 'moment';
import 'moment/locale/ko';
import {CommonUtil} from '../../utils/CommonUtil';
import {BASE_STYLES} from '../../consts/consts';
import {DateInput} from 'semantic-ui-calendar-react-yz';
import {Link} from 'react-router-dom';
import {BidUtil} from '../../utils/BidUtil';

const columns = [
  {
    name: 'auctionId',
    header: 'ID',
    defaultVisible: false,
    visible: false,
    sortable: false,
  },
  {
    name: 'repImage',
    header: '이미지',
    defaultWidth: 80,
    sortable: false,
    render: ({value}) => (
      <BackgroundImage
        lazyLoad
        src={value}
        style={{
          alignSelf: 'stretch',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '.28571429rem',
        }}
        height={'48px'} />
    ),
    headerProps: {
      style: {border: 0, textAlign: 'center'},
    },
  },
  {
    name: 'licensePlateNumber',
    header: '차량번호',
    defaultWidth: 100,
    headerProps: {
      style: {border: 0},
    },
  },
  {
    name: 'carName',
    header: '모델',
    headerProps: {
      style: {border: 0},
    },
  },
  {
    name: 'carSeriesName',
    header: '상세',
    defaultWidth: 200,
    headerProps: {
      style: {border: 0},
    },
  },
  {
    name: 'finishDate',
    header: '종료일시',
    render: ({value}) => moment(value).format('YYYY.MM.DD HH:mm'),
    headerProps: {
      style: {border: 0},
    },
  },
  {
    name: 'c-bidTime',
    header: '남은시간',
    sortable: false,
    render: ({data}) => {
      return (
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <BidTimeLabel bidData={data} />
        </div>
      );
    },
    headerProps: {
      style: {border: 0, textAlign: 'center'},
    },
  },
  {
    name: 'myBidCount',
    header: '내입찰',
    maxWidth: 100,
    textAlign: 'right',
    render: ({value}) => <span>{CommonUtil.Unit.format(value)}회</span>,
    headerProps: {
      style: {border: 0},
    },
  },
  {
    name: 'totalBidCount',
    header: '전체입찰',
    maxWidth: 100,
    textAlign: 'right',
    render: ({value}) => <span>{CommonUtil.Unit.format(value)}회</span>,
    headerProps: {
      style: {border: 0},
    },
  },
  {
    name: 'myCurrentBidAmount',
    header: '내입찰가',
    maxWidth: 160,
    textAlign: 'right',
    render: ({value}) => <span>{CommonUtil.Unit.format(value)}원</span>,
    headerProps: {
      style: {border: 0},
    },
  },
  {
    name: 'winBidAmount',
    header: '낙찰가',
    maxWidth: 160,
    textAlign: 'right',
    render: ({value}) => <span>{CommonUtil.Unit.format(value)}원</span>,
    headerProps: {
      style: {border: 0},
    },
  },
  {
    name: 'c-bidState',
    header: '상태',
    headerAlign: 'start',
    textAlign: 'center',
    defaultWidth: 160,
    sortable: false,
    render: ({data}) => <Label color={BidUtil.getStateColor(data.myBidStateCode)}>{data.myBidStateLabel}</Label>,
    headerProps: {
      style: {border: 0, textAlign: 'center'},
    },
  },
  {
    name: 'c-payAmount',
    header: '입금정보',
    sortable: false,
    defaultWidth: 240,
    textAlign: 'right',
    render: ({data}) => (
      BidUtil.showBidAmountInfo(data.myBidStateCode) && <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
      }}>
        <div style={{alignSelf: 'stretch', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <span>차량</span>
          <span>{CommonUtil.Unit.format(data.bidAmount)}</span>
        </div>
        <div style={{alignSelf: 'stretch', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <span>탁송비</span>
          <span>{CommonUtil.Unit.format(data.transportAmount)}</span>
        </div>
        <div style={{alignSelf: 'stretch', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <span>수수료</span>
          <span>{CommonUtil.Unit.format(data.feeAmount)}</span>
        </div>
        <div style={{
          alignSelf: 'stretch',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderTop: '1px solid #69696966',
        }}>
          <span>합계</span>
          <span>{CommonUtil.Unit.format(data.totalAmount)}</span>
        </div>
      </div>
    ),
    headerProps: {
      style: {border: 0},
    },
  },
  {
    name: 'c-accountInfo',
    header: '입금계좌',
    sortable: false,
    defaultWidth: 240,
    textAlign: 'right',
    render: ({data}) => (
      BidUtil.showBidAmountInfo(data.myBidStateCode) && <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
      }}>
        <div style={{alignSelf: 'stretch', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <span>은행</span>
          <span>{data.cmBank}</span>
        </div>
        <div style={{alignSelf: 'stretch', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <span>예금주</span>
          <span>{data.cmAccountOwner}</span>
        </div>
        <div style={{alignSelf: 'stretch', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <span>계좌번호</span>
          <span>{data.cmAccountNumber}</span>
        </div>
      </div>
    ),
    headerProps: {
      style: {border: 0},
    },
  },
  {
    name: 'c-transport',
    header: '탁송정보',
    sortable: false,
    defaultWidth: 400,
    render: ({data}) => (
      BidUtil.showBidAmountInfo(data.myBidStateCode) && <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
      }}>
        <div style={{alignSelf: 'stretch', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
          <span>탁송지</span>
          <span>{data.buyerAddress}</span>
        </div>
        <div style={{
          alignSelf: 'stretch',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderTop: '1px solid #69696966',
        }}>
          <div
            style={{alignSelf: 'stretch', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <span style={{marginRight: 6}}>탁송기사 - </span>
            <span>{data.transportManagerName ? `${data.transportManagerName} | ${data.transportManagerContactNumber}` : '배정대기'}</span>
          </div>
        </div>
      </div>
    ),
    headerProps: {
      style: {border: 0},
    },
  },
  {
    name: 'c-action',
    header: '',
    sortable: false,
    defaultWidth: 170,
    textAlign: 'center',
    textWrap: 'wrap-text',
    render: ({data}) => (
      BidUtil.showBidContract(data.myBidStateCode) &&
      <Button size={'tiny'} as={Link} to={`/my-bid/item/${data.auctionId}/contract`} target={'_blank'}
              style={{margin: 0}}>
        <Icon name={'file alternate outline'} />
        계약서 보기
      </Button>
    ),
    headerProps: {
      style: {border: 0},
    },
  },
];

const defaultSortInfo = _.map(columns, (v, k) => {
  return {name: v['name'], dir: 0};
});

const header = 90;
const footer = 240;
const margin = 12 * 3;
const padding = 15 * 2;
const top = 188;

const SortIndicator = ({direction}) => {
  return <div style={{
    display: 'inline-block',
    marginLeft: 6,
  }}>
    <Icon name={'caret up'}
          style={{
            position: 'absolute',
            bottom: '54%',
            color: direction === 1 ? BASE_STYLES.THEME_SUB_COLOR : BASE_STYLES.THEME_BG_COLOR,
          }} />
    <Icon name={'caret down'}
          style={{
            position: 'absolute',
            top: '34%',
            color: direction === -1 ? BASE_STYLES.THEME_SUB_COLOR : BASE_STYLES.THEME_BG_COLOR,
          }} />
  </div>;
};

const renderSortTool = (direction) => {
  return <SortIndicator direction={direction} />;
};

function MyBidList({match, location, history}) {
  const isTokenRefreshing = useRecoilValue(tokenRefreshingAtom);

  const [gridRef, setGridRef] = useState(null);
  const outerHeight = useRef(0);
  const stickyRef = useRef();

  const [isSearching, setIsSearching] = useState(false);
  const [conditions, setConditions] = useState({
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().endOf('month').format('YYYY-MM-DD'),
    step: 5,
    wbonly: 'N',
  });
  const [myBids, setMyBids] = useState([]);

  const doSearch = useCallback(() => {
    setIsSearching(true);

    NetworkUtil.get(`/apis/my-bids`, conditions,
      data => {
        let height = data.length === 0 ? 0 : (data.length * 90) + 50 + (20 * 2);
        outerHeight.current = height < window.innerHeight - (header + footer + margin + padding + top) ? 0 : height;

        setTimeout(() => {
          setMyBids(data);
        }, 100);
      },
      errorData => {
      },
      () => setIsSearching(false));
  }, [conditions]);

  useEffect(() => {
    if (!isTokenRefreshing) doSearch();
  }, [doSearch, isTokenRefreshing]);

  return (
    <div className={'carmon-view-container'}>
      <Ref innerRef={stickyRef}>
        <div className={'my-bid-list'}>
          <Sticky offset={102} context={stickyRef} style={{marginBottom: 12}}>
            <Segment style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              gap: 12,
            }}>
              <div style={{alignSelf: 'stretch'}}>
                <Grid divided>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                        <label>진행상태</label>
                        <Dropdown
                          size={'small'}
                          placeholder="경매상태선택"
                          fluid
                          selection
                          value={conditions.step}
                          onChange={(e, {value}) => setConditions(prevState => ({...prevState, step: value}))}
                          options={[
                            {
                              key: 'step5',
                              text: '전체',
                              value: 5,
                              icon: 'list',
                            },
                            {
                              key: 'step1',
                              text: '경매진행중',
                              value: 1,
                              icon: 'car',
                            },
                            {
                              key: 'step2',
                              text: '경매종료',
                              value: 2,
                              icon: 'check circle outline',
                            },
                            {
                              key: 'step3',
                              text: '거래진행중',
                              value: 3,
                              icon: 'shipping fast',
                            },
                            {
                              key: 'step4',
                              text: '거래완료',
                              value: 4,
                              icon: 'handshake outline',
                            },
                          ]}
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                        <label>경매기간</label>
                        <div style={{
                          alignSelf: 'stretch',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                          <DateInput
                            className={'mybid-date-picker'}
                            closable={true}
                            localization={'ko'}
                            dateFormat={'YYYY-MM-DD'}
                            placeholder={'부터'}
                            icon={'calendar outline'}
                            iconPosition={'left'}
                            popupPosition={'bottom right'}
                            value={conditions.from}
                            onChange={(e, {value}) => {
                              setConditions(prevState => ({...prevState, from: value}));
                            }}
                          />
                          <span style={{padding: '0 6px'}}>~</span>
                          <DateInput
                            className={'mybid-date-picker'}
                            closable={true}
                            localization={'ko'}
                            dateFormat={'YYYY-MM-DD'}
                            placeholder={'까지'}
                            icon={'calendar outline'}
                            iconPosition={'left'}
                            popupPosition={'bottom right'}
                            value={conditions.to}
                            onChange={(e, {value}) => {
                              setConditions(prevState => ({...prevState, to: value}));
                            }}
                          />
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={4} style={{display: 'flex'}}>
                      <div style={{flex: 1, display: 'flex', flexDirection: 'column', gap: 8}}>
                        <label>진행상태</label>
                        <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                          <div style={{flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Checkbox
                              label="성공한 경매건만 보기"
                              checked={conditions.wbonly === 'Y'}
                              onChange={(e, {checked}) => {
                                setConditions(prevState => ({...prevState, wbonly: checked ? 'Y' : 'N'}));
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={2} style={{display: 'flex', justifyContent: 'center'}}>
                      <Button icon={'search'} content={'조회'} onClick={() => doSearch()} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </Segment>
          </Sticky>

          <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <Segment loading={isSearching} style={Object.assign({}, ...[{
              display: 'flex',
              flexDirection: 'column',
            }, (outerHeight.current === 0 ? {flex: 1} : {height: outerHeight.current})])}>
              <ReactDataGrid
                onReady={gridRef}
                idProperty="auctionId"
                showCellBorders={'horizontal'}
                style={{border: 0, fontSize: BASE_STYLES.FONT_SIZE.SUB}}
                rowHeight={90}
                showColumnMenuTool={false}
                sortable={true}
                defaultSortInfo={defaultSortInfo}
                renderSortTool={renderSortTool}
                columns={columns}
                dataSource={myBids}
                emptyText={'데이터가 없습니다.'}
                onCellClick={(event, cellProps) => {
                  const cellName = cellProps.name;
                  if (cellName !== 'c-action') {
                    const auctionId = cellProps.data.auctionId;
                    const isBidEnd = cellProps.data.isBidEnd;

                    window.open(`/${isBidEnd === 'Y' ? 'my-bid' : 'bid'}/item/${auctionId}`, '_blank');
                  }
                }} />
            </Segment>
          </div>
        </div>
      </Ref>
    </div>
  );
}

export default MyBidList;

function BidTimeLabel({bidData}) {
  function _printRemainTime(timeLeft) {
    const hours = Math.floor(timeLeft / (3600 * 1000));
    const minutes = Math.floor((timeLeft % (3600 * 1000)) / (60 * 1000));
    const seconds = (timeLeft % (60 * 1000)) / 1000;

    return `${_.padStart(_.toString(hours), 2, '0')}시간 ${_.padStart(_.toString(minutes), 2, '0')}분 ${_.padStart(_.toString(seconds), 2, '0')}초`;
  }

  function _printTime(remainingTime) {
    let hours = Math.floor(remainingTime / 3600);
    let minutes = Math.floor(remainingTime % 3600 / 60);
    let seconds = (remainingTime % 60);
    if (seconds > 0) minutes += 1;

    return `${_.padStart(_.toString(hours), 2, '0')}시간 ${_.padStart(_.toString(minutes), 2, '0')}분`;
  }

  const [timeLeft, {start, pause, resume, reset}] = useCountDown((bidData.remainingTime * 1000), 1000);

  /*useEffect(() => {
    if (bidData.isBidEnd === 'N') start();
  }, [bidData.isBidEnd, start]);*/

  return (
    <Label color={bidData.isBidEnd === 'N' ? 'orange' : 'grey'}>
      {/*{bidData.isBidEnd === 'N' ? _printRemainTime(timeLeft) : '종료'}*/}
      {bidData.isBidEnd === 'N' ? _printTime(bidData.remainingTime) : '종료'}
    </Label>
  );
}
