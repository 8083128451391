import React, {useEffect, useState} from 'react';
import {Divider, Header, Icon, List, Popup, Segment, Table} from 'semantic-ui-react';
import {Logger} from '../../utils/Logger';
import _ from 'lodash';
import {CommonUtil} from '../../utils/CommonUtil';
import {BASE_STYLES, THEME} from '../../consts/consts';
import {useRecoilValue} from 'recoil';
import {tokenRefreshingAtom} from '../../recoil/atoms';
import {NetworkUtil} from '../../utils/NetworkUtil';

function MemoizedCarInformation({auctionId, carInformationData, mileageData}) {
  const isTokenRefreshing = useRecoilValue(tokenRefreshingAtom);

  let optList = _.sortBy(_.get(carInformationData, 'cardata.optlist'), ['name']);
  let selOptList = _.sortBy(_.get(carInformationData, 'seloptlist'), ['optname']);

  // TODO : 20220120 - 선택옵션 vs 선택가능옵션의 명칭이 달라서 매핑이 불가함.
  // _.map(selOptList, selOpt => {
  //   const idx = _.findIndex(optList, opt => {
  //     let selected = _.replace(_.toUpper(opt.name), /\s/g, '');
  //     let selectable = _.replace(_.toUpper(selOpt.optname), /\s/g, '');
  //
  //     return selected === selectable;
  //   });
  //   selOpt.selected = (idx >= 0);
  // });

  const [priceRanges, setPriceRanges] = useState({});

  useEffect(() => {
    if (!isTokenRefreshing && auctionId) {
      NetworkUtil.get(`/apis/bids/${auctionId}/price-ranges`, {}, data => {
        setPriceRanges(data);
      }, errorData => {
        Logger.error(errorData);
      });
    }
  }, [isTokenRefreshing, auctionId]);

  return (
    <>
      <Segment style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Header as={'h3'} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          {/*<Icon link name={'star outline'} style={{fontWeight: 'bold'}} color={'red'}*/}
          {/*      onClick={() => Logger.debug('star')} />*/}
          <Header.Content>
            {_.get(carInformationData, 'cardata.regname')}
            <Header as={'h5'} style={{margin: 0}}>{_.get(carInformationData, 'cardata.seriesname')}</Header>
          </Header.Content>
        </Header>
      </Segment>

      <Segment>
        <Header>기본정보</Header>
        <List selection verticalAlign="middle">
          <List.Item>
            <List.Content
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <List.Header>제조사명</List.Header>
              <List.Description>{_.get(carInformationData, 'cardata.makername')}</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <List.Header>차량번호</List.Header>
              <List.Description>{_.get(carInformationData, 'cardata.carnum')}</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <List.Header>차대번호</List.Header>
              <List.Description>{_.get(carInformationData, 'cardata.vin')}</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <List.Header>최초등록일</List.Header>
              <List.Description>{_.get(carInformationData, 'cardata.firstdate')}</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <List.Header>연식</List.Header>
              <List.Description>{_.get(carInformationData, 'cardata.year')}년형</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <List.Header>주행거리</List.Header>
              <List.Description>{CommonUtil.Unit.format(mileageData)} KM</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <List.Header>연료</List.Header>
              <List.Description>{_.get(carInformationData, 'cardata.fuel')}</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <List.Header>변속기</List.Header>
              <List.Description>{_.get(carInformationData, 'cardata.gearbox')}</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <List.Header>색상</List.Header>
              <List.Description>{_.get(carInformationData, 'cardata.color')}</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <List.Header>신차가격</List.Header>
              <List.Description>{CommonUtil.Unit.format(_.get(carInformationData, 'cardata.newprice'))}원</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <List.Header>출고가격</List.Header>
              <List.Description>{CommonUtil.Unit.format(_.get(carInformationData, 'cardata.carmakeprice'))}원</List.Description>
            </List.Content>
          </List.Item>
        </List>
      </Segment>

      <Segment>
        <Header>차량옵션</Header>
        <List selection verticalAlign="middle">
          {_.map(optList, (opt, idx) => (
            <List.Item key={`OPT_${idx}`}>
              <Icon
                name={'circle check'}
                style={{color: BASE_STYLES.FONT_COLOR}}
              />
              <List.Content>
                <List.Description
                  style={{color: BASE_STYLES.FONT_COLOR}}>{opt.name}</List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Segment>

      <Segment>
        <Header>
          전체옵션
          <Header.Content>
            <Popup
              size={'tiny'}
              on="click"
              position="left center"
              content={
                <Segment basic inverted>
                  <Header>추가 옵션정보</Header>
                  <pre style={{
                    width: 500,
                    maxHeight: 300,
                    overflowY: 'auto',
                    whiteSpace: 'pre-wrap',
                    fontSize: BASE_STYLES.FONT_SIZE.MIN,
                  }}>{_.get(carInformationData, 'cardata.option_memo')}</pre>
                </Segment>
              }
              trigger={<Icon name={'question circle'} color={'yellow'} link />} />
          </Header.Content>
        </Header>
        <List selection verticalAlign="middle">
          {_.map(selOptList, (selOpt, idx) => (
            <List.Item key={`SEL_OPT_${idx}`}>
              <Icon
                name={'circle outline'}
                style={{color: BASE_STYLES.FONT_COLOR}}
              />
              <List.Content>
                <List.Description
                  style={{color: BASE_STYLES.FONT_COLOR}}>{selOpt.optname}</List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Segment>

      {!_.isEmpty(priceRanges) && (<Segment>
        <Header>시세정보</Header>
        {_.map(priceRanges.RESULT.CARLIST, (priceRange, idx) => (
          <React.Fragment key={`PRICE_TBL_${idx}`}>
            <Table unstackable definition compact fixed
                   {...((priceRange.PRYE === _.get(carInformationData, 'cardata.year')) && {
                     color: THEME,
                     inverted: true,
                   })}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={4} textAlign={'center'}>연식</Table.Cell>
                  <Table.Cell textAlign={'right'}>{priceRange.PRYE}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign={'center'}>매도</Table.Cell>
                  <Table.Cell textAlign={'right'}>
                    {priceRange.REGIST_CO_SELL}건<br />
                    {priceRange.AVRG_ACQS_AMOUNT_SELL}원
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign={'center'}>매수</Table.Cell>
                  <Table.Cell textAlign={'right'}>
                    {priceRange.REGIST_CO_BUY}건<br />
                    {priceRange.AVRG_ACQS_AMOUNT_BUY}원
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            {idx < priceRanges.RESULT.CARLIST.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Segment>)}
    </>
  );
}

export default React.memo(MemoizedCarInformation);
