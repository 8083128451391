import axios from 'axios';
import queryString from 'query-string';
import {Logger} from './Logger';
import {ENVIRONMENTS} from '../consts/consts';

/*const request = function (options) {
  const onSuccess = function (response) {
    Logger.debug('Request Successful!', response);
    return response.data;
  }

  const onError = function (error) {
    Logger.error('Request Failed:', error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      Logger.error('Status:', error.response.status);
      Logger.error('Data:', error.response.data);
      Logger.error('Headers:', error.response.headers);

    } else {
      // Something else happened while setting up the request
      // triggered the error
      Logger.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  }

  return axios(options)
    .then(onSuccess)
    .catch(onError);
}*/

export const NetworkUtil = {
  isNetworkError(error) {
    Logger.debug('[isNetworkError]', error, error.request, error.isAxiosError, error.response);
    return !!error.isAxiosError && !error.response;
  },

  get(uri, body, onSuccess, onError, onFinally) {
    let params = queryString.stringify(body);
    Logger.debug(`[GET] ${uri} | ${params}`);

    axios.get(`${uri}?${params}`)
      .then(({status, data}) => {
        if (onSuccess) {
          Logger.debug(`[GET] ${status} | ${ENVIRONMENTS.PRINT_DATA_TO_LOGGER ? JSON.stringify(data) : data.length}`);
          onSuccess(data);
        } else {
          Logger.debug(`[GET] ${ENVIRONMENTS.PRINT_DATA_TO_LOGGER ? JSON.stringify(data) : data.length}`);
        }
      })
      .catch(error => {
        if (NetworkUtil.isNetworkError(error)) {
          Logger.error('Network Error');
          throw error;
        } else {
          const {response: {data: errorData}} = error;
          onError && onError(errorData);
        }
      })
      .finally(() => {
        onFinally && onFinally();
      });
  },

  post(uri, body, onSuccess, onError, onFinally) {
    Logger.debug(`[POST] ${uri} | ${JSON.stringify(body)}`);

    axios.post(uri, body)
      .then(({status, data}) => {
        if (onSuccess) {
          Logger.debug(`[POST] ${status} | ${ENVIRONMENTS.PRINT_DATA_TO_LOGGER ? JSON.stringify(data) : data.length}`);
          onSuccess(data);
        } else {
          Logger.debug(`[POST] ${ENVIRONMENTS.PRINT_DATA_TO_LOGGER ? JSON.stringify(data) : data.length}`);
        }
      })
      .catch(error => {
        if (NetworkUtil.isNetworkError(error)) {
          Logger.error('Network Error');
          throw error;
        } else {
          const {response: {data: errorData}} = error;
          onError && onError(errorData);
        }
      })
      .finally(() => {
        onFinally && onFinally();
      });
  },

  put(uri, body, onSuccess, onError, onFinally) {
    Logger.debug(`[PUT] ${uri} | ${JSON.stringify(body)}`);

    axios.put(uri, body)
      .then(({status, data}) => {
        if (onSuccess) {
          Logger.debug(`[PUT] ${status} | ${ENVIRONMENTS.PRINT_DATA_TO_LOGGER ? JSON.stringify(data) : data.length}`);
          onSuccess(data);
        } else {
          Logger.debug(`[PUT] ${ENVIRONMENTS.PRINT_DATA_TO_LOGGER ? JSON.stringify(data) : data.length}`);
        }
      })
      .catch(error => {
        if (NetworkUtil.isNetworkError(error)) {
          Logger.error('Network Error');
          throw error;
        } else {
          const {response: {data: errorData}} = error;
          onError && onError(errorData);
        }
      })
      .finally(() => {
        onFinally && onFinally();
      });
  },

  delete(uri, body, onSuccess, onError, onFinally) {
    Logger.debug(`[DELETE] ${uri} | ${JSON.stringify(body)}`);

    axios.delete(uri, body)
      .then(({status, data}) => {
        if (onSuccess) {
          Logger.debug(`[DELETE] ${status} | ${ENVIRONMENTS.PRINT_DATA_TO_LOGGER ? JSON.stringify(data) : data.length}`);
          onSuccess(data);
        } else {
          Logger.debug(`[DELETE] ${ENVIRONMENTS.PRINT_DATA_TO_LOGGER ? JSON.stringify(data) : data.length}`);
        }
      })
      .catch(error => {
        if (NetworkUtil.isNetworkError(error)) {
          Logger.error('Network Error');
          throw error;
        } else {
          const {response: {data: errorData}} = error;
          onError && onError(errorData);
        }
      })
      .finally(() => {
        onFinally && onFinally();
      });
  },

  async getAsync(uri, body, loadingState) {
    loadingState && loadingState(true);

    let params = queryString.stringify(body);
    Logger.debug(`[GET ASYNC] ${uri} | ${params}`, body);

    try {
      const {status, data} = await axios.get(`${uri}?${params}`);
      Logger.debug(`[GET ASYNC] ${status} | ${ENVIRONMENTS.PRINT_DATA_TO_LOGGER ? JSON.stringify(data) : data.length}`);
      return data;
    } catch (error) {
      if (NetworkUtil.isNetworkError(error)) {
        Logger.error('Network Error');
        throw error;
      } else {
        throw error?.response?.data;
      }
    } finally {
      loadingState && loadingState(false);
    }
  },

  async postAsync(uri, body, loadingState) {
    loadingState && loadingState(true);
    Logger.debug(`[POST ASYNC] ${uri} | ${JSON.stringify(body)}`);

    try {
      const {status, data} = await axios.post(`${uri}`, body);
      Logger.debug(`[POST ASYNC] ${status} | ${ENVIRONMENTS.PRINT_DATA_TO_LOGGER ? JSON.stringify(data) : data.length}`);
      return data;
    } catch (error) {
      if (NetworkUtil.isNetworkError(error)) {
        Logger.error('Network Error');
        throw error;
      } else {
        throw error?.response?.data;
      }
    } finally {
      loadingState && loadingState(false);
    }
  },
};


