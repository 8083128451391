import {Divider, Header, Segment} from 'semantic-ui-react';
import {BASE_STYLES, CARMON_INFO} from './consts/consts';

import Logo from './images/logos/carmon.png';
import {Image} from 'react-image-and-background-image-fade';

import './AppMobile.css';

export default function AppMobile() {
  return (
    <div className={'mobile-wrapper'}>
      <header style={{
        height: 80,
        display: 'flex',
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '4px 20px 0',
      }}>
        <Image src={Logo} style={{width: 160}} />
      </header>
      <section style={{
        flex: 1,
        alignSelf: 'stretch',
        padding: 14,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
        <div>
          <Header className={'mobile-sub-title'} textAlign={'right'}>카몬 딜러<sub> on 모바일</sub></Header>
          <Header className={'mobile-title'}>COMING</Header>
          <Header className={'mobile-title'} style={{textAlign: 'right'}}>SOON</Header>
        </div>
        <div style={{marginTop: '3em'}}>
          <Header className="ads" style={{fontSize: '3em', color: BASE_STYLES.THEME_ITEM_COLOR}}>
            카몬은 다릅니다!
            <Header.Subheader className="ads" style={{fontSize: '1.4rem', marginTop: 30, textAlign: 'right'}}>
              하나. 전 차량 국가 공인 정비소의<br /><span className={'ads'}
                                             style={{
                                               color: BASE_STYLES.THEME_ITEM_COLOR,
                                               fontWeight: 'bold',
                                             }}>성능점검 완료!</span><br />
              둘. <span className={'ads'}
                       style={{color: BASE_STYLES.THEME_ITEM_COLOR, fontWeight: 'bold'}}>단! 하루면</span> 판매 여부 확인
              가능!<br />
              셋. <span className={'ads'}
                       style={{color: BASE_STYLES.THEME_ITEM_COLOR, fontWeight: 'bold'}}>탁송도</span> 카몬에 맡기세요!<br />
            </Header.Subheader>
          </Header>
          <Header className={'mobile-content'}
                  style={{textAlign: 'right', color: BASE_STYLES.THEME_ITEM_COLOR}}>
            현재 카몬 딜러 시스템은<br />PC에서만 사용 가능합니다.
          </Header>
        </div>
      </section>
      <footer style={{display: 'flex', alignSelf: 'stretch'}}>
        <Segment basic inverted style={{padding: '6px 16px'}}>
          <Header as={'h3'} style={{margin: 0}}>중고차? 다 카몬이랑 해! | 카몬</Header>
          <hr style={{margin: '6px 0'}} />
          <Header style={{margin: 0, fontSize: 14}}>
            대표자 : {CARMON_INFO.REPRESENTATIVE} | 개인정보관리책임자 : {CARMON_INFO.PERSONAL_INFO}<br />
            사업자등록번호 : {CARMON_INFO.BUSINESS_NUMBER}<br />
            통신판매업신고 : {CARMON_INFO.SELLER_NUMBER}<br />
            사업장소재지 : ({CARMON_INFO.POSTAL_CODE}) {CARMON_INFO.ADDRESS}<br />
            제휴문의 : <a href={`mailto:${CARMON_INFO.CONTACT_EMAIL}`} style={{
            color: BASE_STYLES.THEME_ITEM_COLOR,
            textDecoration: 'none',
          }}>{CARMON_INFO.CONTACT_EMAIL}</a><br /><br />
            Copyright © 카몬 All Rights Reserved.
          </Header>
        </Segment>
      </footer>
    </div>
  );
}