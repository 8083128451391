import React, {useRef, useState} from 'react';
import {Button, Container, Form, Grid, Header, Icon, Input, Message, Ref, Segment} from 'semantic-ui-react';
import {NetworkUtil} from '../../utils/NetworkUtil';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {THEME} from '../../consts/consts';

function PasswordView({match, location, history}) {
    const [info, setInfo] = useState({
        loginId: '',
        contactNumber: '',
        employeeNumber: '',
    });
    const [publishResults, setPublishResults] = useState({
        published: null,
        message: '',
    });
    const [validationResult, setValidationResult] = useState(null);
    const [isProceeding, setIsProceeding] = useState(false);

    const nameRef = useRef(null);
    const contactNumberRef = useRef(null);
    const employeeNumberRef = useRef(null);

    const findLoginId = async () => {
        setPublishResults({
            published: null,
            message: '',
        });

        const validationResult = validateForm();
        if (!_.every(validationResult, i => (i === true))) return false;

        setIsProceeding(true);

        NetworkUtil.post('/apis/dealer/finder/password', info, () => {
            setPublishResults(prevState => ({...prevState, published: true}));
            setInfo({
                loginId: '',
                contactNumber: '',
                employeeNumber: ''
            });
        }, errorData => {
            setPublishResults(prevState => ({...prevState, published: false, message: errorData.message}));
        }, () => {
            setIsProceeding(false);
        });
    };

    const validateForm = () => {
        const result = _.reduce(info, (res, value, key) => {
            res[key] = !_.isEmpty(value);
            return res;
        }, {});

        setValidationResult(result);
        return result;
    };

    return (
        <Container fluid className={'carmon-view-container'}
                   style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{maxWidth: 550}}>
                <Segment attached="top" color={THEME}>
                    <Button content="뒤로가기" icon="arrow circle left" labelPosition="left" as={Link} to={'/'} replace/>
                </Segment>
                <Segment attached>
                    <Header textAlign={'center'}>
                        비밀번호 찾기
                        <Header.Subheader>
                            입력하신 정보로 임시 비밀번호를 발행해 드립니다.
                        </Header.Subheader>
                    </Header>
                    <Form loading={isProceeding}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Ref innerRef={nameRef}>
                                        <Form.Field
                                            control={Input}
                                            label="로그인 아이디"
                                            icon={'user'}
                                            iconPosition={'left'}
                                            placeholder="로그인 아이디"
                                            value={info.loginId}
                                            onChange={(e, {value}) => setInfo(prevState => ({
                                                ...prevState,
                                                loginId: value
                                            }))}
                                            onKeyUp={e => (e.key === 'Enter' && contactNumberRef.current.querySelector('input').focus())}
                                            error={validationResult?.loginId === false && {
                                                content: '로그인 아이디를 입력해주세요.',
                                                pointing: 'above',
                                            }}/>
                                    </Ref>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Ref innerRef={contactNumberRef}>
                                        <Form.Field
                                            control={Input}
                                            label="전화번호"
                                            icon={'mobile alternate'}
                                            iconPosition={'left'}
                                            placeholder="숫자만 입력해 주세요."
                                            value={info.contactNumber}
                                            onChange={(e, {value}) => {
                                                const refinedValue = _.replace(value, /[^0-9.]/g, '');
                                                setInfo(prevState => ({...prevState, contactNumber: refinedValue}));
                                            }}
                                            onKeyUp={e => (e.key === 'Enter' && employeeNumberRef.current.querySelector('input').focus())}
                                            error={validationResult?.contactNumber === false && {
                                                content: '전화번호를 입력해주세요.',
                                                pointing: 'above',
                                            }}/>
                                    </Ref>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Ref innerRef={employeeNumberRef}>
                                        <Form.Field
                                            control={Input}
                                            label="종사원번호"
                                            icon={'id card outline'}
                                            iconPosition={'left'}
                                            placeholder="종사원번호"
                                            value={info.employeeNumber}
                                            onChange={(e, {value}) => setInfo(prevState => ({
                                                ...prevState,
                                                employeeNumber: value
                                            }))}
                                            error={validationResult?.employeeNumber === false && {
                                                content: '종사원번호를 입력해주세요.',
                                                pointing: 'above',
                                            }}/>
                                    </Ref>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Button type={'button'} content="비밀번호 초기화 하기" icon={'magic'} fluid primary
                                            onClick={findLoginId}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Segment>
                <Message size={'large'} attached={'bottom'}>
                    <Message.Content>
                        1. 비밀번호는 암호화 되어 있어, 초기화만 가능합니다.<br/>
                        2. 입력하신 정보를 확인한 후, 메시지로 임시 비밀번호를 보내드립니다.<br/>
                        3. 임시 비밀번호로 로그인 하신 후, 반드시 [내 정보]에서 비밀번호를 변경하시기 바랍니다.
                    </Message.Content>
                </Message>

                {(publishResults.published !== null && publishResults.published !== true) && (
                    <Message size={'huge'} warning>
                        <Icon name="help"/>
                        {publishResults.message}
                    </Message>
                )}
                {(publishResults.published !== null && publishResults.published === true) && (
                    <>
                        <Message size={'huge'} success>
                            <Icon name="exclamation"/>
                            딜러님의 임시 패스워드가 발행되었습니다. 메시지를 확인해 주세요.
                        </Message>
                        <Button fluid as={Link} to={'/'}>
                            <Icon name={'arrow right'} style={{margin: '0em 0.42857143em 0em -0.21428571em'}}/>로그인 화면으로
                            가기
                        </Button>
                    </>
                )}
            </div>
        </Container>
    );
}

export default PasswordView;
