import React, {useEffect, useState} from 'react';
import {
  Button, Form, Grid, Header, Image,
  Message, Segment, Label, Icon, Modal,
  ButtonContent, Divider, Container, Menu, Dropdown,
  List, Placeholder, Statistic,
} from 'semantic-ui-react';
import {BASE_STYLES, THEME} from '../../consts/consts';
import {NetworkUtil} from '../../utils/NetworkUtil';
import {useRecoilValue} from 'recoil';
import {tokenRefreshingAtom} from '../../recoil/atoms';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Line, Bar} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map((value, idx) => (10 + value.length) * idx),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: labels.map((value, idx) => (150 - value.length) / idx),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export default function Dashboard() {
  const isTokenRefreshing = useRecoilValue(tokenRefreshingAtom);
  const [dealer, setDealer] = useState({});

  useEffect(() => {
    if (!isTokenRefreshing) NetworkUtil.get(`/apis/dealer`, {}, data => setDealer(data));
  }, [isTokenRefreshing, setDealer]);

  return (
    <Container className={'carmon-view-container'}>
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Segment color={THEME} style={{fontSize: BASE_STYLES.FONT_SIZE.HEADER.SUB, fontWeight: 'bold'}}>
                <Icon name={'user circle'} />{dealer.name} 딜러님, 환영합니다!
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4} stretched>
            <Grid.Column>
              <Segment color={THEME}>
                <Header as={'h3'} dividing>전체차량</Header>
                <div style={{textAlign: 'center'}}>
                  <Statistic label="대" value={'0'} size={'large'} />
                </div>
              </Segment>
            </Grid.Column>
            <Grid.Column width={12}>
              <Segment color={THEME}>
                <Header as={'h3'} dividing>내 경매</Header>
                <Statistic.Group widths={4} size={'large'}>
                  <Statistic>
                    <Statistic.Value>0</Statistic.Value>
                    <Statistic.Label>경매진행중</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>0</Statistic.Value>
                    <Statistic.Label>경매완료</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>0</Statistic.Value>
                    <Statistic.Label>입금대기</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>0</Statistic.Value>
                    <Statistic.Label>구매완료</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2} stretched>
            <Grid.Column>
              <Segment color={THEME}>
                <Header as={'h3'} dividing>내 경매</Header>
                <Line options={options} data={data} type={{}} />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment color={THEME}>
                <Header as={'h3'} dividing>내 경매</Header>
                <Bar options={options} data={data} type={{}} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Container>
  );
}
