import React, {useEffect, useRef, useState} from 'react';
import {Button, Icon, Modal} from 'semantic-ui-react';
import Contract from '../Print/Contract';
import {useParams} from 'react-router-dom';
import {BASE_STYLES} from '../../consts/consts';
import {CommonUtil} from '../../utils/CommonUtil';
import {FormatOptions, numToKorean} from 'num-to-korean';
import _ from 'lodash';
import {NetworkUtil} from '../../utils/NetworkUtil';

export default function MyBidContract({match, location, history, bidData}) {
  const {auctionId} = useParams();
  const printRef = useRef(null);
  const [isConfirmOpened, setIsConfirmedOpened] = useState(false);

  useEffect(() => {
    document.querySelector('#carmon-wrapper').style.paddingBottom = 0;
  }, []);

  return (
    <>
      <div style={{
        width: '100%',
        backgroundColor: '#000000dd',
        paddingTop: 50,
      }}>
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          textAlign: 'right',
          padding: '6px 6px',
          backgroundColor: '#ffffff',
          zIndex: 2,
          borderBottom: '1px solid',
        }}>
          <Button primary size={'tiny'} onClick={() => setIsConfirmedOpened(true)}>
            <Icon name="print" />
            계약서 출력
          </Button>

          <Button size={'tiny'} onClick={() => window.close()}>
            <Icon name="remove" />
            닫기
          </Button>
        </div>

        <Contract procPrint={printRef} />
      </div>

      <Modal
        dimmer={{
          style: {border: 0, margin: 0, borderRadius: 0},
        }}
        size={'tiny'}
        centered={false}
        open={isConfirmOpened}
        onClose={() => setIsConfirmedOpened(false)}
        onOpen={() => setIsConfirmedOpened(true)}>
        <Modal.Header>계약서를 출력합니다.</Modal.Header>
        <Modal.Content>
          <Modal.Description style={{fontSize: BASE_STYLES.FONT_SIZE.BASE}}>
            계약서를 출력하면, 탁송진행상태가 <b style={{fontSize: BASE_STYLES.FONT_SIZE.BASE}}>[탁송완료]</b>로 변경됩니다.<br />계약서를 출력하시겠습니까?
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => {
            printRef !== null && printRef.current();
            setIsConfirmedOpened(false);
          }}>
            <Icon name={'print'} />
            출력하기
          </Button>
          <Button onClick={() => setIsConfirmedOpened(false)}>취소</Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
