import React, {useRef, useState} from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
  Label,
  Icon,
  Modal,
  ButtonContent, Divider, Container, Menu, Dropdown, List, Placeholder, Input, Checkbox, GridColumn, Ref,
} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import Sidebar from '../../components/register/Sidebar';
import {THEME} from '../../consts/consts';
import {Logger} from '../../utils/Logger';
import {NetworkUtil} from '../../utils/NetworkUtil';
import _ from 'lodash';
import {CommonUtil} from '../../utils/CommonUtil';
import {SERVICE_AGREEMENT} from '../../consts/ServiceAgreement';
import {PERSONAL_INFORMATION} from '../../consts/PersonalInformation';

export default function AgreementView({match, location, history}) {
  const loginIdRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);
  const contactNumberRef = useRef(null);

  const [registrationInfo, setRegistrationInfo] = useState({
    loginId: '',
    password: '',
    passwordConfirm: '',
    contactNumber: '',
  });
  const [agreements, setAgreements] = useState({
    service: false,
    personal: false,
  });

  const [isServiceAgreementOpened, setIsServiceAgreementOpened] = useState(false);
  const [isPersonalInformationOpened, setIsPersonalInformationOpened] = useState(false);

  const [isUniqueLoginId, setIsUniqueLoginId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [certificationCode, setCertificationCode] = useState(null);

  const [isValidPassword, setIsValidPassword] = useState(null);

  const [inProceeding, setInProceeding] = useState(false);
  const [isCertified, setIsCertified] = useState(null);

  const _eventHandler = {
    async checkAvailableLoginId() {
      const isAvailable = await NetworkUtil.getAsync(`/apis/dealer/checker/login-id/${registrationInfo.loginId}`, {});
      setIsUniqueLoginId(isAvailable);

      if (isAvailable === false) loginIdRef.current.querySelector('input').select();
      else passwordRef.current.querySelector('input').focus();
    },
    openContactNumberChecker() {
      setIsDialogOpen(true);
    },
    async sendContactNumberCheckAT() {
      const certificationCode = await NetworkUtil.postAsync('/apis/dealer/checker/contact', {
        loginId: registrationInfo.loginId,
        contactNumber: registrationInfo.contactNumber,
      });

      setCertificationCode(certificationCode);
    },
    async proceed() {
      setIsCertified(null);

      const result = await NetworkUtil.postAsync('/apis/dealer/checker/certification', {
        loginId: registrationInfo.loginId,
        contactNumber: registrationInfo.contactNumber,
        certificationCode,
      }, setInProceeding);

      setIsCertified(result);
      return result;
    },
  };

  return (
    <Container fluid className={'carmon-view-container'}
               style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Grid>
        <Grid.Row>
          <Grid.Column style={{maxWidth: 660}}>
            <Segment attached="top" color={THEME}>
              <Button content="뒤로가기" icon="arrow circle left" labelPosition="left" as={Link} to={'/'} replace />
            </Segment>
            <Segment attached>
              <Header textAlign={'center'}>
                카몬 딜러 가입
                <Header.Subheader>중고차? 다 카몬이랑 해!</Header.Subheader>
              </Header>
              <Form loading={inProceeding}>
                <Grid textAlign="left">
                  <Grid.Row>
                    <Grid.Column>
                      <Ref innerRef={loginIdRef}>
                        <Form.Field
                          control={Input}
                          label="아이디"
                          placeholder="아이디"
                          value={registrationInfo.loginId}
                          onChange={(e, {value}) => {
                            const refinedValue = _.toLower(_.replace(value, /\s/g, ''));
                            setRegistrationInfo({...registrationInfo, loginId: refinedValue});
                            setIsUniqueLoginId(null);
                          }}
                          onKeyUp={async e => (e.key === 'Enter' && isUniqueLoginId === null && await _eventHandler.checkAvailableLoginId())}
                          action={{
                            type: 'button',
                            color: THEME,
                            labelPosition: 'right',
                            icon: isUniqueLoginId ? 'check' : 'question',
                            content: '중복확인',
                            disabled: (_.isEmpty(registrationInfo.loginId) || isUniqueLoginId === true),
                            onClick: async () => await _eventHandler.checkAvailableLoginId(),
                          }}
                          error={(!_.isEmpty(registrationInfo.loginId) && isUniqueLoginId === false) && {
                            content: '이미 등록된 아이디가 있습니다.',
                            pointing: 'below',
                          }}
                        />
                      </Ref>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Ref innerRef={passwordRef}>
                        <Form.Field
                          control={Input}
                          type={'password'}
                          label="비밀번호"
                          placeholder="비밀번호 (영문, 숫자, 특수문자 조합, 공백 제외, 최소 8자)"
                          value={registrationInfo.password}
                          onChange={(e, {value}) => {
                            const refinedValue = _.replace(value, /\s/g, '');
                            setRegistrationInfo({...registrationInfo, password: refinedValue});
                          }}
                          onKeyUp={e => {
                            if (e.key === 'Enter') passwordConfirmRef.current.querySelector('input').focus();
                            else {
                              setIsValidPassword(CommonUtil.Validator.validatePassword(registrationInfo.password));
                            }
                          }}
                          error={(isValidPassword === false) && {
                            content: '비밀번호를 최소 8자의 공백을 제외한 영문, 숫자, 특수문자 조합으로 만들어주세요.',
                            pointing: 'below',
                          }}
                        />
                      </Ref>
                      <Ref innerRef={passwordConfirmRef}>
                        <Form.Field
                          control={Input}
                          type={'password'}
                          placeholder="비밀번호 확인"
                          value={registrationInfo.passwordConfirm}
                          onChange={(e, {value}) => {
                            const refinedValue = _.replace(value, /\s/g, '');
                            setRegistrationInfo({...registrationInfo, passwordConfirm: refinedValue});
                          }}
                          error={(registrationInfo.password !== registrationInfo.passwordConfirm) && {
                            content: '비밀번호와 다릅니다.',
                            pointing: 'below',
                          }}
                          onKeyUp={e => (e.key === 'Enter' && contactNumberRef.current.querySelector('input').focus())}
                        />
                      </Ref>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Ref innerRef={contactNumberRef}>
                        <Form.Field
                          control={Input}
                          placeholder="휴대전화번호 (숫자만)"
                          value={registrationInfo.contactNumber}
                          maxLength={13}
                          action={{
                            type: 'button',
                            color: THEME,
                            content: '인증메시지받기',
                            disabled: _.isEmpty(registrationInfo.contactNumber) || (certificationCode !== '' && certificationCode !== null),
                            onClick: () => _eventHandler.openContactNumberChecker(),
                          }}
                          onKeyUp={e => {
                            if (e.key === 'Enter' && certificationCode === null) {
                              e.target.blur();
                              _eventHandler.openContactNumberChecker();
                            }
                          }}
                          onChange={(e, {value}) => {
                            const refined = CommonUtil.CellPhone.format(value);
                            setRegistrationInfo({...registrationInfo, contactNumber: refined});
                            setCertificationCode(null);
                          }}
                          error={(!_.isEmpty(registrationInfo.contactNumber) && certificationCode === '') && {
                            content: !CommonUtil.Validator.validateCellPhone(registrationInfo.contactNumber) ? '올바른 휴대전화번호를 입력하세요.' : '이미 등록된 휴대전화번호입니다.',
                            pointing: 'below',
                          }}
                        />
                      </Ref>
                      <Modal
                        basic
                        open={isDialogOpen}
                        size={'tiny'}>
                        <Header>
                          <Icon name={'exclamation circle'} /> 휴대전화번호 확인!
                        </Header>
                        <Modal.Content>
                          휴대전화번호 확인을 위해 입력하신 번호 <b>[{registrationInfo.contactNumber}]</b> 로 알림톡이 발송됩니다.<br />
                          잘못 입력하신 경우, 알림톡을 받으실 수 없습니다.
                        </Modal.Content>
                        <Modal.Actions>
                          <Button basic color="red" inverted onClick={() => setIsDialogOpen(false)}>
                            <Icon name="remove" /> 다시입력
                          </Button>
                          <Button primary inverted onClick={async () => {
                            await _eventHandler.sendContactNumberCheckAT();
                            setIsDialogOpen(false);
                          }}>
                            <Icon name="checkmark" /> 인증메시지받기
                          </Button>
                        </Modal.Actions>
                      </Modal>
                      <Message
                        hidden={certificationCode !== true}
                        color={'orange'}
                        header="알림톡 메시지를 먼저 확인해주세요!"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Form.Field>
                        <Checkbox label={'약관 전체동의'} checked={agreements.service && agreements.personal}
                                  onClick={(e) => setAgreements(prevState => ({
                                    service: !prevState.service,
                                    personal: !prevState.personal,
                                  }))} />
                      </Form.Field>
                      <Form.Field style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                        <Checkbox label={'카몬 서비스 이용약관 동의(필수)'} checked={agreements.service}
                                  onClick={(e, {value}) => setAgreements({
                                    ...agreements,
                                    service: !agreements.service,
                                  })} />
                        <a href="" role={'button'} onClick={(e) => {
                          e.preventDefault();
                          setIsServiceAgreementOpened(true);
                        }}>약관보기</a>
                      </Form.Field>
                      <Form.Field style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                        <Checkbox label={'개인정보 수집 및 이용 동의(필수)'} checked={agreements.personal}
                                  onClick={(e, {value}) => setAgreements({
                                    ...agreements,
                                    personal: !agreements.personal,
                                  })} />
                        <a href="" role={'button'} onClick={(e) => {
                          e.preventDefault();
                          setIsPersonalInformationOpened(true);
                        }}>약관보기</a>
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        fluid
                        primary
                        content={'본인인증하고 가입정보 입력하기'}
                        loading={inProceeding}
                        disabled={!(
                          isUniqueLoginId === true
                          && (registrationInfo.password === registrationInfo.passwordConfirm && (certificationCode !== '' && certificationCode !== null))
                          && (agreements.service && agreements.personal)
                        ) || inProceeding}
                        onClick={async (e) => {
                          Logger.debug(registrationInfo, agreements, isUniqueLoginId, certificationCode);
                          const isCertified = await _eventHandler.proceed();

                          if (isCertified) {
                            history.push('/register/step2', {
                              loginId: registrationInfo.loginId,
                              password: registrationInfo.password,
                              contactNumber: registrationInfo.contactNumber,
                              certificationCode,
                            });
                          } else {
                            Logger.debug('failed');
                            // setIsCertified(null);
                          }
                        }} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Segment>
            <Message color={'red'} attached="bottom" hidden={isCertified !== false}>
              <Message.Header>
                휴대전화로 전송된 인증메시지를 먼저 확인해주세요!
              </Message.Header>
            </Message>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Modal
        open={isServiceAgreementOpened}
        onClose={() => setIsServiceAgreementOpened(false)}
        onOpen={() => setIsServiceAgreementOpened(true)}>
        <Modal.Header
          style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          카몬 서비스 이용약관
          <Button size={'small'} basic icon={'remove'} onClick={() => setIsServiceAgreementOpened(false)} />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <iframe
              title={'서비스 이용약관'}
              srcDoc={SERVICE_AGREEMENT}
              style={{border: 0, width: '100%', minHeight: 300, height: '60vh', maxHeight: 800}} />
          </Modal.Description>
        </Modal.Content>
      </Modal>

      <Modal
        open={isPersonalInformationOpened}
        onClose={() => setIsPersonalInformationOpened(false)}
        onOpen={() => setIsPersonalInformationOpened(true)}>
        <Modal.Header
          style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          개인정보 수집 및 이용약관
          <Button size={'small'} basic icon={'remove'} onClick={() => setIsPersonalInformationOpened(false)} />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <iframe
              title={'개인정보 취급방침'}
              srcDoc={PERSONAL_INFORMATION}
              style={{border: 0, width: '100%', minHeight: 300, height: '60vh', maxHeight: 800}} />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </Container>
  );
}
