import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useReactToPrint} from 'react-to-print';
import ContractDoc from './ContractDoc';
import {NetworkUtil} from '../../utils/NetworkUtil';
import {useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {tokenRefreshingAtom} from '../../recoil/atoms';
import moment from 'moment';
import {Logger} from '../../utils/Logger';

export default function Contract({procPrint}) {
  const isTokenRefreshing = useRecoilValue(tokenRefreshingAtom);
  const {auctionId} = useParams();
  const contractDocRef = useRef();

  const [contractData, setContractData] = useState({});

  const handlePrint = useReactToPrint({
    documentTitle: `카몬_계약서_${contractData.carId}_${moment().format('YYYYMMDD')}`,
    content: () => contractDocRef.current,
    onAfterPrint: () => {
      NetworkUtil.post(`/apis/my-bids/contract/${auctionId}/print-count`, {},
        printCount => Logger.info(`Print Count : ${printCount}`));
    },
  });

  const getContract = useCallback(() => {
    NetworkUtil.get(`/apis/my-bids/contract/${auctionId}`, {},
      data => setContractData(data),
      errorData => {
      },
      () => {
      });
  }, [auctionId]);

  useEffect(() => {
    if (!isTokenRefreshing) getContract();
  }, [getContract, isTokenRefreshing]);

  useEffect(() => {
    procPrint.current = handlePrint;
  }, [handlePrint, procPrint]);

  return (
    <div style={{textAlign: 'center'}}>
      <ContractDoc ref={contractDocRef} contractData={contractData} />
    </div>
  );
}
