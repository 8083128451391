import React, {useRef, useState} from 'react';
import {Button, Container, Form, Grid, Header, Icon, Input, Message, Ref, Segment} from 'semantic-ui-react';
import {NetworkUtil} from '../../utils/NetworkUtil';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {THEME} from '../../consts/consts';

function LoginIdView({match, location, history}) {
  const [info, setInfo] = useState({
    name: '',
    contactNumber: '',
    employeeNumber: '',
  });
  const [findResults, setFindResults] = useState({
    found: null,
    loginId: null,
    message: '',
  });
  const [validationResult, setValidationResult] = useState(null);
  const [isProceeding, setIsProceeding] = useState(false);

  const nameRef = useRef(null);
  const contactNumberRef = useRef(null);
  const employeeNumberRef = useRef(null);

  const findLoginId = async () => {
    setFindResults({
      found: null,
      loginId: null,
      message: '',
    });

    const validationResult = validateForm();
    if (!_.every(validationResult, i => (i === true))) return false;

    setIsProceeding(true);

    NetworkUtil.post('/apis/dealer/finder/login-id', info, data => {
      setFindResults(prevState => ({...prevState, found: true, loginId: data}));
    }, errorData => {
      setFindResults(prevState => ({...prevState, found: false, message: errorData.message}));
    }, () => {
      setIsProceeding(false);
    });
  };

  const validateForm = () => {
    const result = _.reduce(info, (res, value, key) => {
      res[key] = !_.isEmpty(value);
      return res;
    }, {});

    setValidationResult(result);
    return result;
  };

  return (
    <Container fluid className={'carmon-view-container'}
               style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <div style={{maxWidth: 550}}>
        <Segment attached="top" color={THEME}>
          <Button content="뒤로가기" icon="arrow circle left" labelPosition="left" as={Link} to={'/'} replace />
        </Segment>
        <Segment attached>
          <Header textAlign={'center'}>
            아이디 찾기
            <Header.Subheader>
              입력하신 정보로 아이디를 찾습니다.
            </Header.Subheader>
          </Header>
          <Form loading={isProceeding}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Ref innerRef={nameRef}>
                    <Form.Field
                      control={Input}
                      label="이름"
                      icon={'user'}
                      iconPosition={'left'}
                      placeholder="이름"
                      value={info.name}
                      onChange={(e, {value}) => setInfo(prevState => ({...prevState, name: value}))}
                      onKeyUp={e => (e.key === 'Enter' && contactNumberRef.current.querySelector('input').focus())}
                      error={validationResult?.name === false && {
                        content: '이름을 입력해주세요.',
                        pointing: 'above',
                      }} />
                  </Ref>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Ref innerRef={contactNumberRef}>
                    <Form.Field
                      control={Input}
                      label="전화번호"
                      icon={'mobile alternate'}
                      iconPosition={'left'}
                      placeholder="숫자만 입력해 주세요."
                      value={info.contactNumber}
                      onChange={(e, {value}) => {
                        const refinedValue = _.replace(value, /[^0-9.]/g, '');
                        setInfo(prevState => ({...prevState, contactNumber: refinedValue}));
                      }}
                      onKeyUp={e => (e.key === 'Enter' && employeeNumberRef.current.querySelector('input').focus())}
                      error={validationResult?.contactNumber === false && {
                        content: '전화번호를 입력해주세요.',
                        pointing: 'above',
                      }} />
                  </Ref>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Ref innerRef={employeeNumberRef}>
                    <Form.Field
                      control={Input}
                      label="종사원번호"
                      icon={'id card outline'}
                      iconPosition={'left'}
                      placeholder="종사원번호"
                      value={info.employeeNumber}
                      onChange={(e, {value}) => setInfo(prevState => ({...prevState, employeeNumber: value}))}
                      error={validationResult?.employeeNumber === false && {
                        content: '종사원번호를 입력해주세요.',
                        pointing: 'above',
                      }} />
                  </Ref>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Button type={'button'} content="아이디 찾기" icon={'search'} fluid primary onClick={findLoginId} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
        {(findResults.found !== null && findResults.found !== true) && (
          <Message size={'huge'} warning>
            <Icon name="help" />
            {findResults.message}
          </Message>
        )}
        {(findResults.found !== null && findResults.found === true) && (
          <>
            <Message size={'huge'} success>
              <Icon name="exclamation" />
              딜러님의 로그인 아이디는 [<b>{findResults.loginId}</b>]입니다.
            </Message>
            <Button fluid as={Link} to={'/'}>
              <Icon name={'arrow right'} style={{margin: '0em 0.42857143em 0em -0.21428571em'}} />로그인 화면으로 가기
            </Button>
          </>
        )}
      </div>
    </Container>
  );
}

export default LoginIdView;
