import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Message,
  Popup,
  Ref,
  Segment,
  Sticky,
} from 'semantic-ui-react';
import _ from 'lodash';
import 'rc-slider/assets/index.css';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {NetworkUtil} from '../../utils/NetworkUtil';
import SFCheckBox from '../../components/Bid/SFCheckBox';
import SFRange from '../../components/Bid/SFRange';
import SFToggle from '../../components/Bid/SFToggle';
import {useRecoilValue} from 'recoil';
import {tokenRefreshingAtom} from '../../recoil/atoms';
import BidListItem from '../../components/Bid/BidListItem';
import {InView, useInView} from 'react-intersection-observer';
import moment from 'moment';
import {BASE_STYLES} from '../../consts/consts';

export const CAR_CRITERION_STEPS = ['country', 'maker', 'model', 'series', 'seriesDetail'];
const BASE_SEARCH_CRITERIA = {
  country: {
    sort: 1,
    key: 'country',
    title: '분류',
    form: 'checkbox',
    data: [
      {label: '국산차', value: '1'},
      {label: '수입차', value: '2'},
      // {label: '화물 · 특장 · 기타', value: '99'},
    ],
  },
  maker: {
    sort: 2,
    key: 'maker',
    title: '제조사',
    form: 'checkbox',
    type: 'dynamic',
    data: [],
  },
  model: {
    sort: 3,
    key: 'model',
  },
  series: {
    sort: 4,
    key: 'series',
  },
  seriesDetail: {
    sort: 5,
    key: 'seriesDetail',
  },
  region: {
    sort: 6,
    key: 'region',
    title: '지역',
    form: 'toggle',
    type: 'dynamic',
    data: [],
  },
  madeAt: {
    sort: 7,
    key: 'madeAt',
    title: '연식',
    form: 'range',
    type: 'static',
    min: {label: '1994', value: 1994},
    max: {label: '2023', value: 2023},
    unit: '년',
  },
  accident: {
    sort: 8,
    key: 'accident',
    title: '사고',
    form: 'checkbox',
    type: 'static',
    data: [
      {label: '일반', value: 'N'},
      {label: '유사고 추정', value: 'S'},
      {label: '큰사고 추정', value: 'B'},
    ],
  },
  mission: {
    sort: 9,
    key: 'mission',
    title: '미션',
    form: 'checkbox',
    type: 'static',
    data: [
      {label: '오토 A/T', value: 'A'},
      {label: '수동 M/T', value: 'M'},
    ],
  },
  distance: {
    sort: 10,
    key: 'distance',
    title: '주행거리',
    form: 'range',
    type: 'static',
    min: {label: '0', value: 0},
    max: {label: '50만', value: 500000},
    unit: ' km',
  },
  fuel: {
    sort: 11,
    key: 'fuel',
    title: '연료',
    form: 'checkbox',
    type: 'static',
    data: [
      {label: '휘발유', value: 'GAS'},
      {label: '디젤', value: 'DIE'},
      {label: 'LPG', value: 'LPG'},
      {label: '하이브리드', value: 'HYB'},
      {label: '바이퓨얼', value: 'BIF'},
      {label: '전기', value: 'ELE'},
    ],
  },
  lease: {
    sort: 12,
    key: 'lease',
    title: '리스 · 렌트',
    form: 'checkbox',
    type: 'static',
    data: [
      {label: '현금 · 할부', value: 'CASH'},
      {label: '금융리스', value: 'FLEASE'},
      {label: '운용리스', value: 'WLEASE'},
      {label: '렌트', value: 'RENT'},
    ],
  },
};

const BASE_PAGE_SIZE = 5;
const BASE_BID_DATA = {
  pageSize: BASE_PAGE_SIZE,
  pageNum: 1,
  list: [],
};

function BidList({match, location, history}) {
  const stickyTagsRef = useRef();
  const stickyConditionsRef = useRef();

  const popupContextRef = useRef();
  const [isLoadPopupOpen, setIsLoadPopupOpen] = useState(false);
  const [isSavePopupOpen, setIsSavePopupOpen] = useState(false);

  const isTokenRefreshing = useRecoilValue(tokenRefreshingAtom);

  let baseSearchCriteria = _.cloneDeep(BASE_SEARCH_CRITERIA);
  let searchCriteria = _.reduce(_.cloneDeep(BASE_SEARCH_CRITERIA), (result, bluePrint, criterionKey) => {
    if (bluePrint.form === 'range') {
      result[criterionKey] = [bluePrint.min.value, bluePrint.max.value];
    } else {
      result[criterionKey] = [];
    }
    return result;
  }, {});
  searchCriteria = _.merge(searchCriteria, {
    model: [],
    series: [],
    seriesDetail: [],
    pageSize: BASE_PAGE_SIZE,
    pageNum: 1,
  });

  const searchCriteriaRef = useRef(searchCriteria);

  const [baseCriteria, setBaseCriteria] = useState(baseSearchCriteria);
  const [criteria, setCriteria] = useState(searchCriteria);

  const [madeAtTempRange, setMadeAtTempRange] = useState([BASE_SEARCH_CRITERIA['madeAt'].min.value, BASE_SEARCH_CRITERIA['madeAt'].max.value]);
  const [distanceTempRange, setDistanceTempRange] = useState([BASE_SEARCH_CRITERIA['distance'].min.value, BASE_SEARCH_CRITERIA['distance'].max.value]);
  const [isCriteriaLoading, setIsCriteriaLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const [bidData, setBidData] = useState(_.cloneDeep(BASE_BID_DATA));
  const prevBids = useRef([]);

  const {ref, inView, entry} = useInView({
    /* Optional options */
    threshold: 0,
  });

  const doSearch = useCallback(() => {
    setIsSearching(true);

    NetworkUtil.get(`/apis/bids`, criteria,
      data => {
        const newData = _.cloneDeep(data);

        if (criteria.pageNum === 1) prevBids.current = [];

        newData.list = [...prevBids.current, ...data.list];
        prevBids.current = newData.list;

        setBidData(newData);
      },
      errorData => {
      },
      () => setIsSearching(false));
  }, [criteria]);

  useEffect(() => {
    console.log(bidData);
  }, [bidData]);

  const onSearchConditionItemClicked = async (e, {name: criterionKey, value, nextstep}) => {
    setIsCriteriaLoading(true);

    let needRemove = _.includes(criteria[criterionKey], value);
    let currentCriteria = _.cloneDeep(criteria);

    let nextCriteria;
    let criterionKeyIndex = _.findIndex(CAR_CRITERION_STEPS, step => (step === criterionKey));

    if (needRemove) {
      if (_.includes(CAR_CRITERION_STEPS, criterionKey)) {
        let resetTargets = _.takeRight(CAR_CRITERION_STEPS, CAR_CRITERION_STEPS.length - (criterionKeyIndex + 1));
        _.forEach(resetTargets, key => _.set(currentCriteria, key, []));
      }

      nextCriteria = _.pull(currentCriteria[criterionKey], value);
    } else {
      nextCriteria = [...currentCriteria[criterionKey], value];
    }

    const nextKey = CAR_CRITERION_STEPS[criterionKeyIndex + 1];
    let newBaseConditions = _.cloneDeep(baseCriteria);

    if (_.includes(CAR_CRITERION_STEPS, criterionKey)) {
      let newData = [];
      if (criterionKey === 'country' || (nextCriteria.length > 0 && nextstep === 'Y')) {
        newData = await NetworkUtil.getAsync('/apis/data/base/car', {
          key: criterionKey,
          conditions: nextCriteria,
        });
      }

      if (criterionKey === 'region') {
        newData = await NetworkUtil.getAsync('/apis/data/base/location/states', {
          key: criterionKey,
          conditions: nextCriteria,
        });
      }

      nextKey && _.set(newBaseConditions, `${nextKey}.data`, newData);
    }

    setBaseCriteria(newBaseConditions);
    setCriteria({...currentCriteria, [criterionKey]: nextCriteria, pageSize: BASE_PAGE_SIZE, pageNum: 1});
    setIsCriteriaLoading(false);
  };

  const onMadeAtRangeChanged = ([min, max]) => {
    setMadeAtTempRange([min, max]);
  };
// const debouncedOnRangeChanged = useRef(debounce(onRangeChanged, 10));
  const onMadeAtAfterRangeChanged = ([min, max]) => {
    setCriteria({...criteria, madeAt: [min, max], pageSize: BASE_PAGE_SIZE, pageNum: 1});
  };

  const onDistanceRangeChanged = ([min, max]) => {
    setDistanceTempRange([min, max]);
  };
// const debouncedOnRangeChanged = useRef(debounce(onRangeChanged, 10));
  const onDistanceAfterRangeChanged = ([min, max]) => {
    setCriteria({...criteria, distance: [min, max], pageSize: BASE_PAGE_SIZE, pageNum: 1});
  };

  const resetRanges = (key) => {
    const RESET_VALUES = [BASE_SEARCH_CRITERIA[key].min.value, BASE_SEARCH_CRITERIA[key].max.value];
    if (key === 'madeAt') setMadeAtTempRange(RESET_VALUES);
    if (key === 'distance') setDistanceTempRange(RESET_VALUES);

    setCriteria(prevState => ({
      ...prevState,
      [key]: RESET_VALUES,
      pageSize: BASE_PAGE_SIZE,
      pageNum: 1,
    }));
  };

  const [searchCriteriaPreference, setSearchCriteriaPreference] = useState([]);
  const [criteriaTitle, setCriteriaTitle] = useState('');
  const [isSavingCriteria, setIsSavingCriteria] = useState(false);
  const [isCriteriaSaved, setIsCriteriaSaved] = useState(false);
  const [selectedCriteriaIndex, setSelectedCriteriaIndex] = useState(-1);

  const savePreferredSearchCriteria = () => {
    if (_.isEmpty(_.trim(criteriaTitle))) return false;

    const cloned = _.cloneDeep(criteria);
    _.unset(cloned, 'pageSize');
    _.unset(cloned, 'pageNum');

    setIsSavingCriteria(true);

    NetworkUtil.put('/apis/dealer/preferences/criteria', {
      title: criteriaTitle,
      criteria: JSON.stringify(cloned),
    }, data => {
      setIsCriteriaSaved(true);
      setSearchCriteriaPreference(data);
      setCriteriaTitle('');
    }, errorData => {

    }, () => {
      setIsSavingCriteria(false);
      setTimeout(() => setIsSavePopupOpen(false), 3000);
    });
  };

  // TODO - 의존성 배열에 뭔가 넣으면 무한루프인데 이유가 뭔가? eslint는 warning을 뱉고 있는데.
  // TODO - 페이지 새로 고침 시 2번씩 호출됨. 이거 해결 가능?
  // TODO - 여기 반드시 다듬기.
  const [isBaseDataInitialized, setIsBaseDataInitialized] = useState(false);

  useDeepCompareEffect(() => {
    async function getBaseData() {
      const makers = await NetworkUtil.getAsync('/apis/data/base/car', {
        key: 'country',
        conditions: ['1', '2'],
      });
      const regions = await NetworkUtil.getAsync('/apis/data/base/location/states', {});

      setBaseCriteria(prevState => {
        const newState = _.cloneDeep(prevState);
        _.set(newState, 'maker.data', makers);
        _.set(newState, 'region.data', regions);

        return newState;
      });
    }

    if (!isBaseDataInitialized
      && !isTokenRefreshing
      && (baseCriteria.maker.data.length === 0 && baseCriteria.region.data.length === 0)) {
      getBaseData()
        .then(r => setIsBaseDataInitialized(true));
    }
  }, [baseCriteria, isTokenRefreshing, isBaseDataInitialized]);

  useEffect(() => {
    if (!isTokenRefreshing) NetworkUtil.get('/apis/dealer/preferences/criteria', {}, data => {
      setSearchCriteriaPreference(data);
    }, errorData => {

    }, () => {
    });
  }, [isTokenRefreshing]);

  useEffect(() => {
    if (!isTokenRefreshing) doSearch();
  }, [doSearch, isTokenRefreshing]);

  return (
    <div className={'carmon-view-container'}>
      <div className={'bid-list-search-conditions'}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Segment style={{
                minHeight: 80,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
                <Header as={'h3'} style={{margin: 0}}>전체차량 {bidData?.total}대</Header>
                <Button.Group>
                  <Button icon={'refresh'} basic compact onClick={() => {
                    setBidData(prevState => (_.cloneDeep(BASE_BID_DATA)));
                    setCriteria(prevState => ({...prevState, pageSize: BASE_PAGE_SIZE, pageNum: 1}));
                  }} />
                  {/*<Button icon={'star outline'} basic compact />*/}
                </Button.Group>
              </Segment>

              <SFCheckBox
                base={baseCriteria}
                conditions={criteria}
                condKey={'country'}
                onItemClicked={onSearchConditionItemClicked} />

              <SFCheckBox
                base={baseCriteria}
                conditions={criteria}
                condKey={'maker'}
                isLoading={isCriteriaLoading}
                onItemClicked={onSearchConditionItemClicked}>

                <SFCheckBox
                  base={baseCriteria}
                  conditions={criteria}
                  condKey={'model'}
                  isLoading={isCriteriaLoading}
                  renderIf={criteria['maker'].length > 0}
                  isInner={true}
                  onItemClicked={onSearchConditionItemClicked}>

                  <SFCheckBox
                    base={baseCriteria}
                    conditions={criteria}
                    condKey={'series'}
                    isLoading={isCriteriaLoading}
                    renderIf={criteria['model'].length > 0}
                    isInner={true}
                    onItemClicked={onSearchConditionItemClicked}>

                    <SFCheckBox
                      base={baseCriteria}
                      conditions={criteria}
                      condKey={'seriesDetail'}
                      isLoading={isCriteriaLoading}
                      renderIf={criteria['series'].length > 0}
                      isInner={true}
                      onItemClicked={onSearchConditionItemClicked} />
                  </SFCheckBox>
                </SFCheckBox>
              </SFCheckBox>

              <SFToggle
                condKey={'region'}
                base={baseCriteria}
                conditions={criteria}
                onItemClicked={onSearchConditionItemClicked} />

              {/*TODO - Slider 움직임이 버벅이는 문제*/}
              <SFRange
                condKey={'madeAt'}
                base={baseCriteria}
                conditions={criteria}
                onRangeChanged={onMadeAtRangeChanged}
                onAfterRangeChanged={onMadeAtAfterRangeChanged} />

              <SFCheckBox
                condKey={'accident'}
                base={baseCriteria}
                conditions={criteria}
                onItemClicked={onSearchConditionItemClicked} />

              <SFCheckBox
                condKey={'mission'}
                base={baseCriteria}
                conditions={criteria}
                onItemClicked={onSearchConditionItemClicked} />

              <SFRange
                condKey={'distance'}
                base={baseCriteria}
                conditions={criteria}
                step={10000}
                onRangeChanged={onDistanceRangeChanged}
                onAfterRangeChanged={onDistanceAfterRangeChanged} />

              <SFCheckBox
                condKey={'fuel'}
                base={baseCriteria}
                conditions={criteria}
                onItemClicked={onSearchConditionItemClicked} />

              <SFCheckBox
                condKey={'lease'}
                base={baseCriteria}
                conditions={criteria}
                onItemClicked={onSearchConditionItemClicked} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

      <Ref innerRef={stickyTagsRef}>
        <div className={'bid-list'}>
          <Sticky offset={102} context={stickyTagsRef}>
            <Segment
              style={{
                minHeight: 80,
                padding: `2px 6px`,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <Icon name={'search'} size={'large'} />
              </div>
              <div className={'bid-list-tags'}
                   style={{flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 4}}>
                {_.map(_.sortBy(baseCriteria, 'sort'), (baseCondition, idx) => {
                  const key = baseCondition.key;
                  const condition = criteria[key];

                  if (!_.isEmpty(condition) && _.has(baseCriteria, key)) {
                    const form = baseCriteria[key].form;
                    const unit = baseCriteria[key].unit;

                    if (form === 'range') {
                      return (
                        <Label
                          key={`lbl_${key}_${idx}`}
                          as="a"
                          name={key}
                          value={condition}
                          onClick={() => resetRanges(key)}>
                          {condition[0]}{unit}~{condition[1]}{unit}
                          <Icon name="close" />
                        </Label>
                      );
                    } else {
                      let data;
                      let labelKey = 'label', valueKey = 'value';

                      if (key !== 'country' && _.includes(CAR_CRITERION_STEPS, key)) {
                        labelKey = `${key}Name`;
                        valueKey = `${key}No`;

                        return (<React.Fragment key={`lbl_${key}_${idx}`}>
                          {_.map(condition, (con, idx2) => {
                            data = _.find(baseCriteria[key].data, item => con === item[valueKey]);
                            return data && (
                              <Label
                                key={`lbl_${key}_${idx}_${idx2}`}
                                as="a"
                                name={key}
                                value={con}
                                onClick={onSearchConditionItemClicked}>
                                {data[labelKey]}
                                <Icon name="close" />
                              </Label>
                            );
                          })}
                        </React.Fragment>);
                      }

                      if (key === 'region') {
                        labelKey = 'shortName';
                        valueKey = 'code';
                      }

                      return (<React.Fragment key={`lbl_${key}_${idx}`}>
                        {_.map(condition, (con, idx2) => {
                          data = _.find(baseCriteria[key].data, item => con === item[valueKey]);
                          return data && (
                            <Label
                              key={`lbl_${key}_${idx}_${idx2}`}
                              as="a"
                              name={key}
                              value={con}
                              onClick={onSearchConditionItemClicked}>
                              {data[labelKey]}
                              <Icon name="close" />
                            </Label>
                          );
                        })}
                      </React.Fragment>);
                    }
                  } else {
                    return null;
                  }
                })}
              </div>

              <div
                ref={popupContextRef}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Button.Group size={'tiny'}>
                  <Button icon={'remove'} content={'초기화'} onClick={() => setCriteria(searchCriteriaRef.current)} />
                  <Dropdown
                    className="button icon"
                    floating
                    options={[
                      {
                        key: 'folder open',
                        icon: 'folder open',
                        text: '불러오기',
                        value: 'folder open',
                        style: {fontSize: BASE_STYLES.FONT_SIZE.SUB},
                        active: false,
                        selected: false,
                        onClick: () => setIsLoadPopupOpen(prevState => !prevState),
                      },
                      {
                        key: 'save',
                        icon: 'save',
                        text: '저장하기',
                        value: 'save',
                        style: {fontSize: BASE_STYLES.FONT_SIZE.SUB},
                        active: false,
                        selected: false,
                        onClick: () => setIsSavePopupOpen(prevState => !prevState),
                      },
                    ]}
                    trigger={<></>}
                  />
                </Button.Group>
              </div>

              <Popup
                context={popupContextRef}
                open={isLoadPopupOpen}
                size={'mini'}
                flowing
                position={'bottom right'}
                on={'click'}
                style={{padding: 0, border: 0}}
                onClose={() => setIsLoadPopupOpen(false)}>
                <Popup.Content>
                  <div style={{width: 260, padding: 8}}>
                    {searchCriteriaPreference.length === 0
                      ?
                      (
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          fontSize: BASE_STYLES.FONT_SIZE.SUB,
                        }}>
                          <Icon name={'exclamation'} /> 저장된 검색조건이 없습니다.
                        </div>
                      )
                      :
                      (
                        _.map(searchCriteriaPreference, (v, idx) => (
                          <div
                            key={`criteria_${idx}`}
                            className={'criteria-item'}>
                            <div className={'criteria-title'} onClick={() => {
                              let criteria = JSON.parse(v.criteria);
                              criteria = _.merge(criteria, {
                                pageNum: 1,
                                pageSize: BASE_PAGE_SIZE,
                              });

                              setCriteria(criteria);
                              setIsLoadPopupOpen(false);
                            }}>
                              <span>{v.title}</span>
                              <span>{moment(v.regTime).format('YYYY-MM-DD HH:mm:ss')}</span>
                            </div>
                            <div className={'action-button'}>
                              <Button style={{margin: 0}} size={'mini'} circular icon={'remove'} onClick={() => {
                                NetworkUtil.post('/apis/dealer/preferences/criteria', {
                                  title: v.title,
                                }, data => {
                                  setSearchCriteriaPreference(data);
                                }, errorData => {

                                }, () => {
                                  setIsLoadPopupOpen(false);
                                });
                              }} />
                            </div>
                          </div>
                        ))
                      )}
                  </div>
                </Popup.Content>
              </Popup>

              <Popup
                context={popupContextRef}
                open={isSavePopupOpen}
                size={'mini'}
                flowing
                position={'bottom right'}
                on={'click'}
                onClose={() => {
                  setIsSavePopupOpen(false);
                  setIsCriteriaSaved(false);
                }}>
                <Popup.Content>
                  <Form size={'small'} success={isCriteriaSaved}>
                    <Form.Field>
                      <label>검색조건이름</label>
                      <Input placeholder="예) 현대-아반떼-2019년식-10만km"
                             disabled={isSavingCriteria}
                             value={criteriaTitle}
                             onChange={(e, {value}) => setCriteriaTitle(value)} />
                    </Form.Field>
                    <Message size={'small'} content={(<span>
                        <Icon name={'question circle'} /> 선호하는 차량을 검색하실 때 이용하실 수 있습니다.
                      </span>)} />
                    <Message
                      size={'small'}
                      success
                      header="저장완료!"
                      content="검색조건이 정상적으로 저장되었습니다."
                    />
                    <Button size={'small'} primary loading={isSavingCriteria} icon={'save'} content={'저장하기'}
                            floated={'right'}
                            onClick={() => savePreferredSearchCriteria()} />
                  </Form>
                </Popup.Content>
              </Popup>
            </Segment>
          </Sticky>

          <div style={{display: 'flex', flexWrap: 'wrap', gap: `22px`, marginTop: 26}}>
            {bidData?.list.map((bid, idx) => {
              return !isSearching && bidData.hasNextPage && idx === bidData.list.length - 1
                ? (
                  <InView
                    key={`bid-item-${idx}`}
                    onChange={(inView, entry) => {
                      if (inView) setCriteria(prevState => ({...prevState, pageNum: bidData.nextPage}));
                    }}>
                    <BidListItem context={ref} bidData={bid} />
                  </InView>
                )
                : <BidListItem key={`bid-item-${idx}`} bidData={bid} />;
            })}
          </div>
        </div>
      </Ref>
    </div>
  );
}

export default BidList;
