import {ENVIRONMENTS, ERROR_CODE} from '../consts/consts';

import axios from 'axios';

import {NetworkUtil} from '../utils/NetworkUtil';
import {Logger} from '../utils/Logger';

export function login({loginId, password}, onSuccess, onError) {
  NetworkUtil.post('/apis/token/issue', {loginId, password},
    data => {
      const {accessToken} = data;
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      onSuccess && onSuccess(accessToken);
    },
    errorData => {
      Logger.debug(errorData);
      onError && onError(errorData);
    });
}

export function logout(onSuccess, onError) {
  NetworkUtil.post('/apis/token/logout', {},
    data => {
      onSuccess && onSuccess(data);
    },
    errorData => {
      onError && onError(errorData);
    });
}

export async function refreshToken(onSuccess, onError) {
  let refreshTokenAxios = axios.create({
    baseURL: ENVIRONMENTS.API_BASE_URL,
    timeout: 10000,
    withCredentials: true,
  });

  try {
    const {
      data: {
        accessToken,
      },
    } = await refreshTokenAxios.post(`/apis/token/refresh`, {});

    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    onSuccess && onSuccess(accessToken);
  } catch (error) {
    const {
      status,
      data: {
        code: errorCode,
      },
    } = error.response;

    if (status === 401 && errorCode === ERROR_CODE.REFRESH_TOKEN_EXPIRED) {
      onError && onError(error.response);
    }
  } finally {
    Logger.debug('[Token Refreshed]');
  }
}

export async function asyncRefreshToken() {
  let refreshTokenAxios = axios.create({
    baseURL: ENVIRONMENTS.API_BASE_URL,
    timeout: 10000,
    withCredentials: true,
  });

  try {
    const {
      data: {
        accessToken,
      },
    } = await refreshTokenAxios.post(`/apis/token/refresh`, {});
    Logger.debug('[asyncRefreshToken]', accessToken);

    return accessToken;
  } catch (error) {
    if (NetworkUtil.isNetworkError(error)) {
      Logger.error('Network Error');
      throw error;
    } else {
      throw error;
    }
  }
}
