import React from 'react';
import {Button, Grid, Header, Placeholder, Segment} from 'semantic-ui-react';
import Sidebar from '../../components/register/Sidebar';
import {Link} from 'react-router-dom';
import {THEME} from '../../consts/consts';

function CompletionView() {
  return (
    <>
      <Grid container style={{paddingTop: '7em', paddingBottom: '7em'}}>
        <Grid.Row columns={2}>
          <Grid.Column width={4}>
            <Sidebar />
          </Grid.Column>
          <Grid.Column width={12}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h2" block>
                    이용약관
                  </Header>
                  <Segment>
                    <Placeholder>
                      <Placeholder.Header image>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3} centered>
                <Grid.Column width={4} />
                <Grid.Column width={8} textAlign={'center'}>
                  <Button fluid color={THEME} as={Link} to={'/'}>
                    로그인 하기
                  </Button>
                </Grid.Column>
                <Grid.Column width={4} />
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default CompletionView;
