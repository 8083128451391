import {Button, Container, Form, Grid, Header, Icon, Input, Modal, Popup, Segment, Table} from 'semantic-ui-react';
import {useRecoilValue} from 'recoil';
import {tokenRefreshingAtom} from '../../../recoil/atoms';
import {Logger} from '../../../utils/Logger';
import React, {useCallback, useEffect, useState} from 'react';
import {NetworkUtil} from '../../../utils/NetworkUtil';
import {THEME} from '../../../consts/consts';
import {Link} from 'react-router-dom';

import Postcode from '@actbase/react-daum-postcode';
import _ from 'lodash';

export default function Address({match, location, history}) {
  const isTokenRefreshing = useRecoilValue(tokenRefreshingAtom);

  const [consignmentAddress, setConsignmentAddress] = useState({});
  const [consignmentAddresses, setConsignmentAddresses] = useState([]);

  const [isProceeding, setIsProceeding] = useState(false);
  const [editMode, setEditMode] = useState(['N', 0]);
  const [isPostOpened, setIsPostOpened] = useState(false);

  const getConsignmentAddresses = useCallback(() => {
    NetworkUtil.get(`/apis/dealer/consignments`, {}, data => setConsignmentAddresses(data));
  }, []);

  useEffect(() => {
    if (!isTokenRefreshing) getConsignmentAddresses();
  }, [isTokenRefreshing, getConsignmentAddresses]);

  return (
    <Container className={'carmon-view-container'}
               style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
      <Segment basic style={{width: 660}}>
        <Segment attached="top" color={THEME}>
          <Button content="뒤로가기" icon="arrow circle left" labelPosition="left" as={Link} to={'/my-info'} replace />
        </Segment>
        <Segment attached style={{borderBottom: 'none'}}>
          <Grid style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
            <Grid.Column width={10}>
              <Header as={'h4'}>
                <Header.Content>
                  <Icon name={'user'} />
                  탁송지 관리
                </Header.Content>
                <Header.Subheader>
                  탁송지 주소를 관리합니다.
                </Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column width={6} textAlign={'right'}>
              <Button type={'button'} icon={'address book outline'} content={'신규등록'} disabled={editMode[0] !== 'N'}
                      onClick={e => {
                        setConsignmentAddress({});
                        setEditMode(['I', 0]);
                      }} />
            </Grid.Column>
          </Grid>
        </Segment>

        <Segment basic attached loading={isProceeding} style={{padding: 0}}>
          {
            editMode[0] === 'I' && <Segment attached>
              <Form>
                <Segment basic style={{padding: 0}}>
                  <Segment.Group>
                    <Segment>
                      <Grid style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
                        <Grid.Column width={10}>
                          <Input
                            placeholder={'탁송지명'}
                            value={consignmentAddress.title}
                            onChange={(e, {value}) => setConsignmentAddress({
                              ...consignmentAddress,
                              title: value,
                            })}
                          />
                        </Grid.Column>
                        <Grid.Column width={6} textAlign={'right'}>
                          <Button type={'button'} icon={'save'} color={'orange'} circular inverted
                                  onClick={e => {
                                    if (_.isEmpty(consignmentAddress.title)
                                      || _.isEmpty(consignmentAddress.postalCode)
                                      || _.isEmpty(consignmentAddress.address)
                                      || _.isEmpty(consignmentAddress.addressDetail)) return;
                                    setIsProceeding(true);

                                    NetworkUtil.put(`/apis/dealer/consignments`, consignmentAddress, data => {
                                      setEditMode(['N', 0]);
                                      getConsignmentAddresses();
                                    }, errorData => {

                                    }, () => setIsProceeding(false));
                                  }} />
                          <Button type={'button'} icon={'undo'} circular disabled={editMode[0] !== 'I'}
                                  onClick={e => setEditMode(['N', 0])} />
                        </Grid.Column>
                      </Grid>
                    </Segment>

                    <Segment>
                      <Form.Field>
                        <Input
                          label={{basic: true, content: consignmentAddress.postalCode || '우편번호'}}
                          labelPosition="left"
                          placeholder={'탁송지 주소'}
                          value={consignmentAddress.address}
                          readOnly
                          action={{
                            icon: 'search',
                            position: 'right',
                            color: THEME,
                            disabled: editMode[0] !== 'I',
                            onClick: e => setIsPostOpened(true),
                          }}
                        />
                      </Form.Field>

                      <Form.Field>
                        <Input
                          placeholder={'탁송지 상세주소'}
                          value={consignmentAddress.addressDetail}
                          onChange={(e, {value}) => {
                            setConsignmentAddress({
                              ...consignmentAddress,
                              addressDetail: value,
                            });
                          }}
                          disabled={editMode[0] === 'I' && _.isEmpty(consignmentAddress.address)}
                        />
                      </Form.Field>
                    </Segment>
                  </Segment.Group>
                </Segment>
              </Form>
            </Segment>
          }

          <Segment attached={'bottom'}>
            <Form>
              {
                consignmentAddresses.length === 0
                  ? <Header as={'h4'}>No Data</Header>
                  : (<>
                    {_.map(consignmentAddresses, (address, idx) => {
                      return (
                        <Segment key={`address_${idx}`} basic style={{padding: 0}}>
                          <Segment.Group>
                            <Segment>
                              <Grid style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
                                <Grid.Column width={10}>
                                  <Input
                                    placeholder={'탁송지명'}
                                    value={address.title}
                                    readOnly={!(editMode[0] === 'U')}
                                    onChange={(e, {value}) => {
                                      setConsignmentAddresses([
                                        ...consignmentAddresses.slice(0, idx),
                                        {...address, title: value},
                                        ...consignmentAddresses.slice(idx + 1),
                                      ]);
                                    }}
                                  />
                                </Grid.Column>
                                <Grid.Column width={6} textAlign={'right'}>
                                  <Button
                                    type={'button'} icon={editMode[0] === 'U' ? 'save' : 'pencil'} color={'orange'}
                                    circular inverted
                                    onClick={e => {
                                      if (editMode[0] === 'U') {
                                        setIsProceeding(true);

                                        NetworkUtil.post(`/apis/dealer/consignments`, consignmentAddresses[idx], data => {
                                          setEditMode(['N', 0]);
                                          getConsignmentAddresses();
                                        }, errorData => {

                                        }, () => {
                                          setIsProceeding(false);
                                        });
                                      } else {
                                        setEditMode(['U', idx]);
                                      }
                                    }} />
                                  {
                                    editMode[0] === 'U'
                                      ? <Button type={'button'} icon={'undo'}
                                                circular
                                                onClick={e => setEditMode(['N', 0])} />
                                      : <Popup
                                        compact
                                        flowing
                                        trigger={<Button type={'button'} icon={'trash'}
                                                         circular
                                                         color={'red'}
                                                         inverted />}
                                        content={
                                          <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                          }}>
                                            <Header as={'h5'} style={{flex: 1, marginBottom: 0, marginRight: 12}}>
                                              선택하신 탁송지를 삭제하시겠습니까?
                                            </Header>
                                            <Button size={'small'} color={'red'} content="삭제" onClick={e => {
                                              setIsProceeding(true);

                                              NetworkUtil.delete(`/apis/dealer/consignments/${address.consignmentAddressId}`, {}, data => {
                                                setEditMode(['N', 0]);
                                                getConsignmentAddresses();
                                              }, errorData => {

                                              }, () => setIsProceeding(false));
                                            }} />
                                          </div>
                                        }
                                        on="click"
                                        position="bottom right"
                                      />
                                  }
                                </Grid.Column>
                              </Grid>
                            </Segment>

                            <Segment>
                              <Form.Field>
                                <Input
                                  label={{basic: true, content: address.postalCode || '우편번호'}}
                                  labelPosition="left"
                                  placeholder={'탁송지 주소'}
                                  value={address.address}
                                  readOnly
                                  action={{
                                    icon: 'search',
                                    position: 'right',
                                    color: THEME,
                                    disabled: editMode[0] !== 'U',
                                    onClick: e => setIsPostOpened(true),
                                  }}
                                />
                              </Form.Field>

                              <Form.Field>
                                <Input
                                  placeholder={'탁송지 상세주소'}
                                  value={address.addressDetail}
                                  onChange={(e, {value}) => {
                                    setConsignmentAddress({
                                      ...consignmentAddress,
                                      addressDetail: value,
                                    });
                                  }}
                                  disabled={editMode[0] === 'I' && _.isEmpty(consignmentAddress.address)}
                                />
                              </Form.Field>
                            </Segment>
                          </Segment.Group>
                        </Segment>
                      );
                    })}
                  </>)
              }
            </Form>
          </Segment>
        </Segment>
      </Segment>

      <Modal
        open={isPostOpened}
        size={'tiny'}
        basic
        style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        <Modal.Header style={{padding: '6px 0', textAlign: 'right'}}>
          <Icon name={'close'} link onClick={() => setIsPostOpened(false)} />
        </Modal.Header>
        <Postcode
          style={{flex: 1}}
          jsOptions={{animation: true, hideMapBtn: true}}
          onSelected={data => {
            setConsignmentAddress({
              ...consignmentAddress,
              address: data.address,
              postalCode: data.zonecode,
            });

            setIsPostOpened(false);
          }}
        />
      </Modal>
    </Container>
  );
}
