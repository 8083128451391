import {Button, Container, Dimmer, Form, Header, Icon, Input, Message, Modal, Ref, Segment} from 'semantic-ui-react';
import React, {useEffect, useRef, useState} from 'react';
import {NetworkUtil} from '../../../utils/NetworkUtil';
import {THEME} from '../../../consts/consts';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {useRecoilValue} from 'recoil';
import {tokenRefreshingAtom} from '../../../recoil/atoms';
import {CommonUtil} from '../../../utils/CommonUtil';
import {Logger} from '../../../utils/Logger';

export default function Profile({match, location, history}) {
  const isTokenRefreshing = useRecoilValue(tokenRefreshingAtom);

  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);

  const [dealer, setDealer] = useState({});
  const [validationResult, setValidationResult] = useState(null);

  const [isCheckerOpen, setIsCheckerOpen] = useState(false);
  const [certificationCode, setCertificationCode] = useState(null);
  const [certificationError, setCertificationError] = useState(null);

  const [inPasswordUpdating, setInPasswordUpdating] = useState(false);
  const [passwordUpdateDone, setPasswordUpdateDone] = useState(false);

  const [inContactUpdating, setInContactUpdating] = useState(false);
  const [contactUpdateDone, setContactUpdateDone] = useState(false);

  const getDealer = () => {
    NetworkUtil.get(`/apis/dealer`, {}, data => setDealer(data));
  };

  const updatePassword = async () => {
    if (!CommonUtil.Validator.validatePassword(dealer.password)) {
      setValidationResult(prevState => ({...prevState, password: false}));
      return false;
    }

    const updatedDealer = await NetworkUtil.postAsync('/apis/dealer/profile', {
      of: 'PW',
      password: dealer.password,
    }, setInPasswordUpdating);

    setPasswordUpdateDone(true);
    setDealer({...updatedDealer, password: '', passwordConfirm: ''});
    setTimeout(() => setPasswordUpdateDone(false), 3000);
  };

  const openContactNumberChecker = () => {
    setIsCheckerOpen(true);
  };

  const sendContactNumberCheckAT = () => {
    setCertificationError(null);
    setInContactUpdating(true);

    NetworkUtil.post('/apis/dealer/profile/checker/contact', {
      loginId: dealer.loginId,
      contactNumber: dealer.contactNumber,
    }, data => {
      setCertificationCode(data);
    }, errorData => {
      setCertificationError(errorData);
    }, () => setInContactUpdating(false));
  };

  const updateContact = async () => {
    if (!CommonUtil.Validator.validatePassword(dealer.password)) {
      setValidationResult(prevState => ({...prevState, password: false}));
      return false;
    }

    const updatedDealer = await NetworkUtil.postAsync('/apis/dealer/profile', {
      of: 'CT',
      contactNumber: dealer.contactNumber,
    }, setInContactUpdating);

    setContactUpdateDone(true);
    setDealer(updatedDealer);
    setTimeout(() => setContactUpdateDone(false), 3000);
  };

  const validateForm = () => {
    const result = _.reduce(dealer, (res, value, key) => {
      res[key] = !_.isEmpty(value);
      return res;
    }, {});

    setValidationResult(result);
    return result;
  };

  useEffect(() => {
    if (!isTokenRefreshing) getDealer();
  }, [isTokenRefreshing]);

  return (
    <Container className={'carmon-view-container'}
               style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
      <Segment basic style={{width: 660}}>
        <Segment attached="top" color={THEME}>
          <Button content="뒤로가기" icon="arrow circle left" labelPosition="left" as={Link} to={'/my-info'} replace />
        </Segment>
        <Segment attached>
          <Header as={'h4'}>
            <Header.Content>
              <Icon name={'user'} />
              프로필 수정
            </Header.Content>
            <Header.Subheader>
              카몬에서 사용되는 프로필과 계정정보를 수정합니다.
            </Header.Subheader>
          </Header>
        </Segment>
        <Segment attached={'bottom'}>
          <Form>
            <Segment.Group>
              <Segment>
                <Form.Group widths={'equal'}>
                  <Form.Field
                    control={Input}
                    label="아이디"
                    placeholder="아이디"
                    value={dealer.loginId}
                    readOnly
                  />

                  <Form.Field
                    control={Input}
                    label="이름"
                    placeholder="이름"
                    value={dealer.name}
                    readOnly
                  />
                </Form.Group>
              </Segment>
            </Segment.Group>

            <Segment basic loading={inPasswordUpdating} style={{padding: 0}}>
              <Dimmer active={passwordUpdateDone}>
                <Message positive content={'비밀번호가 변경되었습니다.'} style={{margin: 0}} />
              </Dimmer>
              <Segment.Group>
                <Segment>
                  <Ref innerRef={passwordRef}>
                    <Form.Field
                      control={Input}
                      type={'password'}
                      label="비밀번호"
                      placeholder="비밀번호 (영문, 숫자, 특수문자 조합, 공백 제외, 최소 8자)"
                      value={dealer.password}
                      onChange={(e, {value}) => {
                        const refinedValue = _.replace(value, /\s/g, '');
                        setDealer({...dealer, password: refinedValue});
                      }}
                      onKeyUp={e => {
                        if (e.key === 'Enter') passwordConfirmRef.current.querySelector('input').focus();
                        else {
                          setValidationResult(prevState => ({
                            ...prevState,
                            password: CommonUtil.Validator.validatePassword(dealer.password),
                          }));
                        }
                      }}
                      error={(validationResult?.password === false) && {
                        content: '비밀번호를 최소 8자의 공백을 제외한 영문, 숫자, 특수문자 조합으로 만들어주세요.',
                        pointing: 'below',
                      }}
                    />
                  </Ref>
                  <Ref innerRef={passwordConfirmRef}>
                    <Form.Field
                      control={Input}
                      type={'password'}
                      placeholder="비밀번호 확인"
                      value={dealer.passwordConfirm}
                      onChange={(e, {value}) => {
                        const refinedValue = _.replace(value, /\s/g, '');
                        setDealer({...dealer, passwordConfirm: refinedValue});
                      }}
                      error={(dealer.password !== dealer.passwordConfirm) && {
                        content: '비밀번호와 다릅니다.',
                        pointing: 'below',
                      }}
                    />
                  </Ref>
                </Segment>
                <Segment textAlign={'right'}>
                  <Button type={'button'} loading={inPasswordUpdating} content={'변경하기'}
                          onClick={() => updatePassword()} />
                </Segment>
              </Segment.Group>
            </Segment>

            <Segment basic loading={inContactUpdating} style={{padding: 0}}>
              <Dimmer active={contactUpdateDone}>
                <Message positive content={'휴대전화번호가 변경되었습니다.'} style={{margin: 0}} />
              </Dimmer>
              <Segment.Group>
                <Segment>
                  <Form.Field
                    control={Input}
                    label={'휴대전화번호'}
                    placeholder="휴대전화번호 (숫자만)"
                    value={dealer.contactNumber}
                    readOnly={certificationCode !== null}
                    maxLength={12}
                    action={{
                      type: 'button',
                      color: THEME,
                      content: '인증메시지받기',
                      disabled: _.isEmpty(dealer.contactNumber) || (certificationCode !== '' && certificationCode !== null),
                      onClick: () => openContactNumberChecker(),
                    }}
                    onKeyUp={e => (e.key === 'Enter' && certificationCode === null && openContactNumberChecker())}
                    onChange={(e, {value}) => {
                      const refinedValue = _.replace(value, /[^0-9.]/g, '');
                      setDealer({...dealer, contactNumber: refinedValue});
                      setCertificationCode(null);
                    }}
                    error={(!_.isEmpty(dealer.contactNumber) && certificationError !== null) && {
                      content: certificationError.message,
                      pointing: 'below',
                    }}
                  />
                  <Message color={'red'} hidden={certificationCode === null}>
                    <Message.Header>
                      휴대전화로 전송된 인증메시지를 먼저 확인해주세요!
                    </Message.Header>
                  </Message>
                </Segment>
                <Segment textAlign={'right'}>
                  {certificationCode === null ? (
                    <Button type={'button'} content={'변경하기'} onClick={() => updateContact()} />
                  ) : (
                    <Button type={'button'} content={'변경확인하기'} onClick={() => getDealer()} />
                  )}
                </Segment>
              </Segment.Group>
            </Segment>
          </Form>
        </Segment>
      </Segment>

      <Modal
        basic
        open={isCheckerOpen}
        size={'tiny'}>
        <Header>
          <Icon name={'exclamation circle'} /> 휴대전화번호 확인!
        </Header>
        <Modal.Content>
          휴대전화번호 확인을 위해 입력하신 번호 <b>[{dealer.contactNumber}]</b> 로 알림톡이 발송됩니다.<br />
          잘못 입력하신 경우, 알림톡을 받으실 수 없습니다.
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" inverted onClick={() => setIsCheckerOpen(false)}>
            <Icon name="remove" /> 다시입력
          </Button>
          <Button primary inverted onClick={async () => {
            await sendContactNumberCheckAT();
            setIsCheckerOpen(false);
          }}>
            <Icon name="checkmark" /> 인증메시지받기
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  )
    ;
}
