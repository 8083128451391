import React, {useState} from 'react';
import {Header, Segment} from 'semantic-ui-react';
import {Range} from 'rc-slider';
import 'rc-slider/assets/index.css';
import {BASE_STYLES} from '../../consts/consts';

function SFRange({condKey: key, base, conditions, step = 1, onRangeChanged, onAfterRangeChanged}) {
  const title = base[key].title;
  const unit = base[key].unit;
  const minData = base[key].min;
  const maxData = base[key].max;
  const minValue = minData.value;
  const maxValue = maxData.value;

  const [value, setValue] = useState([minValue, maxValue]);

  return (
    <>
      {title && <Header as={'h5'} attached={'top'}>{title}</Header>}
      <Segment attached style={{borderRadius: '0 0 .28571429rem .28571429rem'}}>
        <Header as={'h5'}>{value[0]}{unit} ~ {value[1]}{unit}</Header>
        <Range
          defaultValue={[minValue, maxValue]}
          min={minValue}
          max={maxValue}
          step={step}
          onChange={([min, max]) => setValue([min, max])}
          onAfterChange={onAfterRangeChanged}
          trackStyle={[{backgroundColor: BASE_STYLES.THEME_COLOR}, {backgroundColor: BASE_STYLES.THEME_COLOR}]}
          handleStyle={[{borderColor: BASE_STYLES.THEME_COLOR}, {borderColor: BASE_STYLES.THEME_COLOR}]}
          railStyle={{backgroundColor: BASE_STYLES.THEME_COLOR}}
          activeDotStyle={{backgroundColor: '#fff'}}
        />
      </Segment>
    </>
  );
}

export default SFRange;
