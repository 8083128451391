import React, {useEffect, useState} from 'react';
import {Button, Container, Form, Grid, Header, Icon, Input, Modal, Segment} from 'semantic-ui-react';
import {BASE_STYLES, LS_KEYS, THEME} from '../../consts/consts';
import {NetworkUtil} from '../../utils/NetworkUtil';
import {Logger} from '../../utils/Logger';
import {Link} from 'react-router-dom';
import {useRecoilState, useRecoilValue} from 'recoil';
import {jwtAtom, loginStateAtom, tokenRefreshingAtom} from '../../recoil/atoms';
import _ from 'lodash';
import {CommonUtil} from '../../utils/CommonUtil';
import axios from 'axios';

const WITHDRAWAL_CONFIRM_TEXT = '이용중단';

export default function MyInfo({match, location, history}) {
  const [loginState, setLoginState] = useRecoilState(loginStateAtom);
  const [jwt, setJwt] = useRecoilState(jwtAtom);

  const isTokenRefreshing = useRecoilValue(tokenRefreshingAtom);

  const [dealer, setDealer] = useState({});
  const [isWithdrawalOpened, setIsWithdrawalOpened] = useState(false);
  const [withdrawalConfirmText, setWithdrawalConfirmText] = useState('');

  useEffect(() => {
    if (!isTokenRefreshing) NetworkUtil.get(`/apis/dealer`, {}, data => setDealer(data));
  }, [isTokenRefreshing, setDealer]);

  return (
    <Container className={'carmon-view-container'}>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment attached="top" color={THEME}>
              <Header as={'h4'}>
                <Icon name={'user'} />
                프로필
              </Header>
            </Segment>
            <Segment attached style={{height: 200}}>
              <Form>
                <Form.Group widths={'equal'}>
                  <Form.Field
                    control={Input}
                    label="이름"
                    placeholder="이름"
                    value={dealer.name}
                    readOnly
                  />

                  <Form.Field
                    control={Input}
                    label="휴대전화번호"
                    placeholder="휴대전화번호"
                    value={dealer.contactNumber}
                    readOnly
                  />
                </Form.Group>
              </Form>
            </Segment>
            <Segment attached={'bottom'} textAlign={'right'}>
              <Button type={'button'} as={Link} to={'/my-info/profile'}>
                수정하기
              </Button>
            </Segment>
          </Grid.Column>

          <Grid.Column>
            <Segment attached="top" color={THEME}>
              <Header as={'h4'}>
                <Icon name={'id card outline'} />
                사원증 및 소속상사 관리
              </Header>
            </Segment>
            <Segment attached style={{height: 200}}>
              <Form>
                <Form.Field
                  control={Input}
                  label="종사원번호"
                  placeholder="종사원번호"
                  value={dealer.employeeNumber}
                />

                <Form.Group widths={'equal'}>
                  <Form.Field
                    control={Input}
                    label="소속상사"
                    placeholder="소속상사"
                    value={dealer.companyName}
                  />

                  <Form.Field
                    control={Input}
                    label="직함"
                    placeholder="직함"
                    value={dealer.positionTitle}
                    readOnly
                  />
                </Form.Group>
              </Form>
            </Segment>
            <Segment attached={'bottom'} textAlign={'right'}>
              <Button type={'button'} as={Link} to={'/my-info/employment'}>
                관리하기
              </Button>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment attached="top" color={THEME}>
              <Header as={'h4'}>
                <Icon name={'edit outline'} />
                탁송지 관리
              </Header>
            </Segment>
            <Segment attached style={{height: 200}}>
              탁송지 주소를 관리할 수 있습니다.
            </Segment>
            <Segment attached={'bottom'} textAlign={'right'}>
              <Button type={'button'} as={Link} to={'/my-info/address'}>
                관리하기
              </Button>
            </Segment>
          </Grid.Column>

          {/*<Grid.Column>
            <Segment attached="top" color={THEME}>
              <Header as={'h4'}>
                <Icon name={'car'} />
                선호 차량 관리
              </Header>
            </Segment>
            <Segment attached style={{height: 200}}>
              선호하는 차량의 검색 조건을 설정하실 수 있습니다.
            </Segment>
            <Segment attached={'bottom'} textAlign={'right'}>
              <Button type={'button'} as={Link} to={'/my-info/favorite'}>
                관리하기
              </Button>
            </Segment>
          </Grid.Column>*/}
        </Grid.Row>

        <Grid.Row textAlign={'right'}>
          <Grid.Column>
            <Header as={'h5'}>
              카몬 서비스를 더 이상 이용하지 않으시려면,&nbsp;
              <a href="" role={'button'} onClick={(e) => {
                e.preventDefault();
                setIsWithdrawalOpened(true);
              }}>이용중단하기</a>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Modal
        size={'small'}
        open={isWithdrawalOpened}
        onClose={() => setIsWithdrawalOpened(false)}
        onOpen={() => setIsWithdrawalOpened(true)}>
        <Modal.Header
          style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          카몬 서비스 이용을 중단하시겠습니까?
          <Button size={'small'} basic icon={'remove'} onClick={() => setIsWithdrawalOpened(false)} />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            서비스 이용을 중단하시면, 딜러님의 <b style={{color: BASE_STYLES.THEME_SUB_COLOR}}>모든 정보가 삭제되며 다시 복구할 수 없습니다.</b><br />
            정말 카몬 서비스 이용을 중단하시겠습니까?
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions
          style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <Form>
            <Form.Field
              control={Input}
              placeholder="이용중단"
              value={withdrawalConfirmText}
              onChange={(e, {value}) => {
                const refinedValue = _.replace(value, /\s/g, '');
                setWithdrawalConfirmText(refinedValue);
              }}
              error={!_.isEmpty(withdrawalConfirmText) && withdrawalConfirmText !== WITHDRAWAL_CONFIRM_TEXT}
            />
          </Form>
          <Button
            primary
            disabled={withdrawalConfirmText !== WITHDRAWAL_CONFIRM_TEXT}
            onClick={() => {
              NetworkUtil.delete('/apis/dealer/withdrawal', {}, () => {
                setIsWithdrawalOpened(false);

                axios.defaults.headers.common['Authorization'] = '';
                setLoginState(false);
                setJwt('');
                localStorage.removeItem(LS_KEYS.LOGIN_CHECK_KEY);
                history.replace('/');
              });
            }}>
            이용중단하기
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
}
