import React from 'react';
import {Card, Label, Segment, Statistic, Table} from 'semantic-ui-react';
import _ from 'lodash';
import {BASE_STYLES, THEME} from '../../consts/consts';
import moment from 'moment';
import {CommonUtil} from '../../utils/CommonUtil';

function setTextColor(condition) {
  return condition ? '#f2711c' : BASE_STYLES.FONT_COLOR;
}

function setBackgroundColor(condition) {
  return condition ? '#f2711c33' : '#ffffff';
}

function MemoizedAccidentHistory({accidentHistoryData, carRegisterData}) {
  console.log(accidentHistoryData);
  console.log(carRegisterData);

  return (
    <>
      <Segment>
        <Label color={THEME} ribbon style={{marginTop: -6, marginBottom: 10}}>
          <h4>중고차 사고이력 정보</h4>
        </Label>
        <Card.Group itemsPerRow={4}>
          <Card style={{backgroundColor: setBackgroundColor(_.toNumber(accidentHistoryData?.TOTAL_LOSS_HISTORY) > 0)}}>
            <Card.Content>
              <Card.Header style={{textAlign: 'center'}}>
                전손 보험사고
              </Card.Header>
              <Card.Description style={{textAlign: 'center'}}>
                <Statistic horizontal>
                  <Statistic.Value
                    style={{color: setTextColor(_.toNumber(accidentHistoryData?.TOTAL_LOSS_HISTORY) > 0)}}>
                    {_.toNumber(accidentHistoryData?.TOTAL_LOSS_HISTORY)}
                  </Statistic.Value>
                  <Statistic.Label>회</Statistic.Label>
                </Statistic>
              </Card.Description>
            </Card.Content>
          </Card>

          <Card style={{backgroundColor: setBackgroundColor(_.toNumber(accidentHistoryData?.ROBBERY_HISTORY) > 0)}}>
            <Card.Content>
              <Card.Header style={{textAlign: 'center'}}>
                도난 보험사고
              </Card.Header>
              <Card.Description style={{textAlign: 'center'}}>
                <Statistic horizontal>
                  <Statistic.Value style={{color: setTextColor(_.toNumber(accidentHistoryData?.ROBBERY_HISTORY) > 0)}}>
                    {_.toNumber(accidentHistoryData?.ROBBERY_HISTORY)}
                  </Statistic.Value>
                  <Statistic.Label>회</Statistic.Label>
                </Statistic>
              </Card.Description>
            </Card.Content>
          </Card>

          <Card
            style={{backgroundColor: setBackgroundColor(_.toNumber(accidentHistoryData?.FLOODING_TOTAL_LOSS_HISTORY) + _.toNumber(accidentHistoryData?.FLOODING_PARTIAL_LOSS_HISTORY) > 0)}}>
            <Card.Content>
              <Card.Header style={{textAlign: 'center'}}>
                침수 보험사고
              </Card.Header>
              <Card.Description style={{textAlign: 'center'}}>
                <Statistic horizontal>
                  <Statistic.Value
                    style={{color: setTextColor(_.toNumber(accidentHistoryData?.FLOODING_TOTAL_LOSS_HISTORY) + _.toNumber(accidentHistoryData?.FLOODING_PARTIAL_LOSS_HISTORY) > 0)}}>
                    {_.toNumber(accidentHistoryData?.FLOODING_TOTAL_LOSS_HISTORY) + _.toNumber(accidentHistoryData?.FLOODING_PARTIAL_LOSS_HISTORY)}
                  </Statistic.Value>
                  <Statistic.Label>회</Statistic.Label>
                </Statistic>
              </Card.Description>
            </Card.Content>
          </Card>

          <Card style={{backgroundColor: setBackgroundColor(_.toNumber(accidentHistoryData?.TOTAL_LOSS_HISTORY) > 0)}}>
            <Card.Content>
              <Card.Header style={{textAlign: 'center'}}>
                특수 용도 이력
              </Card.Header>
              <Card.Description style={{textAlign: 'center'}}>
                <Statistic horizontal>
                  <Statistic.Value
                    style={{color: setTextColor(_.toNumber(accidentHistoryData?.TOTAL_LOSS_HISTORY) > 0)}}>
                    {_.toNumber(accidentHistoryData?.TOTAL_LOSS_HISTORY)}
                  </Statistic.Value>
                  <Statistic.Label>회</Statistic.Label>
                </Statistic>
              </Card.Description>
            </Card.Content>
          </Card>

          <Card
            style={{backgroundColor: setBackgroundColor(_.toNumber(_.split(accidentHistoryData?.MY_CAR_DAMAGE_HISTORY, '회')[0]) > 0)}}>
            <Card.Content>
              <Card.Header style={{textAlign: 'center'}}>
                내차 피해
              </Card.Header>
              <Card.Description style={{textAlign: 'center'}}>
                <Statistic horizontal>
                  <Statistic.Value
                    style={{color: setTextColor(_.toNumber(_.split(accidentHistoryData?.MY_CAR_DAMAGE_HISTORY, '회')[0]) > 0)}}>
                    {_.split(accidentHistoryData?.MY_CAR_DAMAGE_HISTORY, '회')[0]}
                  </Statistic.Value>
                  <Statistic.Label>회</Statistic.Label>
                </Statistic>
              </Card.Description>
            </Card.Content>
            {_.toNumber(_.split(accidentHistoryData?.MY_CAR_DAMAGE_HISTORY, '회')[0]) &&
            (<Card.Content extra style={{textAlign: 'center'}}>
              <span style={{
                fontWeight: 'bold',
                color: '#f2711c',
                fontSize: 20,
              }}>
                {_.replace(_.split(accidentHistoryData?.MY_CAR_DAMAGE_HISTORY, '회')[1], /["'()]/g, '')}
              </span>
            </Card.Content>)}
          </Card>

          <Card
            style={{backgroundColor: setBackgroundColor(_.toNumber(_.split(accidentHistoryData?.OTHER_CAR_DAMAGE_HISTORY, '회')[0]) > 0)}}>
            <Card.Content>
              <Card.Header style={{textAlign: 'center'}}>
                상대차 피해
              </Card.Header>
              <Card.Description style={{textAlign: 'center'}}>
                <Statistic horizontal>
                  <Statistic.Value
                    style={{color: setTextColor(_.toNumber(_.split(accidentHistoryData?.OTHER_CAR_DAMAGE_HISTORY, '회')[0]) > 0)}}>
                    {_.split(accidentHistoryData?.OTHER_CAR_DAMAGE_HISTORY, '회')[0]}
                  </Statistic.Value>
                  <Statistic.Label>회</Statistic.Label>
                </Statistic>
              </Card.Description>
            </Card.Content>
            {_.toNumber(_.split(accidentHistoryData?.OTHER_CAR_DAMAGE_HISTORY, '회')[0]) &&
            (<Card.Content extra style={{textAlign: 'center'}}>
              <span style={{
                fontWeight: 'bold',
                color: '#f2711c',
                fontSize: 20,
              }}>
                {_.replace(_.split(accidentHistoryData?.OTHER_CAR_DAMAGE_HISTORY, '회')[1], /["'()]/g, '')}
              </span>
            </Card.Content>)}
          </Card>

          <Card
            style={{backgroundColor: setBackgroundColor(_.toNumber(accidentHistoryData?.OWNER_CHANGE_COUNT) > 0)}}>
            <Card.Content>
              <Card.Header style={{textAlign: 'center'}}>
                소유자 변경
              </Card.Header>
              <Card.Description style={{textAlign: 'center'}}>
                <Statistic horizontal>
                  <Statistic.Value
                    style={{color: setTextColor(_.toNumber(accidentHistoryData?.OWNER_CHANGE_COUNT) > 0)}}>
                    {_.toNumber(accidentHistoryData?.OWNER_CHANGE_COUNT)}
                  </Statistic.Value>
                  <Statistic.Label>회</Statistic.Label>
                </Statistic>
              </Card.Description>
            </Card.Content>
          </Card>

          <Card
            style={{backgroundColor: setBackgroundColor(_.toNumber(accidentHistoryData?.CAR_NUM_CHANGE_COUNT) > 0)}}>
            <Card.Content>
              <Card.Header style={{textAlign: 'center'}}>
                차량번호 변경
              </Card.Header>
              <Card.Description style={{textAlign: 'center'}}>
                <Statistic horizontal>
                  <Statistic.Value
                    style={{color: setTextColor(_.toNumber(accidentHistoryData?.CAR_NUM_CHANGE_COUNT) > 0)}}>
                    {_.toNumber(accidentHistoryData?.CAR_NUM_CHANGE_COUNT)}</Statistic.Value>
                  <Statistic.Label>회</Statistic.Label>
                </Statistic>
              </Card.Description>
            </Card.Content>
          </Card>
        </Card.Group>
      </Segment>

      <Segment>
        <Label color={THEME} ribbon style={{marginTop: -6, marginBottom: -4}}>
          <h4>자동차 일반 사양 정보</h4>
        </Label>
        <Table className={'accident-history'} compact celled>
          <Table.Body>
            <Table.Row>
              <Table.Cell className={'title'} style={{fontWeight: 'bold', width: '30%'}}>
                제조사
              </Table.Cell>
              <Table.Cell>
                {accidentHistoryData?.MANUFACTURER}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className={'title'} style={{fontWeight: 'bold'}}>
                자동차명
              </Table.Cell>
              <Table.Cell>
                {accidentHistoryData?.CAR_NAME}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className={'title'} style={{fontWeight: 'bold'}}>
                배기량
              </Table.Cell>
              <Table.Cell>
                {accidentHistoryData?.ENGINE_DISPLACEMENT}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className={'title'} style={{fontWeight: 'bold'}}>
                사용연료
              </Table.Cell>
              <Table.Cell>
                {accidentHistoryData?.FUEL}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className={'title'} style={{fontWeight: 'bold'}}>
                연식
              </Table.Cell>
              <Table.Cell>
                {accidentHistoryData?.CAR_TYPE}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className={'title'} style={{fontWeight: 'bold'}}>
                차체형상
              </Table.Cell>
              <Table.Cell>
                {accidentHistoryData?.CAR_BODY}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className={'title'} style={{fontWeight: 'bold'}}>
                용도 및 차종
              </Table.Cell>
              <Table.Cell>
                {accidentHistoryData?.CAR_MODEL}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className={'title'} style={{fontWeight: 'bold'}}>
                최초 보험 가입일자
              </Table.Cell>
              <Table.Cell>
                {moment(accidentHistoryData?.FIRST_CAR_INSURANCE_DATE).format('YYYY년 MM월 DD일')}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>

      <Segment>
        <Label color={THEME} ribbon style={{marginTop: -6, marginBottom: 10}}>
          <h4>자동차 특수 용도 이력 정보</h4>
        </Label>
        <Card.Group itemsPerRow={3}>
          <Card>
            <Card.Content>
              <Card.Header style={{textAlign: 'center'}}>
                대여용도 사용이력(렌터카)
              </Card.Header>
              <Card.Description style={{textAlign: 'center'}}>
                <Statistic horizontal>
                  <Statistic.Value text>
                    {accidentHistoryData?.RENT_CAR_HISTORY}
                  </Statistic.Value>
                </Statistic>
              </Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Card.Header style={{textAlign: 'center'}}>
                영업용도 사용이력
              </Card.Header>
              <Card.Description style={{textAlign: 'center'}}>
                <Statistic horizontal>
                  <Statistic.Value text>
                    {accidentHistoryData?.BUSINESS_HISTORY}
                  </Statistic.Value>
                </Statistic>
              </Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Card.Header style={{textAlign: 'center'}}>
                관용용도 사용이력
              </Card.Header>
              <Card.Description style={{textAlign: 'center'}}>
                <Statistic horizontal>
                  <Statistic.Value text>
                    {accidentHistoryData?.COMMON_USE_HISTORY}
                  </Statistic.Value>
                </Statistic>
              </Card.Description>
            </Card.Content>
          </Card>
        </Card.Group>
      </Segment>

      {/*<Segment>*/}
      {/*  <Label color={THEME} ribbon style={{marginTop: -6, marginBottom: 10}}>*/}
      {/*    <h4>자동차 번호/소유자 변경이력 정보</h4>*/}
      {/*  </Label>*/}
      {/*</Segment>*/}

      <Segment>
        <Label color={THEME} ribbon style={{marginTop: -6, marginBottom: -4}}>
          <h4>내차 보험처리 이력 - {_.toNumber(accidentHistoryData?.TOTAL_COUNT)}건</h4>
        </Label>
        <Table className={'accident-history'} compact fixed celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className={'title'} style={{width: '19%'}}>사고일자</Table.HeaderCell>
              <Table.HeaderCell className={'title'} style={{width: '17%'}}>보험금</Table.HeaderCell>
              <Table.HeaderCell className={'title'} style={{width: '17%'}}>수리비용</Table.HeaderCell>
              <Table.HeaderCell className={'title'} style={{width: '17%'}}>부품</Table.HeaderCell>
              <Table.HeaderCell className={'title'} style={{width: '17%'}}>공임</Table.HeaderCell>
              <Table.HeaderCell className={'title'} style={{width: '17%'}}>도장</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(accidentHistoryData?.RESP_DATA, (data, idx) => {
              return (
                <Table.Row key={`MY_${idx}`}>
                  <Table.Cell
                    style={{textAlign: 'right'}}>{moment(data.MY_ACCIDENT_DATE).format('YYYY년 MM월 DD일')}</Table.Cell>
                  <Table.Cell
                    style={{textAlign: 'right'}}>{CommonUtil.convertToCurrency(data.MY_INSURANCE_BENEFIT)}</Table.Cell>
                  <Table.Cell
                    style={{textAlign: 'right'}}>{CommonUtil.convertToCurrency(data.MY_REPAIR_COST)}</Table.Cell>
                  <Table.Cell
                    style={{textAlign: 'right'}}>{CommonUtil.convertToCurrency(data.MY_COMPONENT)}</Table.Cell>
                  <Table.Cell style={{textAlign: 'right'}}>{CommonUtil.convertToCurrency(data.MY_PAY)}</Table.Cell>
                  <Table.Cell style={{textAlign: 'right'}}>{CommonUtil.convertToCurrency(data.MY_PAINTING)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Segment>

      <Segment>
        <Label color={THEME} ribbon style={{marginTop: -6, marginBottom: -4}}>
          <h4>타차 보험처리 이력 - {_.toNumber(accidentHistoryData?.TOTAL_COUNT2)}건</h4>
        </Label>
        <Table className={'accident-history'} compact fixed celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className={'title'} style={{width: '19%'}}>사고일자</Table.HeaderCell>
              <Table.HeaderCell className={'title'} style={{width: '17%'}}>보험금</Table.HeaderCell>
              <Table.HeaderCell className={'title'} style={{width: '17%'}}>수리비용</Table.HeaderCell>
              <Table.HeaderCell className={'title'} style={{width: '17%'}}>부품</Table.HeaderCell>
              <Table.HeaderCell className={'title'} style={{width: '17%'}}>공임</Table.HeaderCell>
              <Table.HeaderCell className={'title'} style={{width: '17%'}}>도장</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(accidentHistoryData?.RESP_DATA2, (data, idx) => {
              return (
                <Table.Row key={`OTHER_${idx}`}>
                  <Table.Cell
                    style={{textAlign: 'right'}}>{moment(data.OTHER_ACCIDENT_DATE).format('YYYY년 MM월 DD일')}</Table.Cell>
                  <Table.Cell
                    style={{textAlign: 'right'}}>{CommonUtil.convertToCurrency(data.OTHER_INSURANCE_BENEFIT)}</Table.Cell>
                  <Table.Cell
                    style={{textAlign: 'right'}}>{CommonUtil.convertToCurrency(data.OTHER_REPAIR_COST)}</Table.Cell>
                  <Table.Cell
                    style={{textAlign: 'right'}}>{CommonUtil.convertToCurrency(data.OTHER_COMPONENT)}</Table.Cell>
                  <Table.Cell style={{textAlign: 'right'}}>{CommonUtil.convertToCurrency(data.OTHER_PAY)}</Table.Cell>
                  <Table.Cell
                    style={{textAlign: 'right'}}>{CommonUtil.convertToCurrency(data.OTHER_PAINTING)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Segment>

      <Segment>
        <Label color={THEME} ribbon style={{marginTop: -6, marginBottom: -4}}>
          <h4>타차 가해처리 이력 - {_.toNumber(accidentHistoryData?.TOTAL_COUNT3)}건</h4>
        </Label>
        <Table className={'accident-history'} compact fixed celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className={'title'} style={{width: '19%'}}>사고일자</Table.HeaderCell>
              <Table.HeaderCell className={'title'} style={{width: '17%'}}>보험금</Table.HeaderCell>
              <Table.HeaderCell className={'title'} style={{width: '17%'}}>수리비용</Table.HeaderCell>
              <Table.HeaderCell className={'title'} style={{width: '17%'}}>부품</Table.HeaderCell>
              <Table.HeaderCell className={'title'} style={{width: '17%'}}>공임</Table.HeaderCell>
              <Table.HeaderCell className={'title'} style={{width: '17%'}}>도장</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(accidentHistoryData?.RESP_DATA3, (data, idx) => {
              return (
                <Table.Row key={`MY_FAULT_${idx}`}>
                  <Table.Cell
                    style={{textAlign: 'right'}}>{moment(data.MY_FAULT_ACCIDENT_DATE).format('YYYY년 MM월 DD일')}</Table.Cell>
                  <Table.Cell
                    style={{textAlign: 'right'}}>{CommonUtil.convertToCurrency(data.MY_FAULT_INSURANCE_BENEFIT)}</Table.Cell>
                  <Table.Cell
                    style={{textAlign: 'right'}}>{CommonUtil.convertToCurrency(data.MY_FAULT_REPAIR_COST)}</Table.Cell>
                  <Table.Cell
                    style={{textAlign: 'right'}}>{CommonUtil.convertToCurrency(data.MY_FAULT_COMPONENT)}</Table.Cell>
                  <Table.Cell
                    style={{textAlign: 'right'}}>{CommonUtil.convertToCurrency(data.MY_FAULT_PAY)}</Table.Cell>
                  <Table.Cell
                    style={{textAlign: 'right'}}>{CommonUtil.convertToCurrency(data.MY_FAULT_PAINTING)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Segment>

      {/*<Segment>*/}
      {/*  <Label color={THEME} ribbon style={{marginTop: -6, marginBottom: -4}}>*/}
      {/*    <h4>저당권 / 압류 내역 - {_.toNumber(carRegisterData.EB_COUNT)}건</h4>*/}
      {/*  </Label>*/}
      {/*  <Table className={'accident-history'} compact fixed celled>*/}
      {/*    <Table.Header>*/}
      {/*      <Table.Row>*/}
      {/*        <Table.HeaderCell className={'title'} style={{width: '24%'}}>을부번호</Table.HeaderCell>*/}
      {/*        <Table.HeaderCell className={'title'} style={{width: '30%'}}>저당권설정 접수번호</Table.HeaderCell>*/}
      {/*        <Table.HeaderCell className={'title'} style={{width: '23%'}}>저당권설정일</Table.HeaderCell>*/}
      {/*        <Table.HeaderCell className={'title'} style={{width: '23%'}}>저당권말소일</Table.HeaderCell>*/}
      {/*      </Table.Row>*/}
      {/*    </Table.Header>*/}
      {/*    <Table.Body>*/}
      {/*      {_.map(carRegisterData?.RESP_MORTGAGE_DATA_INFO, (data, idx) => {*/}
      {/*        return (*/}
      {/*          <Table.Row key={`MY_FAULT_${idx}`}>*/}
      {/*            <Table.Cell*/}
      {/*              style={{textAlign: 'right'}}>{data.EB_NO}</Table.Cell>*/}
      {/*            <Table.Cell*/}
      {/*              style={{textAlign: 'right'}}>{data.MORTGAGE_NO}</Table.Cell>*/}
      {/*            <Table.Cell*/}
      {/*              style={{textAlign: 'right'}}>{moment(data.MORTGAGE_DATE).format('YYYY년 MM월 DD일')}</Table.Cell>*/}
      {/*            <Table.Cell*/}
      {/*              style={{textAlign: 'right'}}>{data.MORTGAGE_ERASE && moment(data.MORTGAGE_ERASE).format('YYYY년 MM월 DD일')}</Table.Cell>*/}
      {/*          </Table.Row>*/}
      {/*        );*/}
      {/*      })}*/}
      {/*    </Table.Body>*/}
      {/*  </Table>*/}
      {/*</Segment>*/}
    </>
  );
}

export default React.memo(MemoizedAccidentHistory);
