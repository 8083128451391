import React from 'react';
import {Header, Icon, Image, Label, Popup, Table} from 'semantic-ui-react';
import {CommonUtil} from '../../utils/CommonUtil';

import BluePrint from '../../images/blueprint.png';
import moment from 'moment';
import {BASE_STYLES} from '../../consts/consts';
import _ from 'lodash';

import KaatStamp from '../../images/stamps/kaat.png';

const _setAccidentPointBg = (dataRaw, dataKey) => {
  const value = dataRaw?.[dataKey];

  switch (value) {
    case 'X': // 교환
      return 'rgba(204,51,0,0.75)';
    case 'W': // 판금
      return 'rgba(255,153,102,0.75)';
    case 'C': // 부식
      return 'rgba(255,204,0,0.75)';
    case 'A': // 흠집
      return 'rgba(153,204,51,0.75)';
    case 'U': // 요철
      return 'rgba(51,153,0,0.75)';
    case 'T': // 손상
      return 'rgba(41,109,169,0.75)';
    default:
      return 'transparent';
  }
};

const _setWarningTextColor = (dataRaw, dataKey, value) => {
  return dataRaw?.[dataKey] === value
    ? {color: 'rgba(204,51,0,1)', fontWeight: 'bold'}
    : {color: BASE_STYLES.FONT_COLOR, fontWeight: 'normal'};
};

const _setChecked = (dataRaw, dataKey, value) => {
  return (dataRaw?.[dataKey] === value ? 'check square outline' : 'square outline');
};

export default function PerformanceCheckReport({performanceCheckReportData}) {
  return (
    <>
      <div style={{fontSize: BASE_STYLES.FONT_SIZE.MIN, display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between'}}>
          <span>◼︎ 자동차관리법 시행규칙 [별지 제82호서식] &lt;개정 2020.6.26.&gt;</span>
          <div style={{display: 'flex', flexDirection: 'column', padding: `2px 6px`, border: `1px solid`}}>
            <span>성능점검일 - {moment(performanceCheckReportData?.BAS_000003).format('YYYY.MM.DD')}</span>
            <span>성능점검장명 - {performanceCheckReportData?.BAS_000004}</span>
            <span>성능점검자 - {performanceCheckReportData?.BAS_000005}</span>
          </div>
        </div>
      </div>
      <Table className={'performance-check-record'} compact celled
             style={{fontSize: BASE_STYLES.FONT_SIZE.SUB}}>
        <colgroup>
          <col width="90px" />
          <col width="110px" />
          <col width="80px" />
          <col width="130px" />
          <col width="60px" />
          <col width="10px" />
          <col width="30px" />
          <col width="80px" />
          <col width="120px" />
          <col width="80px" />
          <col width="103px" />
        </colgroup>

        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan={11} style={{textAlign: 'center'}}>
              <h4>
                중고자동차 성능ㆍ상태점검기록부<br />
                ([&nbsp;&nbsp;&nbsp;&nbsp;] 자동차가격조사ㆍ산정 선택)
              </h4>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={11}>
              <div
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around'}}>
                <h5 id="BAS_000002"
                    style={{marginBottom: 0, marginRight: 10}}>제 {performanceCheckReportData?.BAS_000002} 호</h5>
                <span style={{
                  flex: 1,
                }}>※ 자동차 가격조사ㆍ산정은 매수인이 원하는 경우 제공하는 서비스 입니다.</span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} colSpan={11} style={{
              textAlign: 'center',
              borderBottom: '2px double rgba(34,36,38,.1)',
            }}>
              <div
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <h5 style={{marginBottom: 0, marginRight: 4}}>자동차 기본정보</h5>
                <span>(가격산정 기준가격은 매수인이 자동차가격조사ㆍ산정을 원하는 경우에만 적습니다.)</span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>① 차명</Table.Cell>
            <Table.Cell colSpan="7">
              <div
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <span>{performanceCheckReportData?.BAS_010000}</span>
                <span>(세부모델 : {performanceCheckReportData?.BAS_010100 || '\u00A0\u00A0\u00A0\u00A0'})</span>
              </div>
            </Table.Cell>
            <Table.Cell className={'title'}>② 자동차등록번호</Table.Cell>
            <Table.Cell colSpan="2">{performanceCheckReportData?.BAS_020000}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>③ 연식</Table.Cell>
            <Table.Cell colSpan="4">{performanceCheckReportData?.BAS_030000}</Table.Cell>
            <Table.Cell className={'title'} colSpan={3}>④ 검사유효기간</Table.Cell>
            <Table.Cell colSpan="3">
              {moment(performanceCheckReportData?.BAS_040100).format('YYYY.MM.DD')} ~ {moment(performanceCheckReportData?.BAS_040200).format('YYYY.MM.DD')}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>⑤ 최초등록일</Table.Cell>
            <Table.Cell colSpan="4">
              {moment(performanceCheckReportData?.BAS_050000).format('YYYY 년 MM 월 DD일')}
            </Table.Cell>
            <Table.Cell className={'title'} rowSpan="2" colSpan="3">⑦ 변속기종류</Table.Cell>
            <Table.Cell rowSpan="2" colSpan="3">
              {/*BAS_070000*/}
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 12,
                }}>
                  <span>
                    <Icon name={_setChecked(performanceCheckReportData, 'BAS_070000', '1')} />자동
                  </span>
                  <span>
                    <Icon name={_setChecked(performanceCheckReportData, 'BAS_070000', '2')} />수동
                  </span>
                  <span>
                    <Icon name={_setChecked(performanceCheckReportData, 'BAS_070000', '3')} />세미오토
                  </span>
                </div>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 12,
                }}>
                  <span>
                    <Icon name={_setChecked(performanceCheckReportData, 'BAS_070000', '4')} />무단변속기
                  </span>
                  <span>
                    <Icon
                      name={_setChecked(performanceCheckReportData, 'BAS_070000', '5')} />기타 ({performanceCheckReportData?.BAS_070100 || '\u00A0\u00A0\u00A0\u00A0'})
                  </span>
                </div>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>⑥ 차대번호</Table.Cell>
            <Table.Cell colSpan="4"><span>{performanceCheckReportData?.BAS_060000}</span></Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>⑧ 사용연료</Table.Cell>
            <Table.Cell colSpan="10">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon
                  name={_setChecked(performanceCheckReportData, 'BAS_080000', '1')} />가솔린</span>
                <span><Icon
                  name={_setChecked(performanceCheckReportData, 'BAS_080000', '2')} />디젤</span>
                <span><Icon
                  name={_setChecked(performanceCheckReportData, 'BAS_080000', '3')} />LPG</span>
                <span><Icon
                  name={_setChecked(performanceCheckReportData, 'BAS_080000', '4')} />하이브리드</span>
                <span><Icon
                  name={_setChecked(performanceCheckReportData, 'BAS_080000', '5')} />전기</span>
                <span><Icon
                  name={_setChecked(performanceCheckReportData, 'BAS_080000', '6')} />수소전기</span>
                <span><Icon
                  name={_setChecked(performanceCheckReportData, 'BAS_080000', '7')} />기타</span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>⑨ 원동기형식</Table.Cell>
            <Table.Cell>{performanceCheckReportData?.BAS_090000}</Table.Cell>
            <Table.Cell className={'title'}>⑩ 보증유형</Table.Cell> {/* TODO - 없음 */}
            <Table.Cell colSpan="5">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />자가보증</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />보험사보증</span>
              </div>
            </Table.Cell>
            <Table.Cell className={'title'}>가격산정 기준가격</Table.Cell>
            <Table.Cell className={'title'} colSpan="2">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
                <span style={{flex: 1, border: '1px dotted'}}>&nbsp;</span>
                <span style={{
                  flex: 1,
                  borderTop: '1px dotted',
                  borderBottom: '1px dotted',
                  borderRight: '1px dotted',
                  alignSelf: 'stretch',
                }}>&nbsp;</span>
                <span style={{
                  flex: 1,
                  borderTop: '1px dotted',
                  borderBottom: '1px dotted',
                  borderRight: '1px dotted',
                }}>&nbsp;</span>
                <span style={{
                  flex: 1,
                  borderTop: '1px dotted',
                  borderBottom: '1px dotted',
                  borderRight: '1px dotted',
                }}>&nbsp;</span>
                <span style={{
                  flex: 1,
                  borderTop: '1px dotted',
                  borderBottom: '1px dotted',
                  borderRight: '1px dotted',
                }}>&nbsp;</span>
                <span>&nbsp;&nbsp;만원</span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} colSpan="11" style={{
              textAlign: 'center',
              borderBottom: '2px double rgba(34,36,38,.1)',
            }}>
              <div
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <h5 style={{marginBottom: 0, marginRight: 4}}>자동차 종합상태</h5>
                <span>(색상, 주요옵션, 가격조사ㆍ산정액 및 특기사항은 매수인이 자동차가격조사ㆍ산정을 원하는 경우에만 적습니다.)</span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>⑪ 사용이력</Table.Cell>
            <Table.Cell colSpan="3">상태</Table.Cell>
            <Table.Cell colSpan="5">항목 / 해당부품</Table.Cell>
            <Table.Cell className={'title'} colSpan="2">가격조사ㆍ산정액 및 특기사항</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} rowSpan="2">주행거리 및 계기상태</Table.Cell>
            <Table.Cell colSpan="3">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Icon name={_setChecked(performanceCheckReportData, 'TOT_110101', '1')} />양호
                </span>
                <span style={_setWarningTextColor(performanceCheckReportData, 'TOT_110101', '2')}>
                  <Icon name={_setChecked(performanceCheckReportData, 'TOT_110101', '2')} />불량
                </span>
              </div>
            </Table.Cell>
            <Table.Cell rowSpan="2" colSpan="5">
              현재 주행거리 &nbsp;<b
              style={{fontSize: BASE_STYLES.FONT_SIZE.SUB}}>{CommonUtil.Unit.format(performanceCheckReportData?.TOT_110202)}</b>&nbsp; KM
            </Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><b> </b></Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Icon name={_setChecked(performanceCheckReportData, 'TOT_110201', '1')} />많음
                </span>
                <span>
                  <Icon name={_setChecked(performanceCheckReportData, 'TOT_110201', '2')} />보통
                </span>
                <span>
                  <Icon name={_setChecked(performanceCheckReportData, 'TOT_110201', '3')} />적음
                </span>
              </div>
            </Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><b> </b></Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>차대번호표기</Table.Cell>
            <Table.Cell colSpan="8">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Icon name={_setChecked(performanceCheckReportData, 'TOT_110301', '1')} />양호
                </span>
                <span style={_setWarningTextColor(performanceCheckReportData, 'TOT_110301', '2')}>
                  <Icon name={_setChecked(performanceCheckReportData, 'TOT_110301', '2')} />부식
                </span>
                <span style={_setWarningTextColor(performanceCheckReportData, 'TOT_110301', '3')}>
                  <Icon name={_setChecked(performanceCheckReportData, 'TOT_110301', '3')} />훼손(오손)
                </span>
                <span style={_setWarningTextColor(performanceCheckReportData, 'TOT_110301', '4')}>
                  <Icon name={_setChecked(performanceCheckReportData, 'TOT_110301', '4')} />상이
                </span>
                <span style={_setWarningTextColor(performanceCheckReportData, 'TOT_110301', '5')}>
                  <Icon name={_setChecked(performanceCheckReportData, 'TOT_110301', '5')} />변조(변타)
                </span>
                <span style={_setWarningTextColor(performanceCheckReportData, 'TOT_110301', '6')}>
                  <Icon name={_setChecked(performanceCheckReportData, 'TOT_110301', '6')} />도말
                </span>
              </div>
            </Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><b> </b></Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>배출가스</Table.Cell>
            <Table.Cell colSpan="3">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110401', '2')} />일산화탄소</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110411', '2')} />탄화수소</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110421', '2')} />매연</span>
              </div>
            </Table.Cell>
            <Table.Cell colSpan="5" className="p_3">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>{performanceCheckReportData?.TOT_110402} %,</span>
                <span>{performanceCheckReportData?.TOT_110412} ppm,</span>
                <span>{performanceCheckReportData?.TOT_110422} %</span>
              </div>
            </Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><b> </b></Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>튜닝</Table.Cell>
            <Table.Cell colSpan="2">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110501', '1')} />없음</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110501', '2')} />있음</span>
              </div>
            </Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110511', '1')} />적법</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110511', '2')} />불법</span>
              </div>
            </Table.Cell>
            <Table.Cell colSpan="5">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110521', '2')} />구조</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110522', '2')} />장치</span>
              </div>
            </Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><b> </b></Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>특별이력</Table.Cell>
            <Table.Cell colSpan="3">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110601', '1')} />없음</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110601', '2')} />있음</span>
              </div>
            </Table.Cell>
            <Table.Cell colSpan="5">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110611', '2')} />침수</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110612', '2')} />화재</span>
              </div>
            </Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><b> </b></Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>용도변경</Table.Cell>
            <Table.Cell colSpan="3">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110701', '1')} />없음</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110701', '2')} />있음</span>
              </div>
            </Table.Cell>
            <Table.Cell colSpan="5">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110711', '2')} />렌트</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110713', '2')} />영업용</span>
              </div>
            </Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><b> </b></Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>색상</Table.Cell>
            <Table.Cell colSpan="3" className={'title'}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />무채색</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />유채색</span>
              </div>
            </Table.Cell>
            <Table.Cell colSpan="5" className={'title'}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />전체도색</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />색상변경</span>
              </div>
            </Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><b> </b></Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>주요옵션</Table.Cell>
            <Table.Cell className={'title'} colSpan="3">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />없음</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />있음</span>
              </div>
            </Table.Cell>
            <Table.Cell className={'title'} colSpan="5">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />썬루프</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />네비게이션</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />기타</span>
              </div>
            </Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
            <Table.Cell className={'title'} style={{textAlign: 'right'}}><b> </b></Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>리콜대상</Table.Cell>
            <Table.Cell colSpan="3">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110801', '1')} />해당없음</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110801', '2')} />해당</span>
              </div>
            </Table.Cell>
            <Table.Cell colSpan="2">리콜이행</Table.Cell>
            <Table.Cell colSpan="5">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110811', '1')} />이행</span>
                <span><Icon name={_setChecked(performanceCheckReportData, 'TOT_110811', '2')} />미이행</span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} colSpan={11}
                        style={{
                          textAlign: 'center',
                          borderBottom: '2px double rgba(34,36,38,.1)',
                        }}>
              <div
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <h5 style={{marginBottom: 0, marginRight: 4}}>사고 교환 수리 등 이력</h5>
                <span style={{
                  fontSize: BASE_STYLES.FONT_SIZE.MIN,
                }}>(가격조사ㆍ산정액 및 특기사항은 매수인이 자동차가격조사ㆍ산정을 원하는 경우에만 적습니다)</span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan="11">
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                ※ 상태표시 부호&nbsp;:&nbsp;
                <Label circular size={'mini'}
                       style={{color: '#ffffff', backgroundColor: 'rgba(204,51,0,0.75)'}}>X</Label> (교환),&nbsp;
                <Label circular size={'mini'}
                       style={{color: '#ffffff', backgroundColor: 'rgba(255,153,102,0.75)'}}>W</Label> (판금 또는 용접),&nbsp;
                <Label circular size={'mini'}
                       style={{color: '#ffffff', backgroundColor: 'rgba(255,204,0,0.75)'}}>C</Label> (부식),&nbsp;
                <Label circular size={'mini'}
                       style={{color: '#ffffff', backgroundColor: 'rgba(153,204,51,0.75)'}}>A</Label> (흠집),&nbsp;
                <Label circular size={'mini'}
                       style={{color: '#ffffff', backgroundColor: 'rgba(51,153,0,0.75)'}}>U</Label> (요철),&nbsp;
                <Label circular size={'mini'}
                       style={{color: '#ffffff', backgroundColor: 'rgba(41,109,169,0.75)'}}>T</Label> (손상)
              </div>
              ※ 하단 항목은 승용차 기준이며, 기타 자동차는 승용차에 준하여 표시
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan="11" style={{height: 280, borderBottom: 0}}>
              <div className="elem" style={{height: 280, marginTop: 29}}>
                <div style={{overflow: 'hidden', position: 'relative'}}>
                  <img src={BluePrint} style={{opacity: 0.6, marginTop: 0}} alt={'차량이미지'} />
                  <Popup
                    size={'tiny'}
                    content="1. 후드"
                    trigger={
                      <Label circular size={'mini'} title="1.후드" style={{
                        position: 'absolute',
                        top: (33 - 13),
                        left: (258 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_120001'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_120001}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="2.프론트휀더좌"
                    trigger={
                      <Label circular size={'mini'} title="2.프론트휀더좌" style={{
                        position: 'absolute',
                        top: (51 - 13),
                        left: (65 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_121002'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_121002}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="2.프론트휀더우"
                    trigger={
                      <Label circular size={'mini'} title="2.프론트휀더우" style={{
                        position: 'absolute',
                        top: (56 - 13),
                        left: (652 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_122002'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_122002}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="3.도어전좌"
                    trigger={
                      <Label circular size={'mini'} title="3.도어전좌" style={{
                        position: 'absolute',
                        top: (112 - 13),
                        left: (62 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_121103'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_121103}</Label>}
                  />
                  <Popup
                    size={'tiny'}
                    content="3.도어후좌"
                    trigger={
                      <Label circular size={'mini'} title="3.도어후좌" style={{
                        position: 'absolute',
                        top: (166 - 13),
                        left: (62 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_121303'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_121303}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="3.도어전우"
                    trigger={
                      <Label circular size={'mini'} title="3.도어전우" style={{
                        position: 'absolute',
                        top: (113 - 13),
                        left: (652 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_122103'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_122103}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="3.도어후우"
                    trigger={
                      <Label circular size={'mini'} title="3.도어후우" style={{
                        position: 'absolute',
                        top: (166 - 13),
                        left: (652 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_122303'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_122303}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="4.트렁크리드"
                    trigger={
                      <Label circular size={'mini'} title="4.트렁크리드" style={{
                        position: 'absolute',
                        top: (255 - 13),
                        left: (257 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_120004'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_120004}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="5.라디에이터 서포트"
                    trigger={
                      <Label circular size={'mini'} title="5.라디에이터 서포트" style={{
                        position: 'absolute',
                        top: (18 - 13),
                        left: (478 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_120005'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_120005}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="6.쿼터패널좌"
                    trigger={
                      <Label circular size={'mini'} title="6.쿼터패널좌" style={{
                        position: 'absolute',
                        top: (236 - 13),
                        left: (64 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_121006'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_121006}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="6.쿼터패널우"
                    trigger={
                      <Label circular size={'mini'} title="6.쿼터패널우" style={{
                        position: 'absolute',
                        top: (234 - 13),
                        left: (652 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_122006'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_122006}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="7.루프패널"
                    trigger={
                      <Label circular size={'mini'} title="7.루프패널" style={{
                        position: 'absolute',
                        top: (137 - 13),
                        left: (258 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_120007'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_120007}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="8.사이드실패널좌"
                    trigger={
                      <Label circular size={'mini'} title="8.사이드실패널좌" style={{
                        position: 'absolute',
                        top: (162 - 13),
                        left: (28 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_121008'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_121008}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="8.사이드실패널우"
                    trigger={
                      <Label circular size={'mini'} title="8.사이드실패널우" style={{
                        position: 'absolute',
                        top: (162 - 13),
                        left: (694 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_122008'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_122008}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="9.프론트패널"
                    trigger={
                      <Label circular size={'mini'} title="9.프론트패널" style={{
                        position: 'absolute',
                        top: (30 - 13),
                        left: (478 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_120009'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_120009}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="10.크로스멤버"
                    trigger={
                      <Label circular size={'mini'} title="10.크로스멤버" style={{
                        position: 'absolute',
                        top: (97 - 13),
                        left: (478 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_120010'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_120010}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="11.인사이드패널좌"
                    trigger={
                      <Label circular size={'mini'} title="11.인사이드패널좌" style={{
                        position: 'absolute',
                        top: (45 - 13),
                        left: (422 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_121011'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_121011}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="11.인사이드패널우"
                    trigger={
                      <Label circular size={'mini'} title="11.인사이드패널우" style={{
                        position: 'absolute',
                        top: (45 - 13),
                        left: (510 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_122011'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_122011}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="12.사이드멤버전좌"
                    trigger={
                      <Label circular size={'mini'} title="12.사이드멤버전좌" style={{
                        position: 'absolute',
                        top: (59 - 13),
                        left: (441 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_121112'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_121112}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="12.사이드멤버후좌"
                    trigger={
                      <Label circular size={'mini'} title="12.사이드멤버후좌" style={{
                        position: 'absolute',
                        top: (259 - 13),
                        left: (424 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_121312'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_121312}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="12.사이드멤버전우"
                    trigger={
                      <Label circular size={'mini'} title="12.사이드멤버전우" style={{
                        position: 'absolute',
                        top: (59 - 13),
                        left: (492 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_122112'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_122112}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="12.사이드멤버후우"
                    trigger={
                      <Label circular size={'mini'} title="12.사이드멤버후우" style={{
                        position: 'absolute',
                        top: (259 - 13),
                        left: (507 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_122312'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_122312}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="13.휠하우스전좌"
                    trigger={
                      <Label circular size={'mini'} title="13.휠하우스전좌" style={{
                        position: 'absolute',
                        top: (81 - 13),
                        left: (422 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_121113'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_121113}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="13.휠하우스후좌"
                    trigger={
                      <Label circular size={'mini'} title="13.휠하우스후좌" style={{
                        position: 'absolute',
                        top: (220 - 13),
                        left: (420 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_121313'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_121313}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="13.휠하우스전우"
                    trigger={
                      <Label circular size={'mini'} title="13.휠하우스전우" style={{
                        position: 'absolute',
                        top: (81 - 13),
                        left: (510 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_122113'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_122113}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="13.휠하우스후우"
                    trigger={
                      <Label circular size={'mini'} title="13.휠하우스후우" style={{
                        position: 'absolute',
                        top: (220 - 13),
                        left: (510 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_122313'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_122313}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="14.필러패널A좌"
                    trigger={
                      <Label circular size={'mini'} title="14.필러패널A좌" style={{
                        position: 'absolute',
                        top: (115 - 13),
                        left: (115 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_121114'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_121114}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="14.필러패널B좌"
                    trigger={
                      <Label circular size={'mini'} title="14.필러패널B좌" style={{
                        position: 'absolute',
                        top: (156 - 13),
                        left: (114 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_121214'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_121214}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="14.필러패널C좌"
                    trigger={
                      <Label circular size={'mini'} title="14.필러패널C좌" style={{
                        position: 'absolute',
                        top: (200 - 13),
                        left: (120 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_121314'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_121314}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="14.필러패널A우"
                    trigger={
                      <Label circular size={'mini'} title="14.필러패널A우" style={{
                        position: 'absolute',
                        top: (114 - 13),
                        left: (607 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_122114'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_122114}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="14.필러패널B우"
                    trigger={
                      <Label circular size={'mini'} title="14.필러패널B우" style={{
                        position: 'absolute',
                        top: (157 - 13),
                        left: (603 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_122214'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_122214}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="14.필러패널C우"
                    trigger={
                      <Label circular size={'mini'} title="14.필러패널C우" style={{
                        position: 'absolute',
                        top: (199 - 13),
                        left: (601 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_122314'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_122314}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="15.대쉬패널"
                    trigger={
                      <Label circular size={'mini'} title="15.대쉬패널" style={{
                        position: 'absolute',
                        top: (110 - 13),
                        left: (478 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_120015'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_120015}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="16.플로워패널"
                    trigger={
                      <Label circular size={'mini'} title="16.플로워패널" style={{
                        position: 'absolute',
                        top: (140 - 13),
                        left: (478 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_120016'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_120016}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="17.트렁크플로워"
                    trigger={
                      <Label circular size={'mini'} title="17.트렁크플로워" style={{
                        position: 'absolute',
                        top: (245 - 13),
                        left: (478 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_120017'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_120017}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="18.리어패널"
                    trigger={
                      <Label circular size={'mini'} title="18.리어패널" style={{
                        position: 'absolute',
                        top: (273 - 13),
                        left: (478 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_120018'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_120018}</Label>
                    } />
                  <Popup
                    size={'tiny'}
                    content="19.패키지트레이"
                    trigger={
                      <Label circular size={'mini'} title="19.패키지트레이" style={{
                        position: 'absolute',
                        top: (213 - 13),
                        left: (478 - 8),
                        color: '#ffffff',
                        backgroundColor: _setAccidentPointBg(performanceCheckReportData, 'IMS_120019'),
                        cursor: 'pointer',
                      }}>{performanceCheckReportData?.IMS_120019}</Label>
                    } />
                </div>
              </div>
              <div style={{height: 30}}>&nbsp;</div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan="2">⑫ 사고이력<span>(유의사항 4 참조)</span></Table.Cell>
            <Table.Cell colSpan="4">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'HST_12A001', '1')} />없음</span>
                <span style={_setWarningTextColor(performanceCheckReportData, 'HST_12A001', '2')}>
                  <Icon name={_setChecked(performanceCheckReportData, 'HST_12A001', '2')} />있음
                </span>
              </div>
            </Table.Cell>
            <Table.Cell colSpan="3">단순수리</Table.Cell>
            <Table.Cell colSpan="2">
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Icon name={_setChecked(performanceCheckReportData, 'HST_12A002', '1')} />없음</span>
                <span style={_setWarningTextColor(performanceCheckReportData, 'HST_12A002', '2')}>
                  <Icon name={_setChecked(performanceCheckReportData, 'HST_12A002', '2')} />있음
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan="9">⑬ 교환,판금 등 이상 부위</Table.Cell>
            <Table.Cell colSpan="2" className={'title'}>가격조사ㆍ산정액 및 특기사항</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell rowSpan="3">외판부위</Table.Cell>
            <Table.Cell rowSpan="2">1랭크</Table.Cell>
            <Table.Cell colSpan="7" style={{borderBottom: 0}}>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140101', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140101', '2')} />1. 후드 &nbsp;
              </span>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140102', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140102', '2')} />2. 프론트휀더 &nbsp;
              </span>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140103', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140103', '2')} />3. 도어 &nbsp;
              </span>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140104', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140104', '2')} />4. 트렁크 리드
              </span>
            </Table.Cell>
            <Table.Cell rowSpan="8" className={'title'} style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
            <Table.Cell rowSpan="8" className={'title'}><b> </b></Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan="7" style={{borderTop: 0, borderLeft: '1px solid rgba(34,36,38,.1)'}}>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140105', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140105', '2')} />5. 라디에이터서포트(볼트체결부품)
              </span>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>2랭크</Table.Cell>
            <Table.Cell colSpan="7">
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140201', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140201', '2')} />6. 쿼터패널(리어휀더) &nbsp;
              </span>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140202', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140202', '2')} />7. 루프패널 &nbsp;
              </span>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140203', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140203', '2')} />8. 사이드실패널
              </span>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell rowSpan="5">주요골격</Table.Cell>
            <Table.Cell rowSpan="2">A랭크</Table.Cell>
            <Table.Cell colSpan="7" style={{borderBottom: 0}}>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140301', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140301', '2')} />9. 프론트패널 &nbsp;
              </span>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140302', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140302', '2')} />10. 크로스멤버 &nbsp;
              </span>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140303', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140303', '2')} />11. 인사이드패널
              </span>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan="7" style={{borderTop: 0, borderLeft: '1px solid rgba(34,36,38,.1)'}}>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140304', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140304', '2')} />17. 트렁크플로워 &nbsp;
              </span>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140305', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140305', '2')} />18. 리어패널
              </span>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell rowSpan="2" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>B랭크</Table.Cell>
            <Table.Cell colSpan="7" style={{borderBottom: 0}}>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140401', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140401', '2')} />12. 사이드멤버 &nbsp;
              </span>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140402', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140402', '2')} />13. 휠하우스
              </span>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan="7" style={{borderTop: 0, borderLeft: '1px solid rgba(34,36,38,.1)'}}>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140403', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140403', '2')} />14. 필러패널 &nbsp;(&nbsp;
              </span>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140404', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140404', '2')} />A, &nbsp;
              </span>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140405', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140405', '2')} />B, &nbsp;
              </span>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140406', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140406', '2')} />C &nbsp;) &nbsp;
              </span>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140407', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140407', '2')} />19. 패키지트레이
              </span>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>C랭크</Table.Cell>
            <Table.Cell colSpan="7">
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140501', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140501', '2')} />15. 대쉬패널 &nbsp;
              </span>
              <span style={_setWarningTextColor(performanceCheckReportData, 'HST_140502', '2')}>
                <Icon name={_setChecked(performanceCheckReportData, 'HST_140502', '2')} />16. 플로워패널
              </span>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={11} style={{padding: 0}}>
              <Table className={'performance-check-record'} compact celled
                     style={{border: 0, fontSize: BASE_STYLES.FONT_SIZE.SUB}}>
                <colgroup>
                  <col width="110px" />
                  <col width="100px" />
                  <col width="130px" />
                  <col width="60px" />
                  <col width="40px" />
                  <col width="130px" />
                  <col width="100px" />
                  <col width="100px" />
                  <col width="*" />
                </colgroup>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell className={'title'} colSpan={11}
                                style={{
                                  textAlign: 'center',
                                  borderBottom: '2px double rgba(34,36,38,.1)',
                                }}>
                      <div
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <h5 style={{marginBottom: 0, marginRight: 4}}>자동차 세부상태</h5>
                        <span style={{
                          fontSize: BASE_STYLES.FONT_SIZE.MIN,
                        }}>(가격조사ㆍ산정액 및 특기사항은 매수인이 자동차가격조사ㆍ산정을 원하는 경우에만 적습니다)</span>
                      </div>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>⑭ 주요장치</Table.Cell>
                    <Table.Cell colSpan="3">항목 / 해당부품</Table.Cell>
                    <Table.Cell colSpan="3">상태</Table.Cell>
                    <Table.Cell colSpan="2" className={'title'}>가격조사ㆍ산정액 및 특기사항</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell rowSpan="2">자기진단</Table.Cell>
                    <Table.Cell colSpan="3">원동기</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_151101', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_151101', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_151101', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'} rowSpan="2"
                                style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>변속기</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_151201', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_151201', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_151201', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell rowSpan="10">원동기</Table.Cell>
                    <Table.Cell colSpan="3">작동상태(공회전)</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_152101', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152101', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152101', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'} rowSpan="10"
                                style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell rowSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>오일누유</Table.Cell>
                    <Table.Cell colSpan="2">실린더 커버(로커암 커버)</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_152201', '1')} />없음</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152201', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152201', '2')} />미세누유
                        </span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152201', '3')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152201', '3')} />누유
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>실린더 헤드 /
                      가스켓</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_152202', '1')} />없음</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152202', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152202', '2')} />미세누유
                        </span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152202', '3')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152202', '3')} />누유
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>실린더 블록 /
                      오일팬</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_152203', '1')} />없음</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152203', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152203', '2')} />미세누유
                        </span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152203', '3')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152203', '3')} />누유
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>오일 유량</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_152301', '1')} />적정</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152301', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152301', '2')} />부족
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell rowSpan="4" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>냉각수누수</Table.Cell>
                    <Table.Cell colSpan="2">실린더 헤드 / 가스켓</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_152401', '1')} />없음</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152401', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152401', '2')} />미세누수
                        </span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152401', '3')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152401', '3')} />누수
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>워터펌프</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_152402', '1')} />없음</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152402', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152402', '2')} />미세누수
                        </span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152402', '3')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152402', '3')} />누수
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>라디에이터</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_152403', '1')} />없음</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152403', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152403', '2')} />미세누수
                        </span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152403', '3')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152403', '3')} />누수
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>냉각수 수량</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_152404', '1')} />적정</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152404', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152404', '2')} />부족
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>커먼레일</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_152501', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_152501', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_152501', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell rowSpan="7">변속기</Table.Cell>
                    <Table.Cell rowSpan="3">자동변속기 (A/T)</Table.Cell>
                    <Table.Cell colSpan="2">오일누유</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_153101', '1')} />없음</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_153101', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_153101', '2')} />미세누유
                        </span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_153101', '3')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_153101', '3')} />누유
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'} rowSpan="7"
                                style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>오일유량 및 상태</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_153102', '1')} />적정</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_153102', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_153102', '2')} />부족
                        </span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_153102', '3')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_153102', '3')} />과다
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>작동상태(공회전)</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_153103', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_153103', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_153103', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell rowSpan="4" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>수동변속기 (M/T)</Table.Cell>
                    <Table.Cell colSpan="2">오일누유</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_153201', '1')} />없음</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_153201', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_153201', '2')} />미세누유
                        </span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_153201', '3')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_153201', '3')} />누유
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>기어변속장치</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_153202', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_153202', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_153202', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>오일유량 및 상태</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_153203', '1')} />적정</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_153203', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_153203', '2')} />부족
                        </span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_153203', '3')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_153203', '3')} />과다
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>작동상태(공회전)</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_153204', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_153204', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_153204', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell rowSpan="4">동력전달</Table.Cell>
                    <Table.Cell colSpan="3">클러치 어셈블리</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_154101', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_154101', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_154101', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'} rowSpan="4"
                                style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>등속조인트</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_154201', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_154201', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_154201', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>추진축 및 베어링</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_154301', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_154301', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_154301', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>디퍼렌셜 기어</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_154401', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_154401', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_154401', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell rowSpan="6">조향</Table.Cell>
                    <Table.Cell colSpan="3">동력조향 작동 오일 누유</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_155101', '1')} />없음</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_155101', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_155101', '2')} />미세누유
                        </span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_155101', '3')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_155101', '3')} />누유
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'} rowSpan="6"
                                style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell rowSpan="5" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>작동상태</Table.Cell>
                    <Table.Cell colSpan="2">스티어링 펌프</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_155202', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_155202', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_155202', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>스티어링
                      기어(MDPS포함)</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_155201', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_155201', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_155201', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>스티어링조인트</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_155204', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_155204', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_155204', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>파워고압호스</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_155205', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_155205', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_155205', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>타이로드엔드 및 볼
                      조인트</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_155203', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_155203', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_155203', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell rowSpan="3">제동</Table.Cell>
                    <Table.Cell colSpan="3">브레이크 마스터 실린더오일 누유</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_156101', '1')} />없음</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_156101', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_156101', '2')} />미세누유
                        </span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_156101', '3')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_156101', '3')} />누유
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'} rowSpan="3"
                                style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>브레이크 오일 누유</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_156201', '1')} />없음</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_156201', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_156201', '2')} />미세누유
                        </span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_156201', '3')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_156201', '3')} />누유
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>배력장치 상태</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_156301', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_156301', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_156301', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell rowSpan="6">전기</Table.Cell>
                    <Table.Cell colSpan="3">발전기 출력</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_157101', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_157101', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_157101', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'} rowSpan="6"
                                style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>시동 모터</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_157201', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_157201', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_157201', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>와이퍼 모터 기능</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_157301', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_157301', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_157301', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>실내송풍 모터</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_157401', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_157401', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_157401', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>라디에이터 팬 모터</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_157501', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_157501', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_157501', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>윈도우 모터</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_157601', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_157601', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_157601', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell rowSpan="3">고전원 전기장치</Table.Cell>
                    <Table.Cell colSpan="3">충전구 절연 상태</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_159101', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_159101', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_159101', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'} rowSpan="3"
                                style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>구동축전지 격리 상태</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_159201', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_159201', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_159201', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="3" style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>고전원전기배선 상태 (접속단자, 피복,
                      보호기구)</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_159301', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_159301', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_159301', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>연료</Table.Cell>
                    <Table.Cell colSpan="3">연료누출(LP가스포함)</Table.Cell>
                    <Table.Cell colSpan="3">
                      <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 12,
                      }}>
                        <span><Icon name={_setChecked(performanceCheckReportData, 'DTS_158101', '1')} />양호</span>
                        <span style={_setWarningTextColor(performanceCheckReportData, 'DTS_158101', '2')}>
                          <Icon name={_setChecked(performanceCheckReportData, 'DTS_158101', '2')} />불량
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className={'title'} style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
                    <Table.Cell className={'title'}><b> </b></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan={11} style={{padding: 0}}>
                      <Table className={'performance-check-record'} compact celled
                             style={{
                               border: 0,
                               backgroundColor: 'rgba(105, 105, 105, 0.1)',
                               fontSize: BASE_STYLES.FONT_SIZE.SUB,
                             }}>
                        <colgroup>
                          <col width="110px" />
                          <col width="100px" />
                          <col width="130px" />
                          <col width="60px" />
                          <col width="40px" />
                          <col width="130px" />
                          <col width="100px" />
                          <col width="100px" />
                          <col width="124px" />
                        </colgroup>

                        <Table.Body>
                          <Table.Row>
                            <Table.Cell
                              className={'title'} colSpan={9}
                              style={{
                                textAlign: 'center',
                                borderBottom: '2px double rgba(34,36,38,.1)',
                              }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}>
                                <h5 style={{marginBottom: 0, marginRight: 4}}>자동차 기타정보</h5>
                                <span style={{
                                  fontSize: BASE_STYLES.FONT_SIZE.MIN,
                                }}>(가격조사ㆍ산정액 및 특기사항은 매수인이 자동차가격조사ㆍ산정을 원하는 경우에만 적습니다)</span>
                              </div>
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>&nbsp;</Table.Cell>
                            <Table.Cell colSpan="7">항목</Table.Cell>
                            <Table.Cell>가격조사ㆍ산정액</Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell rowSpan="5">수리필요</Table.Cell>
                            <Table.Cell>외장</Table.Cell>
                            <Table.Cell colSpan="3">
                              <div style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 12,
                              }}>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />양호</span>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />불량</span>
                              </div>
                            </Table.Cell>
                            <Table.Cell>내장</Table.Cell>
                            <Table.Cell colSpan="2">
                              <div style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 12,
                              }}>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />양호</span>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />불량</span>
                              </div>
                            </Table.Cell>
                            <Table.Cell rowSpan="6" style={{textAlign: 'right'}}><span>0</span>만원</Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>광택</Table.Cell>
                            <Table.Cell colSpan="3">
                              <div style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 12,
                              }}>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />양호</span>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />불량</span>
                              </div>
                            </Table.Cell>
                            <Table.Cell>룸 크리닝</Table.Cell>
                            <Table.Cell colSpan="2">
                              <div style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 12,
                              }}>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />양호</span>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />불량</span>
                              </div>
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>휠</Table.Cell>
                            <Table.Cell>
                              <div style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 12,
                              }}>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />양호</span>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />불량</span>
                              </div>
                            </Table.Cell>
                            <Table.Cell colSpan="5">
                              <div style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 6,
                              }}>
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  gap: 4,
                                }}>
                                  <span>운전석</span>
                                  <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />전,</span>
                                  <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />후</span>
                                </div>
                                <span> / </span>
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  gap: 4,
                                }}>
                                  <span>동반석</span>
                                  <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />전,</span>
                                  <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />후,</span>
                                  <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />응급</span>
                                </div>
                              </div>
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>타이어</Table.Cell>
                            <Table.Cell>
                              <div style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 12,
                              }}>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />양호</span>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />불량</span>
                              </div>
                            </Table.Cell>
                            <Table.Cell colSpan="5">
                              <div style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 6,
                              }}>
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  gap: 4,
                                }}>
                                  <span>운전석</span>
                                  <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />전,</span>
                                  <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />후</span>
                                </div>
                                <span> / </span>
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  gap: 4,
                                }}>
                                  <span>동반석</span>
                                  <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />전,</span>
                                  <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />후,</span>
                                  <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />응급</span>
                                </div>
                              </div>
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>유리</Table.Cell>
                            <Table.Cell colSpan="6">
                              <div style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 12,
                              }}>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />양호</span>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />불량</span>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>기본품목</Table.Cell>
                            <Table.Cell>보유상태</Table.Cell>
                            <Table.Cell>
                              <div style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 12,
                              }}>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />있음</span>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />없음</span>
                              </div>
                            </Table.Cell>
                            <Table.Cell colSpan="5">
                              <div style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 4,
                              }}>
                                (&nbsp;<span><Icon
                                name={_setChecked(performanceCheckReportData, 'TODO', '1')} />사용설명서</span>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />안전삼각대</span>
                                <span><Icon name={_setChecked(performanceCheckReportData, 'TODO', '1')} />잭</span>
                                <span><Icon
                                  name={_setChecked(performanceCheckReportData, 'TODO', '1')} />스패너</span>&nbsp;)
                              </div>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell colSpan="9" style={{textAlign: 'center'}}>
                              <Header as={'h4'}>
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginBottom: 4,
                                }}>
                                  <span style={{marginRight: 6}}>최종 가격조사ㆍ산정 금액</span>
                                  <div style={{
                                    width: 120,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}>
                                    <span style={{flex: 1, border: '1px dotted'}}>&nbsp;</span>
                                    <span style={{
                                      flex: 1,
                                      borderTop: '1px dotted',
                                      borderBottom: '1px dotted',
                                      borderRight: '1px dotted',
                                    }}>&nbsp;</span>
                                    <span style={{
                                      flex: 1,
                                      borderTop: '1px dotted',
                                      borderBottom: '1px dotted',
                                      borderRight: '1px dotted',
                                    }}>&nbsp;</span>
                                    <span style={{
                                      flex: 1,
                                      borderTop: '1px dotted',
                                      borderBottom: '1px dotted',
                                      borderRight: '1px dotted',
                                    }}>&nbsp;</span>
                                    <span style={{
                                      flex: 1,
                                      borderTop: '1px dotted',
                                      borderBottom: '1px dotted',
                                      borderRight: '1px dotted',
                                    }}>&nbsp;</span>
                                  </div>
                                  <span style={{marginLeft: 6}}>만원</span>
                                </div>
                                <Header.Subheader style={{fontSize: BASE_STYLES.FONT_SIZE.SUB, color: '#000000DE'}}>
                                  이 가격조사ㆍ산정가격은 보험개발원의 차량기준가액을 바탕으로 한 기준가격과
                                  ([<b>&nbsp;&nbsp;&nbsp;&nbsp;</b>]기술사회, [<b>&nbsp;&nbsp;&nbsp;&nbsp;</b>]한국자동차진단보증협회)
                                  기준서를 적용하였음
                                </Header.Subheader>
                              </Header>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row style={{backgroundColor: '#fff'}}>
                            <Table.Cell rowSpan="2">⑮ 특기사항 및 점검자의 의견</Table.Cell>
                            <Table.Cell>성능ㆍ상태 점검자</Table.Cell>
                            <Table.Cell colSpan="7" style={{verticalAlign: 'top', height: 120}}>
                              <div style={{
                                fontSize: BASE_STYLES.FONT_SIZE.MIN,
                                color: BASE_STYLES.THEME_BG_COLOR,
                                marginBottom: 6,
                              }}>
                                (비금속 또는 탈부착 가능 부품은 점검사항에서 제외되며 중고차 특성 상 부분적인 판금, 도색은 있을 수 있습니다.)
                              </div>
                              {performanceCheckReportData?.BAS_990000}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row style={{backgroundColor: '#fff'}}>
                            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>가격ㆍ조사 산정자</Table.Cell>
                            <Table.Cell colSpan="7">
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colSpan={11}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}>
                        <Header style={{textAlign: 'center', marginBottom: 0}}>
                          「자동차관리법」 제58조 및 같은 법 시행규칙 제120조에 따라<br />
                          ([<span><Icon name={'check'} style={{margin: 0, color: 'orange'}} /></span>] 중고자동차의 성능·상태를 점검,
                          [&nbsp;&nbsp;&nbsp;&nbsp;] 자동차가격조사 ·산정)
                          하였음을 확인합니다.
                        </Header>
                        <Header style={{marginTop: 20, marginBottom: 16}}>
                          <span>{moment(performanceCheckReportData?.BAS_000003).format('YYYY 년')}</span>
                          <span style={{
                            marginLeft: 12,
                            marginRight: 12,
                          }}>{moment(performanceCheckReportData?.BAS_000003).format('MM 월')}</span>
                          <span>{moment(performanceCheckReportData?.BAS_000003).format('DD 일')}</span>
                        </Header>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          alignSelf: 'stretch',
                        }}>
                          <span style={{fontSize: 16, fontWeight: 'bold'}}>중고자동차 성능·상태 점검자</span>
                          <span style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                          }}>(사)한국자동차기술인협회 / {performanceCheckReportData?.BAS_000004} (서명 또는 인)
                          <Image src={KaatStamp} style={{position: 'absolute', right: 24, bottom: 20}} /></span>
                        </div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}
