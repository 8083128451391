import React from 'react';
import {Link} from 'react-router-dom';
import {Card, Icon, Label} from 'semantic-ui-react';
import moment from 'moment';
import {BASE_STYLES, THEME} from '../../consts/consts';
import {BackgroundImage} from 'react-image-and-background-image-fade';

export function MemoizedBidListItem({bidData}) {
  return (
    <Card
      className={'bid-list-items'}
      as={Link}
      to={`/bid/item/${bidData.auctionId}`}
      target={'_blank'}>
      <BackgroundImage
        lazyLoad
        src={bidData.repImage}
        style={{
          display: 'flex',
          padding: 0,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        height={'220px'} />
      <Card.Content>
        <Card.Header style={{fontSize: BASE_STYLES.FONT_SIZE.MAX}}>{bidData.carName}</Card.Header>
        <Card.Meta
          style={{fontSize: BASE_STYLES.FONT_SIZE.BASE, marginBottom: 16}}>{bidData.carSeriesName} ({bidData.carSeriesName1}, {bidData.carSeriesName2})</Card.Meta>
        <Card.Description>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
            <div style={{flex: 1, fontSize: BASE_STYLES.FONT_SIZE.BASE}}>
              {moment(bidData.carFirstDate).format('YYYY년 MM월')} ({bidData.year}년형)<br />
              {(bidData.mileage / 10000).toFixed(1)}만km<br />
            </div>
            <div>
              <Label color={THEME} image>
                <Icon name={'grab'} />
                {bidData.bidCount} 명
              </Label>
            </div>
          </div>
        </Card.Description>
      </Card.Content>
      <Card.Content extra style={{fontSize: BASE_STYLES.FONT_SIZE.SUB, color: '#333333'}}>
        {bidData.locationStateName} - {bidData.locationRegionName} | {bidData.centerName}
      </Card.Content>
    </Card>
  );
}

export default React.memo(MemoizedBidListItem);
