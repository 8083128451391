import {Route, Switch} from 'react-router-dom';
import MyInfoView from './MyInfo';
import ProfileView from './Subs/Profile';
import EmploymentView from './Subs/Employment';
import FavoriteView from './Subs/Favorite';
import AddressView from './Subs/Address';
import WithdrawalView from './Subs/Withdrawal';
import NotFound from '../Error/NotFound';

export default function MyInfo({match, location, history}) {
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={MyInfoView} />
      <Route path={`${match.path}/profile`} component={ProfileView} />
      <Route path={`${match.path}/employment`} component={EmploymentView} />
      <Route path={`${match.path}/favorite`} component={FavoriteView} />
      <Route path={`${match.path}/address`} component={AddressView} />
      <Route path={`${match.path}/withdrawal`} component={WithdrawalView} />
      <Route path={`${match.path}/**`} component={NotFound} />
    </Switch>
  );
}
