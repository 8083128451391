import React from 'react';
import {Button, Divider, Icon, Image, Menu} from 'semantic-ui-react';

import {Link, NavLink, withRouter} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import Logo from '../../images/logos/carmon.png';
import {jwtAtom, loginStateAtom} from '../../recoil/atoms';

import {logout} from '../../providers/authProvider';
import {BASE_STYLES, LS_KEYS, THEME} from '../../consts/consts';
import axios from 'axios';

function Navbar({match, location, history}) {
  const [loginState, setLoginState] = useRecoilState(loginStateAtom);
  const [jwt, setJwt] = useRecoilState(jwtAtom);

  const doLogout = () => logout(response => {
    setLoginState(false);
    setJwt('');

    axios.defaults.headers.common['Authorization'] = '';
    localStorage.removeItem(LS_KEYS.LOGIN_CHECK_KEY);
    history.replace('/');
  }, error => {
    console.error(error);
  });

  const MAIN_MENUS = [
    {name: 'bidList', to: '/bid/list', path: '/bid', icon: 'car', label: '경매장'},
    {name: 'myBid', to: '/my-bid/list', path: '/my-bid', icon: 'warehouse', label: '내 차고'},
    // {name: 'myTran', to: '/my-tran/list', path: '/my-tran', icon: 'handshake outline', label: '내 거래'},
  ];

  const SUB_MENUS = [
    // {name: 'chart', to: '/chart', path: '/chart', icon: 'chart bar outline', label: '매입시세'},
    // {name: 'noticeList', to: '/notice/list', path: '/notice', icon: 'exclamation circle', label: '공지사항'},
  ];

  const PERSONAL_MENUS = [
    {name: 'myInfo', to: '/my-info', path: '/my-info', icon: 'user', label: '내 정보'},
  ];

  return (
    <header id={'carmon-header'}>
      <Menu fixed={'top'} color={THEME} compact borderless inverted className="carmon-navbar-menu">
        {/*<Menu.Item as={Link} to={loginState ? '/dashboard' : '/'} header>*/}
        <Menu.Item as={Link} to={loginState ? '/bid/list' : '/'} header>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <Image src={Logo} style={{marginLeft: 16, width: 160}} />
          </div>
        </Menu.Item>
        {loginState && (<Menu.Item>
            <Divider vertical />
          </Menu.Item>
        )}
        {loginState && (
          <Menu color={THEME} compact inverted icon={'labeled'}>
            {MAIN_MENUS.map((menu, idx) => (
              <Menu.Item
                className="carmon-navbar-menu-item"
                key={`MM_${idx}`}
                name={menu.name}
                active={location.pathname.startsWith(menu.path)}
                as={NavLink}
                to={menu.to}>
                <Icon name={menu.icon} />
                {menu.label}
              </Menu.Item>
            ))}
          </Menu>
        )}
        {(loginState && SUB_MENUS.length > 0) && (<Menu.Item>
            <Divider vertical />
          </Menu.Item>
        )}
        {(loginState && SUB_MENUS.length > 0) && (
          <Menu color={THEME} compact inverted icon={'labeled'}>
            {SUB_MENUS.map((menu, idx) => (
              <Menu.Item
                className="carmon-navbar-menu-item"
                key={`SM_${idx}`}
                name={menu.name}
                active={location.pathname.startsWith(menu.path)}
                as={NavLink}
                to={menu.to}>
                <Icon name={menu.icon} />
                {menu.label}
              </Menu.Item>
            ))}y
          </Menu>
        )}
        {loginState && (<Menu.Item>
            <Divider vertical />
          </Menu.Item>
        )}
        {loginState ? (
          <Menu color={THEME} inverted compact icon={'labeled'} floated={'right'}>
            {PERSONAL_MENUS.map((menu, idx) => (
              <Menu.Item
                className="carmon-navbar-menu-item"
                key={`PM_${idx}`}
                name={menu.name}
                active={location.pathname.startsWith(menu.path)}
                as={NavLink}
                to={menu.to}>
                <Icon name={menu.icon} />
                {menu.label}
              </Menu.Item>
            ))}
            <Menu.Item
              position={'right'}
              onClick={doLogout}>
              <Icon name={'sign out'} />
              로그아웃
            </Menu.Item>
          </Menu>
        ) : (
          <Menu.Item position={'right'} as={NavLink} to={'/register/step1'}>
            <Button secondary animated={'vertical'}>
              <Button.Content visible>아직도 카몬 딜러가 아니신가요?</Button.Content>
              <Button.Content hidden>
                <Icon name={'signup'} />
                지금 바로 카몬 딜러가 되세요!
              </Button.Content>
            </Button>
          </Menu.Item>
        )}
      </Menu>
    </header>
  );
}

export default withRouter(Navbar);
