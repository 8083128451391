import {Route, Switch} from 'react-router-dom';
import LoginIdView from './LoginId';
import PasswordView from './Password';
import NotFound from '../Error/NotFound';

export default function FindInfo({match, location, history}) {
  return (
    <Switch>
      <Route exact path={`${match.path}/login-id`} component={LoginIdView} />
      <Route exact path={`${match.path}/password`} component={PasswordView} />
      <Route path={`${match.path}/*`} component={NotFound} />
    </Switch>
  );
}
