import React from 'react';
import {Button, Grid, Header, Segment} from 'semantic-ui-react';
import _ from 'lodash';
import {THEME} from '../../consts/consts';

function SFToggle({
                    base,
                    conditions,
                    condKey: key,
                    renderIf = true,
                    isInner,
                    onItemClicked,
                    children,
                  }) {
  if (!renderIf) return null;

  const {title, data} = base[key];
  const {[key]: condition} = conditions;
  let items = data;
  let chunkedItems = _.chunk(items, 3);

  let labelKey = 'label', valueKey = 'value';
  if (key === 'region') {
    labelKey = 'shortName';
    valueKey = 'code';
  }

  // 다음 항목의 데이터가 있는지 확인
  // TODO - State가 어디든 바뀔 때마다 계속 Re-render 되는데 이거 정상인가?

  function _buildItemKey(idx, idx2) {
    return `btn_${key}_${idx}_${idx2}`;
  }

  return (
    <>
      {title && <Header as={'h5'} attached={'top'}>{title}</Header>}
      <Segment attached style={{maxHeight: 400, overflowY: 'auto', borderRadius: '0 0 .28571429rem .28571429rem'}}>
        <Grid>
          {_.map(chunkedItems, (chunkedItem, idx) => {
            return (
              <Grid.Row columns={3} key={`row_${key}_${idx}`}
                        style={{
                          padding: idx === 0
                            ? `3px 0 1px 0`
                            : idx === chunkedItems.length - 1
                              ? `1px 0 3px 0`
                              : `1px 0`,
                        }}>
                {_.map(chunkedItem, (item, idx2) => {
                  return (
                    <Grid.Column key={`col_${key}_${idx}_${idx2}`} textAlign={'center'} style={{padding: `2px 0`}}>
                      <Button
                        compact
                        toggle
                        color={THEME}
                        name={key}
                        key={_buildItemKey(idx, idx2)}
                        value={item[valueKey]}
                        active={_.includes(condition, item[valueKey])}
                        content={item[labelKey]}
                        onClick={(e, props) => {
                          e.stopPropagation();
                          onItemClicked(e, props);
                        }} />
                    </Grid.Column>
                  );
                })}
              </Grid.Row>
            );
          })}
        </Grid>
      </Segment>
    </>
  );
}

export default SFToggle;
