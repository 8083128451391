import {Route, Switch} from 'react-router-dom';
import BidListView from './BidList';
import BidItemView from './BidItem';
import NotFound from '../Error/NotFound';

export default function Bid({match, location, history}) {
  return (
    <Switch>
      <Route path={`${match.path}/list`} component={BidListView} />
      <Route path={`${match.path}/item/:auctionId`} component={BidItemView} />
      <Route path={`${match.path}/**`} component={NotFound} />
    </Switch>
  );
}
