import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Checkbox,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
  Message,
  Segment, Sticky,
} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {jwtAtom, loginStateAtom} from '../../recoil/atoms';
import {login} from '../../providers/authProvider';
import {BASE_STYLES, LS_KEYS} from '../../consts/consts';

import {CommonUtil} from '../../utils/CommonUtil';

import AnimatedNumber from 'animated-number-react';
import {NetworkUtil} from '../../utils/NetworkUtil';

import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

function LoginView({match, location, history}) {
  const [loginState, setLoginState] = useRecoilState(loginStateAtom);
  const [jwt, setJwt] = useRecoilState(jwtAtom);
  const [loginInfo, setLoginInfo] = useState({
    loginId: localStorage.getItem(LS_KEYS.KEPT_LOGIN_ID) || '',
    password: '',
    isOnError: false,
    status: 0,
    detail: '',
  });

  const [keptLoginId, setKeptLoginId] = useState(localStorage.getItem(LS_KEYS.KEPT_LOGIN_ID) !== null);
  const [isProceeding, setIsProceeding] = useState(false);
  const [biddableCarsCount, setBiddableCarsCount] = useState(0);

  const doLogin = () => {
    setIsProceeding(true);

    login(
      loginInfo,
      accessToken => {
        setIsProceeding(false);

        setLoginState(true);
        setJwt(accessToken);

        localStorage.setItem(LS_KEYS.LOGIN_CHECK_KEY, 'true');
        if (keptLoginId) localStorage.setItem(LS_KEYS.KEPT_LOGIN_ID, loginInfo.loginId);
        else localStorage.removeItem(LS_KEYS.KEPT_LOGIN_ID);

        // history.push('/dashboard');
        history.push('/bid/list');
      },
      errorData => {
        setIsProceeding(false);

        setLoginInfo({...loginInfo, isOnError: true, ...errorData});
        setLoginState(false);
        setJwt('');

        localStorage.removeItem(LS_KEYS.LOGIN_CHECK_KEY);
      });
  };

  useEffect(() => {
    NetworkUtil.get('/apis/data/base/cars/count', {}, count => setBiddableCarsCount(count));
  }, []);

  return (
    <Container fluid className={'carmon-view-container'}
               style={{
                 minWidth: 980,
                 flex: 1,
                 display: 'flex',
                 flexDirection: 'column',
                 justifyContent: 'center',
                 alignItems: 'stretch',
                 paddingTop: 90, /* header height + 20px */
                 paddingLeft: 0,
                 paddingRight: 0,
                 marginBottom: -20,
               }}>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          justifyItems: 'stretch',
          backgroundColor: BASE_STYLES.THEME_COLOR,
          borderTop: `1px dashed ${BASE_STYLES.THEME_SUB_COLOR}`,
          borderBottom: `1px dashed ${BASE_STYLES.THEME_SUB_COLOR}`,
        }}>
        <div
          style={{
            flex: 2,
            alignSelf: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '60px 0 60px 60px',
          }}>
          <Splide
            options={{
              type: 'loop',
              direction: 'ttb',
              throttle: 1,
              height: '640px',
              wheel: true,
              autoHeight: true,
              perPage: 1,
            }}>
            <SplideSlide style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}>
              <Header className="ads" style={{fontSize: 100, color: BASE_STYLES.THEME_ITEM_COLOR}}>
                중고차?<br />다&nbsp;
                <span className={'ads'}
                      style={{fontSize: 160, fontWeight: 'bold', color: BASE_STYLES.THEME_SUB_COLOR}}>카몬</span>이랑 해!
                {
                  biddableCarsCount > 50
                    ? (
                      <Header.Subheader className="ads" style={{fontSize: 50, marginTop: 30}}>
                        바로 지금,
                        <span className={'ads'} style={{color: BASE_STYLES.THEME_ITEM_COLOR, fontWeight: 'bold'}}>
                      <AnimatedNumber
                        className={'ads'}
                        value={biddableCarsCount}
                        formatValue={(value) => CommonUtil.Unit.format(value.toFixed(0))}
                        duration={1000} />대
                    </span>의 차량이<br />
                        딜러님의 선택을 기다립니다!
                      </Header.Subheader>
                    ) : (
                      <Header.Subheader className="ads" style={{fontSize: 50, marginTop: 30}}>
                        바로 지금 카몬 딜러가 되시고<br />진짜 비대면 중고차 매입을 경험해보세요!
                      </Header.Subheader>
                    )}
              </Header>
            </SplideSlide>
            <SplideSlide style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}>
              <Header className="ads" style={{fontSize: 100, color: BASE_STYLES.THEME_ITEM_COLOR}}>
                카몬은 다릅니다!
                <Header.Subheader className="ads" style={{fontSize: 50, marginTop: 30}}>
                  하나. 전 차량 국가 공인 정비소의 <span className={'ads'}
                                            style={{
                                              color: BASE_STYLES.THEME_ITEM_COLOR,
                                              fontWeight: 'bold',
                                            }}>성능점검 완료!</span><br />
                  둘. <span className={'ads'}
                           style={{color: BASE_STYLES.THEME_ITEM_COLOR, fontWeight: 'bold'}}>단! 하루면</span> 판매 여부 확인
                  가능!<br />
                  셋. <span className={'ads'}
                           style={{color: BASE_STYLES.THEME_ITEM_COLOR, fontWeight: 'bold'}}>탁송도</span> 카몬에 맡기세요!<br />
                </Header.Subheader>
              </Header>
            </SplideSlide>
            <SplideSlide style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}>
              <Header className="ads" style={{fontSize: 100, color: BASE_STYLES.THEME_ITEM_COLOR}}>
                더 이상<br /> 고민하지 말고!
              </Header>
              <Button fluid secondary style={{fontSize: 40}} icon={'signup'} content={'카몬딜러가입!'}
                      as={Link}
                      to={'/register/step1'} />
            </SplideSlide>
          </Splide>
        </div>
        <div style={{
          flex: 1,
          alignSelf: 'stretch',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: BASE_STYLES.THEME_SUB_COLOR,
          padding: 60,
        }}>
          <div
            style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <div>
              <Segment size={'huge'}
                       style={{border: `3px solid ${BASE_STYLES.THEME_BG_COLOR}`, borderRadius: 6, margin: 0}}>
                <Header as={'h1'} style={{marginBottom: 30, textAlign: 'center'}}>
                  딜러님, 환영합니다!
                </Header>
                <Form loading={isProceeding}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Input size={'large'} fluid icon="user" iconPosition={'left'} placeholder={'아이디'}
                               value={loginInfo.loginId}
                               onChange={(e, {value}) => setLoginInfo({...loginInfo, loginId: value})} />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Input size={'large'} type={'password'} fluid icon="lock" iconPosition={'left'}
                               placeholder={'비밀번호'}
                               onChange={(e, {value}) => setLoginInfo({...loginInfo, password: value})} />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column textAlign={'left'}>
                        <Checkbox
                          defaultChecked={keptLoginId}
                          label="아이디 기억하기"
                          onChange={(e, {checked}) => setKeptLoginId(checked)} />
                      </Grid.Column>
                      <Grid.Column textAlign={'right'} verticalAlign={'middle'}>
                        <Link to={'/find-info/login-id'}>아이디 찾기</Link>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <Link to={'/find-info/password'}>비밀번호 찾기</Link>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Button content="로그인" size={'huge'} icon={'sign in'} fluid onClick={doLogin}
                                style={{backgroundColor: BASE_STYLES.THEME_SUB_COLOR}} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </Segment>
              <Message style={{fontSize: BASE_STYLES.FONT_SIZE.BASE}} warning hidden={!loginInfo.isOnError}>
                <Icon name="help" />
                {loginInfo.message}{/* ({loginInfo.code} - {loginInfo.status})*/}
              </Message>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default LoginView;
